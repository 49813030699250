import Vue from "vue";
import VueRouter from "vue-router";
import Estadisticas from "../views/Estadisticas.vue";
import Dashboard from "../views/Dashboard.vue";
import EditarPaciente from "../views/EditarPaciente.vue";
import ListadoPacientes from "../views/ListadoPacientes.vue";
import PerfilUsuario from "../views/PerfilUsuario.vue";
import ListadoUsuario from "../views/ListadoUsuario.vue";
import Login from "../views/Login.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/dashboard/:fecha",
    name: "Dashboard",
    component: Dashboard,
    meta: { authOnly: true }
  },
  {
    path: "/editar_paciente/:id_paciente/:nuevo_registro",
    name: "EditarPaciente",
    component: EditarPaciente,
    meta: { authOnly: true }
  },
  {
    path: "/listado_pacientes",
    name: "ListadoPacientes",
    component: ListadoPacientes,
    meta: { authOnly: true }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { guestOnly: true }
  }, 
  {
    path: "/perfil",
    name: "PerfilUsuario",
    component: PerfilUsuario,
    meta: { authOnly: true }
  },
  {
    path: "/usuarios",
    name: "ListadoUsuario",
    component: ListadoUsuario,
    meta: { authOnly: true }
  },
  {
    path: "/estadisticas",
    name: "Estadisticas",
    component: Estadisticas,
    meta: { authOnly: true }
  }, 
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

function isLoggedIn(){
  var login = true;
  if(!localStorage.getItem('auth') || localStorage.getItem('auth') == 'false'){
    login = false;
  }
  return login;
}

router.beforeEach((to, from, next) =>{
  if(to.matched.some(record => record.meta.authOnly)){
    if(!isLoggedIn()){
      next({
        name: 'Login',
      });
    }else{
      next();
    }
  }else if(to.matched.some(record => record.meta.guestOnly)){
    if(isLoggedIn()){
      next({
        path: '/dashboard/true',
      });
    }else{
      next();
    }
  }
});

export default router;
