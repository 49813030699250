import Vue from "vue";
import App from "./App.vue";
//import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import {BootstrapVue, BootstrapVueIcons} from "bootstrap-vue"
import Datepicker from 'vuejs-datepicker';
import vueSelect from "vue-select"
import ToggleButton from 'vue-js-toggle-button'
import DatePicker from 'vue2-datepicker';
import VueTheMask from 'vue-the-mask'
import Cleave from 'cleave.js';

import '@fortawesome/fontawesome-free/js/all.js'

import '@fortawesome/fontawesome-free/css/all.css'
import 'vue-select/dist/vue-select.css';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-vue/dist/bootstrap-vue.css"
import 'vue2-datepicker/index.css';

Vue.config.productionTip = false;

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(ToggleButton)
Vue.use(DatePicker)
Vue.use(VueTheMask)

Vue.component("DatePicker", DatePicker);
Vue.component('v-select', vueSelect)
Vue.component('vuejs-datepicker', Datepicker)
Vue.component('vue-the-mask', VueTheMask)

Vue.directive('cleave', {
  inserted: (el, binding) => {
      el.cleave = new Cleave(el, binding.value || {})
  },
  update: (el) => {
      const event = new Event('input', {bubbles: true});
      setTimeout(function () {
          el.value = el.cleave.properties.result
          el.dispatchEvent(event)
      }, 100);
  }
})

/* Vue.directive("uppercase", {
  //twoWay: true,
  bind: function (el) {
    if(el.value != ''){
      el.value = el.value.toString().toLocaleUpperCase();
    }
  },
  update: function (el) {
    if(el.value != ''){
      el.value = el.value.toString().toLocaleUpperCase();
    }
  },
  inserted: function (el) {
    if(el.value != ''){
      el.value = el.value.toString().toLocaleUpperCase();
    }
  },
  componentUpdated: function (el) {
    if(el.value != ''){
      el.value = el.value.toString().toLocaleUpperCase();
    }
  },
  unbind: function (el) {
    if(el.value != ''){
      el.value = el.value.toString().toLocaleUpperCase();
    }
  }
}) */

Vue.directive("uppercase", {
  /* twoWay: true,
  unbind: () => {},
  bind: function (el, binding, vnode) {
    const findVModelName = vnode => {
      try {
        return vnode.data.directives.find(o => {
          return o.name === "model";
        }).expression;
      } catch (error) {
        error.message = `Unable to find v-model directive at ${
          vnode.tag
        } tag. Please specify the expression: v-uppercase='obj.prop'`;
        throw error;
      }
    };

    const funcByName = (functionName, context, value) => {
      var namespaces = functionName.split(".");
      var func = namespaces.pop();
      for (var i = 0; i < namespaces.length; i++)
        context = context[namespaces[i]];
      return (context[func] = value);
    };

    var expression = binding.expression ?
      binding.expression :
      findVModelName(vnode);
    vnode.context.$watch(expression, {
      //deep: true, // ?
      immediate: true,
      handler(newVal) {
        if (newVal) {
          return funcByName(
            expression,
            vnode.context,
            newVal.toString().toLocaleUpperCase()
          );
        }
      }
    });
  } */
});


Vue.filter('uppercase', function (value) {
  if(value) return value.toUpperCase()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
