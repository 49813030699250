<template>
    <div>
        <div v-if="editar_estudios">
            <label for="nuevo_estudio" class="col control-label text-primary"><span v-if="!reg_estudio.id_estudio">Nuevo estudio </span> <span v-else>Editar estudio </span><toggle-button :sync="true" v-model="nuevo_estudio" color="#43aee6" :labels="{ checked: 'SI', unchecked: 'NO' }"/></label>
            <div v-if="nuevo_estudio" class="card">
                <div class="card-body">
                    <div class="row col-12">
                        <div class="col-4">
                            <span class="col-12">Nombre</span>
                            <input v-uppercase v-model="reg_estudio.nombre" class="col-12" type="text">
                        </div>

                        <div class="col-4">
                            <span class="col-12">Descripción</span>
                            <input v-uppercase v-model="reg_estudio.descripcion" class="col-12" type="text">
                        </div>
                    </div>

                    <div class="col-12 mt-3">
                        <button v-if="!reg_estudio.id_estudio" class="btn btn-primary btn-lg btn-block" @click="registrarEstudio">Agregar</button>
                        <div v-else >
                            <button class="btn btn-warning btn-lg btn-block" @click="editarEstudio">Editar</button>
                            <button class="btn btn-secondary btn-lg btn-block" @click="cancelarEdicion">Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <button role="tab" class="btn btn-outline-secondary" :class="{ active: laboratorial }" @click="cambioPestana('laboratorial')">
                    Estudios laboratoriales
                </button>
            </li>
            <li class="nav-item">
                <button role="tab" class="btn btn-outline-secondary" :class="{ active: gabinete }" @click="cambioPestana('gabinete')">
                    Estudios de gabinete
                </button>
            </li>
        </ul>

        <b-row class="my-1">
            <b-col sm="1" class="ml-3 pt-2">
                <label for="input-none">Buscar:</label>
            </b-col>
            <b-col sm="9">
                <!--<b-form-input v-model="filter" type="search" id="filterInput" placeholder="Buscar" ></b-form-input> -->
                <b-form-input v-model="keyword" type="search" id="filterInput" placeholder="Buscar" ></b-form-input>
            </b-col>
        </b-row>

        <b-table :keyword="keyword" :items="items" :bordered="false" :fields="fields" striped hover outlined responsive>
        <!-- <b-table :filter="filter" :per-page="perPage" :items="listado_estudios" :bordered="true" :fields="fields" striped hover outlined responsive :current-page="currentPage"> -->
            <template #cell(opciones)="row">
                <div v-if="!botones[row.item.id_estudio] && !desde_catalogo" class="btn btn-outline-success mr-1 btn-sm" @click="addEstudio(row.item.id_estudio)">Registrar</div>
                <div v-if="editar_estudios" class="btn btn-outline-warning mr-1 btn-sm" @click="selectEditarEstudio(row.item)">Editar</div>
                <div v-if="editar_estudios" class="btn btn-outline-danger mr-1 btn-sm" @click="eliminarEstudio(row.item.id_estudio, row.item.nombre)">Eliminar</div>
            </template>
        </b-table>

        <div class="ml-3">
            <b-pagination :per-page="perPage" v-model="currentPage" pills :total-rows="rows"></b-pagination>
        </div>

        <b-overlay no-wrap fixed :show="show_mensaje_eliminar_medicamento" rounded="sm">
            <template #overlay>
                <div>
                <p><strong id="form-confirm-label">¿Estas seguro de eliminar el estudio "{{ estudio_eliminar }}" ?</strong></p>
                <div>
                    <b-button variant="outline-danger" class="mr-3" @click="btnLimpiarEliminacion">
                    Cancelar
                    </b-button>
                    <b-button variant="outline-success" @click="btnAceptarEliminacion">OK</b-button>
                </div>
                </div>
            </template>
        </b-overlay>
    </div>
</template>

<script>
import api from "../config.js";
export default {
    name: 'ListadoEstudios',

    props: ['desde_catalogo'],

    data: () => ({
        show_mensaje_eliminar_medicamento:false,
        id_estudio_eliminar: null,
        estudio_eliminar: '',
        keyword: '',
        editar_estudios: false,
        nuevo_estudio: false,
        reg_estudio: {
            id_estudio: null,
            nombre: '',
            tipo: 'laboratoriales',
            descripcion: '',
        },
        filter: null,
        currentPage: 1,
        perPage: 10,
        listado_estudios: [],
        listado_global: [],
        botones:[],
        laboratorial: true,
        gabinete: false,
        fields: [
        {
            key: "nombre",
            label: "Estudio",
            sortable: true
        },
        {
            key: "descripcion",
            label: "descripcion",
            sortable: true
        },
        {
            key: "opciones",
            label: "",
            sortable: false
        }
        ],
    }),

    created: async function(){
        await this.getListadoEstudios();
    },

    methods:{
        async btnAceptarEliminacion(){
            var listado = await api.post("api/eliminar_estudio/" + this.id_estudio_eliminar);
            this.btnLimpiarEliminacion();
            this.listado_global = listado.data.estudios;
            if(listado.data.tipo == 'Exito'){
                this.$toast.success("El estudio se ha eliminado", {
                    dismissible: true,
                    position: "top"
                });
            }else{
                this.$toast.error("Ocurrio un error vuelve a intentarlo", {
                    dismissible: true,
                    position: "top"
                });
            }

            if(this.laboratorial){
                this.listado_estudios = this.listado_global.laboratoriales;
            }else{
                this.listado_estudios = this.listado_global.gabinete;
            }

            this.keyword = '';
        },
        btnLimpiarEliminacion(){
            this.estudio_eliminar = '';
            this.show_mensaje_eliminar_medicamento = false;
            this.id_estudio_eliminar = null;
        },
        eliminarEstudio(id_estudio, nombre){
            this.id_estudio_eliminar = id_estudio;
            this.estudio_eliminar = nombre;
            this.show_mensaje_eliminar_medicamento = true;
        },
        selectEditarEstudio(estudio){
            this.keyword = estudio.nombre;
            this.reg_estudio.id_estudio = estudio.id_estudio;
            this.reg_estudio.nombre = estudio.nombre;
            this.reg_estudio.descripcion = estudio.descripcion;
        },
        async registrarEstudio(){
            this.reg_estudio.nombre = this.reg_estudio.nombre.toUpperCase();
            this.reg_estudio.descripcion = this.reg_estudio.descripcion.toUpperCase();

            var listado = await api.post("api/add_estudio",this.reg_estudio);
            this.listado_global = listado.data;

            if(this.laboratorial){
                this.listado_estudios = this.listado_global.laboratoriales;
            }else{
                this.listado_estudios = this.listado_global.gabinete;
            }

            this.keyword = this.reg_estudio.nombre;
            this.cancelarEdicion(); 
        },
        async editarEstudio(){
            var id = this.reg_estudio.id_estudio;
            this.reg_estudio.nombre = this.reg_estudio.nombre.toUpperCase();
            this.reg_estudio.descripcion = this.reg_estudio.descripcion.toUpperCase();
            var listado = await api.post("api/actualizar_estudios/" + id,this.reg_estudio);

            this.listado_global = listado.data;
            
            if(this.laboratorial){
                this.listado_estudios = this.listado_global.laboratoriales;
            }else{
                this.listado_estudios = this.listado_global.gabinete;
            }

            this.keyword = this.reg_estudio.nombre;
            this.cancelarEdicion(); 
        },
        cancelarEdicion(){
            this.reg_estudio.id_estudio = '';
            this.reg_estudio.nombre = '';
            this.reg_estudio.descripcion = '';
        },
        async getListadoEstudios() {
            var listado = await api.get("api/get_estudios");
            this.listado_global = listado.data;

            if(this.laboratorial){
                this.listado_estudios = this.listado_global.laboratoriales;
            }else{
                this.listado_estudios = this.listado_global.gabinete;
            }

            this.editar_estudios = this.listado_global.tiene_permiso;
        },
        addEstudio(id_estudio){
            this.botones[id_estudio] = true;
            //FIXME ver porque funciona solamente con el splice
            this.botones.splice(0, 0 );
            this.$emit('addEstudio', id_estudio);
        },
        cambioPestana(pestana){
            switch(pestana){
                case 'laboratorial':
                    this.cancelarEdicion();
                    this.reg_estudio.tipo = 'laboratoriales';
                    this.laboratorial = true;
                    this.gabinete = false;

                    this.listado_estudios = this.listado_global.laboratoriales;
                    break;
                case 'gabinete':
                    this.cancelarEdicion();
                    this.reg_estudio.tipo = 'gabinete';
                    this.gabinete = true;
                    this.laboratorial = false;

                    this.listado_estudios = this.listado_global.gabinete;
                    break;
            }
        },
    },

    computed: {
        filtrado(){
            return this.keyword ? this.listado_estudios.filter(
                                           item => item.nombre.toLowerCase().includes(this.keyword.toLowerCase())
                                        || ((item.descripcion)?item.descripcion.toLowerCase().includes(this.keyword.toLowerCase()): '')
                                        ) : this.listado_estudios;
        },
        rows() {
            return this.filtrado.length;
        },

        items(){
            return this.filtrado.slice((this.currentPage - 1) * this.perPage,this.perPage * this.currentPage);
        }
    }
}
</script>