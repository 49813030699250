<template>
    <div class="uploader mt-3" @dragenter="OnDragEnter" @dragleave="OnDragLeave" @dragover.prevent @drop="onDrop" :class="{ draggin: isDragging }">
        <div v-if="!imagen">
            <i class="fa fa-cloud-upload"></i>
        </div>

        <div v-else class="imagen-preview">
            <div class="img-wrapper">
                <img height="150" width="150" :src="imagen" alt="imagen">
                <div class="details">
                    <span v-if="file" style="width: 150px; display: inline-block; background: #fff; color: #000; font-size: 12px" class="name" v-text="file.name"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from "../../config.js";

export default {
    name: 'ArchivosUpload',

    props: ['id_paciente','id_consulta','id_estudio_to_consulta'],

    data: () => ({
        isDragging: false,
        dragCount: 0,
        imagen: null,
        file: null,
        nombre_editar: null, 
    }),

    methods: {
        setImagen(imagen, nombre){
            this.imagen = imagen;
            this.nombre_editar = nombre;
            this.file = null;
        },
        getFile(){
            return this.file;
        },
        cleanFile(){
            this.nombre_editar = null;
            this.imagen =  null;
            this.file =  null;
        },
        OnDragEnter(e){
            e.preventDefault();
            
            this.dragCount++;

            this.isDragging = true;
        },
        OnDragLeave(e){
            e.preventDefault();
            this.dragCount--;            

            if(this.dragCount <= 0 ){
                this.isDragging = false;
            }
        },
        onDrop(e){
            e.preventDefault();
            e.stopPropagation();
            this.dragCount = 0;
            this.isDragging = false;

            const file = e.dataTransfer.files;

            if(file[0]){
                this.addImage(file[0]);
            }
        },
        onInputChange(e){
            const file = e.target.files;

            if(file[0]){
                this.addImage(file[0]);
            }
        },
        async addImage(file){
            const formData = new FormData();

            formData.append('archivo', file, file.name);
            formData.append('id_paciente', this.id_paciente);
            formData.append('id_consulta', this.id_consulta);
            formData.append('id_estudio_to_consulta', this.id_estudio_to_consulta);

            try{
                var datos = await api.post("api/add_archivo_estudio", formData);

                this.$emit('refreshArchivos', datos);
            }catch(error){
                this.$toast.error("Ocurrio un error vuelve a intentarlo", {
                    dismissible: true,
                    position: "top"
                });
            }
            

            
        }
    }
}
</script>

<style scoped>
.uploader{
    width: 100%;
    background: #2196F3;
    color: #fff;
    padding: 40px 15px;
    text-align: center;
    border: 3px dashed #fff;
    border-radius: 10px;
    font-size: 20px;
    position: relative;
}
.uploader i{
    font-size: 85px;
}
.draggin{
    color: #2196F3;
    background: #fff;
    border: 3px dashed #2196F3;
}
.draggin .file-input label, input{
    background: #2196F3;
    color: #fff;
}
.file-input{
    width: 200px;
    margin: auto;
    height: 68px;
    position: relative;
}

.file-input label, input{
    background: #fff;
    color: #2196F3;
    width: 100%;
    position: absolute;
    left: 0;
    top: -7px;
    padding: 10px;
    border-radius: 4px;
    margin-top: 7px;
    cursor: pointer;
}

.file-input input{
    opacity: 0;
    z-index: -2;
}
</style>