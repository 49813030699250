<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="card-body">
                    <div class="form-group row">
                        <h4 for="nombre" class="col-lg-12 control-label text-primary">Información de los padres</h4>
                    </div>
                    <div class="form-group row">
                        <label for="padre" class="col-lg-3 control-label text-primary">Nombre del padre</label>
                        <div class="col-lg-9">
                            <input v-model="padres.padre" type="text" class="form-control" id="nombre"/>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="madre" class="col-lg-3 control-label text-primary">Nombre de la madre</label>
                        <div class="col-lg-9">
                            <input v-model="padres.madre" type="text" class="form-control" id="nombre"/>
                        </div>
                    </div>

                    <hr />

                    <div class="form-group row">
                        <h4 class="col-lg-12 control-label text-primary">Información del embarazo</h4>
                    </div>

                    <div class="form-group row">
                        <label for="edad_mama" class="col-lg-4 control-label text-primary">Edad de la madre</label>
                        <div class="col-lg-2">
                            <input type="text" :maxlength="max" @keydown="filterKey" v-model.number="padres.edad_mama" class="form-control" id="nombre"/>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="no_embarazo" class="col-lg-4 control-label text-primary">Embarazo No.:</label>
                        <div class="col-lg-2">
                            <input type="text" :maxlength="max" @keydown="filterKey" v-model.number="padres.no_embarazo" class="form-control" id="nombre"/>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="semanas_gestacion" class="col-lg-4 control-label text-primary">Semanas de gestación:</label>
                        <div class="col-lg-2">
                            <input type="text"  :maxlength="max" @keydown="filterKey" v-model.number="padres.semanas_gestacion" class="form-control" id="nombre"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Padres",

    props: ['data_padres'],

    data: () => ({
        max: 4,
        padres:{
            padre: '',
            madre: '',
            edad_mama: '',
            no_embarazo: '',
            semanas_gestacion: ''
        },
    }),

    created: function(){
        if(this.data_padres){
            //this.padres = this.data_padres;

            this.padres.padre = this.data_padres.padre;
            this.padres.madre = this.data_padres.madre;
            this.padres.edad_mama = this.data_padres.edad_mama;
            this.padres.no_embarazo = this.data_padres.no_embarazo;
            this.padres.semanas_gestacion = this.data_padres.semanas_gestacion;
        }
    },

    methods: {
        getInfo(){
            return this.padres;
        },
        filterKey(e){
            if((e.key >= 0 && e.key <= 9) || e.key == 'Backspace') {
                //
            }else{
                e.preventDefault();
            }
        }
    }
}
</script>