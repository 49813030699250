<template>
    <div>
        <label for="nueva_especialidad" class="col control-label text-primary"><span v-if="!reg_especialidad.id_especialidad">Nueva especialidad</span> <span v-else>Editar especialidad </span><toggle-button :sync="true" v-model="nueva_especialidad" color="#43aee6" :labels="{ checked: 'SI', unchecked: 'NO' }"/></label>
        <div v-if="nueva_especialidad" class="card">
            <div class="card-body">
                <div class="row col-12">
                    <div class="col-12">
                        <span class="col-12">Especialidad:</span>
                        <input v-uppercase v-model="reg_especialidad.nombre" class="col-12" type="text">
                    </div>
                </div>

                <div class="col-12 mt-3">
                    <button v-if="!reg_especialidad.id_especialidad" class="btn btn-primary btn-lg btn-block" @click="registrarEspecialidad">Agregar</button>
                    <div v-else >
                        <button class="btn btn-warning btn-lg btn-block" @click="editarEspecialidad">Editar</button>
                        <button class="btn btn-secondary btn-lg btn-block" @click="cancelarEdicion">Cancelar</button>
                    </div>
                </div>
            </div>
        </div>

        <b-row class="my-1">
            <b-col sm="1" class="ml-3 pt-2">
                <label for="input-none">Buscar:</label>
            </b-col>
            <b-col sm="9">
                <!--<b-form-input v-model="filter" type="search" id="filterInput" placeholder="Buscar" ></b-form-input> -->
                <b-form-input v-model="keyword" type="search" id="filterInput" placeholder="Buscar" ></b-form-input>
            </b-col>
        </b-row>

        <b-table :keyword="keyword" :items="items" :bordered="false" :fields="fields" striped hover outlined responsive>
        <!-- <b-table :filter="filter" :per-page="perPage" :items="listado_estudios" :bordered="true" :fields="fields" striped hover outlined responsive :current-page="currentPage"> -->
            <template #cell(opciones)="row">
                <div class="btn btn-outline-warning mr-1 btn-sm" @click="selectEditarEspecialidad(row.item)">Editar</div>
                <div class="btn btn-outline-danger mr-1 btn-sm" @click="eliminarEspecialidad(row.item.id_especialidad, row.item.nombre)">Eliminar</div>
            </template>
        </b-table>

        <div class="ml-3">
            <b-pagination :per-page="perPage" v-model="currentPage" pills :total-rows="rows"></b-pagination>
        </div>

        <b-overlay no-wrap fixed :show="show_mensaje_eliminar_especialidad" rounded="sm">
            <template #overlay>
                <div>
                <p><strong id="form-confirm-label">¿Estas seguro de eliminar la especialidad "{{ especialidad_eliminar }}" ?</strong></p>
                <div>
                    <b-button variant="outline-danger" class="mr-3" @click="btnLimpiarEliminacion">
                    Cancelar
                    </b-button>
                    <b-button variant="outline-success" @click="btnAceptarEliminacion">OK</b-button>
                </div>
                </div>
            </template>
        </b-overlay>
    </div>
</template>

<script>
import api from "../../config.js";

export default {
    name: 'Especialidades',

    data: () => ({
        show_mensaje_eliminar_especialidad:false,
        id_especialidad_eliminar: null,
        especialidad_eliminar: '',
        keyword: '',
        editar_especialidad: false,
        nueva_especialidad: false,
        reg_especialidad: {
            id_especialidad: null,
            nombre: '',
        },
        filter: null,
        currentPage: 1,
        perPage: 10,
        listado_especialidades: [],
        fields: [
        {
            key: "nombre",
            label: "Especialidad",
            sortable: true
        },
        {
            key: "opciones",
            label: "",
            sortable: false
        }
        ],
    }),

    created: async function(){
        await this.getListadoEspecialidades();
    },

    methods:{
        async btnAceptarEliminacion(){
            var listado = await api.post("api/eliminar_especialidad/" + this.id_especialidad_eliminar);
            this.btnLimpiarEliminacion();
            /* this.listado_global = listado.data.estudios; */
            if(listado.data.tipo == 'Exito'){
                this.$toast.success("La especialidad se ha eliminado", {
                    dismissible: true,
                    position: "top"
                });

                this.keyword = '';
                this.listado_especialidades = listado.data.especialidades;
            }else{
                this.$toast.error("Ocurrio un error vuelve a intentarlo", {
                    dismissible: true,
                    position: "top"
                });
            }
        },
        btnLimpiarEliminacion(){
            this.especialidad_eliminar = '';
            this.show_mensaje_eliminar_especialidad = false;
            this.id_especialidad_eliminar = null;
        },
        eliminarEspecialidad(id_especialidad, nombre){
            this.id_especialidad_eliminar = id_especialidad;
            this.especialidad_eliminar = nombre;
            this.show_mensaje_eliminar_especialidad = true;
        },
        selectEditarEspecialidad(especialidad){
            this.nueva_especialidad = true;
            this.keyword = especialidad.nombre;
            this.reg_especialidad.id_especialidad = especialidad.id_especialidad;
            this.reg_especialidad.nombre = especialidad.nombre;
        },
        async registrarEspecialidad(){
            this.reg_especialidad.nombre = this.reg_especialidad.nombre.toUpperCase();

            var listado = await api.post("api/add_especialidad",this.reg_especialidad);
            this.listado_especialidades = listado.data;

            this.cancelarEdicion(); 
        },
        async editarEspecialidad(){
            var id = this.reg_especialidad.id_especialidad;
            this.reg_especialidad.nombre = this.reg_especialidad.nombre.toUpperCase();
            var listado = await api.post("api/update_especialidad/" + id,this.reg_especialidad);

            this.listado_especialidades = listado.data;

            this.keyword = this.reg_especialidad.nombre; 
            this.cancelarEdicion(); 
        },
        cancelarEdicion(){
            this.reg_especialidad.id_especialidad = '';
            this.reg_especialidad.nombre = '';
        },
        async getListadoEspecialidades() {
            var listado = await api.get("api/listado_especialidades");
            this.listado_especialidades = listado.data;
        },
    },

    computed: {
        filtrado(){
            return this.keyword ? this.listado_especialidades.filter(
                                           item => item.nombre.toLowerCase().includes(this.keyword.toLowerCase())
                                        ) : this.listado_especialidades;
        },
        rows() {
            return this.filtrado.length;
        },

        items(){
            return this.filtrado.slice((this.currentPage - 1) * this.perPage,this.perPage * this.currentPage);
        }
    }
}
</script>