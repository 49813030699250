<template>
    <div style="margin-left:10px">
        <NuevoPaciente :id_cita="id_cita" :id_paciente="id_paciente"></NuevoPaciente>
    </div>
</template>

<script>
import NuevoPaciente from "../components/NuevoPaciente.vue";

export default {
    name: "EditarPaciente",
    components:{
        NuevoPaciente
    },

    data: () => ({
        id_paciente: null,
        id_cita: null,
    }),

    created(){
        this.id_paciente = this.$route.params.id_paciente;
        var nuevo_registro = this.$route.params.nuevo_registro;

        if(nuevo_registro == 'true'){
            this.$toast.success("Se ha registrado exitosamente el paciente", {
                dismissible: true,
                position: "top"
            });

            this.$router.push("/editar_paciente/" + this.id_paciente + "/edicion");
        }else if(nuevo_registro == 'edicion'){
            console.log('edicion');
        }else{
            if(isNaN(nuevo_registro)){
                console.log('info');
            }else{
                console.log('cita');
                this.id_cita = nuevo_registro;
            }
        }
    }
    
}
</script>