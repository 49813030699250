<template>
  <div id="app">
    <transition name="fade">
      <keep-alive>
        <template v-if="!logeado">
          <Login></Login>
        </template>
        
        <template v-else>
          <Home></Home>
        </template>
      </keep-alive>
    </transition>
  </div>
</template>

<script>
  import Home from "./views/Home.vue";
  import Login from "./views/Login.vue";
  export default {
    components:{
      Home,
      Login
    },
    data: () => ({
      logeado: true,
    }),

    created: async function () {
      this.logeado = await this.isLoggedIn();
    },
    methods:{
      async isLoggedIn(){
        await this.$store.dispatch("getUsuario"); 
        var login = true;
        if(!localStorage.getItem('auth') || localStorage.getItem('auth') == 'false'){
          login = false;
          this.$router.push('/login');
        }
        return login;
      }
    }
  }
</script>

<style>
</style>
