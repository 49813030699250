<template>
    <div>
        <Loading :show="showLoading"></Loading>
        <div class="container" v-if="!showLoading">
            <div class="text-center">
                <label>MEDICO</label>
            </div>
           <v-select style="background:white" v-model="id_medico" :reduce="nombre => nombre.id_usuario" :options="medicos" label="nombre" @input="getCitas(fecha)"/>
        </div>
        
        <Calendario :parametro_fecha="parametro_fecha" @fechaCalendario="getCitas" @fechaListado="getListadoCitas" ref="componente"></Calendario>
        <ListadoCitas @fechaListado="getCalendarioCompleto" :fecha_seleccionada="fecha_seleccionada" :id_medico="id_medico" :listado_citas="listado_citas"></ListadoCitas>
    </div>
</template>

<script>
    import Calendario from "../components/Calendario.vue";
    import ListadoCitas from "../components/ListadoCitas.vue";

    import api from "../config.js";
    import Loading from "../components/Loading.vue";

    export default({
        name: "Dashboard",
        components:{
            Calendario,
            ListadoCitas,
            Loading
        },

        data: () => ({
            fecha: null,
            id_medico: null,
            showLoading: false,
            citas: [],
            medicos: [],
            listado_citas: [],
            parametro_fecha: null,
            fecha_seleccionada: null,
            mes_nombre: [
                {nombre: 'Enero'},
                {nombre: 'Febrero'},
                {nombre: 'Marzo'},
                {nombre: 'Abril'},
                {nombre: 'Mayo'},
                {nombre: 'Junio'},
                {nombre: 'Julio'},
                {nombre: 'Agosto'},
                {nombre: 'Septiembre'},
                {nombre: 'Octubre'},
                {nombre: 'Noviembre'},
                {nombre: 'Diciembre'},
            ],
        }),

        created: async function() {
            this.showLoading = true;
            this.parametro_fecha = this.$route.params.fecha;
            var this4 = this;
            JSON.parse(localStorage.getItem('usuario'), function(campo, valor){
                if(campo == 'id_usuario'){
                    this4.id_medico = valor;
                }
            });
            
            var cookie = await api.get("/sanctum/csrf-cookie");
            if (cookie.status == 204) {
                await this.getMedicos();
            }

            var fecha;
            if(this.parametro_fecha != 'true'){
                fecha = new Date(this.parametro_fecha);
            }else{
                fecha = new Date();
            }

            await this.getCitas(fecha, false);
            this.getListadoCitas(fecha);

            this.showLoading = false;
        },

        methods:{
            async getMedicos(){
                var medic = await api.get("api/listado_medicos");
                this.medicos = medic.data;
            },
            async getCitas(fecha, recargar_calendario){
                //this.showLoading = true;
                this.fecha = fecha;
                
                if(!recargar_calendario){
                    this.listado_citas = [];
                }
                
                this.citas = [];
                var mes = ((fecha.getMonth() + 1).toString().length == 1)?('0' + (fecha.getMonth() + 1)):(fecha.getMonth() + 1);

                var cit = await api.get("api/citas_calendario/" + this.id_medico + "?fecha=" + fecha.getFullYear() + '-' + mes);

                if(cit.data.tipo == 'exito'){
                    this.citas = cit.data.citas;
                }

                this.$refs.componente.getInfoMes(fecha, this.citas, cit.data.horario_medico);
            },

            async getListadoCitas(fecha){
                var mes = ((fecha.getMonth() + 1).toString().length == 1)?('0' + (fecha.getMonth() + 1)):(fecha.getMonth() + 1);
                var dia = ((fecha.getDate() ).toString().length == 1)?('0' + (fecha.getDate())):(fecha.getDate());

                this.fecha_seleccionada = dia  + ' de ' + this.mes_nombre[(mes - 1)].nombre + ' del ' + fecha.getFullYear();
                var list = await api.get("api/citas_listado/" + this.id_medico + "?fecha=" + fecha.getFullYear() + '-' + mes + '-' + dia);

                if(list.data.tipo == 'exito'){
                    this.listado_citas = list.data.listado; 
                }else{
                    this.listado_citas = [];
                }
            },

            async getCalendarioCompleto(fecha, recargar_calendario){
                
                this.getCitas(fecha, recargar_calendario)
                this.getListadoCitas(fecha);
            }
        }
    })
</script>