<template>
    <section>
        <div class="row col-md-12">
            <div class="col-md-6 card-body">
                <div class="form-group row" v-for="col in col_1" :key="col.clave">
                    <label for="nombre" style="font-size:13px" class="col-lg-5 control-label text-primary">{{ col.label }}</label>
                    <div class="col-lg-7">
                        <textarea class="form-control" v-if="!campo_informativo" v-model="datos[col.clave]" style="width:100%; font-size:13px; padding: 5px"></textarea>
                        <div class="border border-primary" style="width:100%; font-size:13px; padding:10px" v-else>{{ datos[col.clave] | uppercase }}</div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 card-body">
                <div class="form-group row" v-for="col in col_2" :key="col.clave">
                    <label for="nombre" style="font-size:13px" class="col-lg-5 control-label text-primary">{{ col.label }}</label>
                    <div class="col-lg-7">
                        <textarea class="form-control" v-if="!campo_informativo" v-model="datos[col.clave]" style="width:100%; font-size:13px; padding: 5px"></textarea>
                        <div class="border border-primary" style="width:100%; font-size:13px; padding:10px" v-else>{{ datos[col.clave] | uppercase}}</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'ExploracionFisica',

    props: ['exploracion_fisica', 'campo_informativo'],

    data: () => ({
        datos:{
            habitus_exterior: '',
            cabeza: '',
            cuello: '',
            torax: '',
            genitales: '',
            extremidades: '',
            columna_vertebral: '',
            abdomen: '',
        },

        col_1:[
            {clave:'habitus_exterior', label:'HABITUS EXTERIOR'},
            {clave:'cabeza', label:'CABEZA'},
            {clave:'cuello', label:'CUELLO'},
            {clave:'torax', label:'TÓRAX Y CARDIOPULMONAR'},
        ],

        col_2:[
            {clave:'genitales', label:'GENITALES EXT.RECTAL Y/O VAGINAL'},
            {clave:'extremidades', label:'EXTREMIDADES'},
            {clave:'columna_vertebral', label:'COLUMNA VERTEBRAL'},
            {clave:'abdomen', label:'ABDOMEN'}
        ],
    }),

    created: function(){
        if(this.exploracion_fisica){
            this.datos.habitus_exterior  = this.exploracion_fisica.habitus_exterior;
            this.datos.cabeza            = this.exploracion_fisica.cabeza;
            this.datos.cuello            = this.exploracion_fisica.cuello;
            this.datos.torax             = this.exploracion_fisica.torax;
            this.datos.genitales         = this.exploracion_fisica.genitales;
            this.datos.extremidades      = this.exploracion_fisica.extremidades;
            this.datos.columna_vertebral = this.exploracion_fisica.columna_vertebral;
            this.datos.abdomen           = this.exploracion_fisica.abdomen;
        }
    },

    methods:{
        getInfo(){
            return this.datos;
        }
    }
}
</script>