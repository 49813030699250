<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="card-body row">
                    <div class="col-lg-12 pt-3">
                        <div class="form-group row col">
                            <label class="col-lg-12 control-label text-primary" style="font-size:20px">SIGNOS VITALES</label>
                        </div>

                        <div class="form-group row col-lg-12">
                            <div class="form-group row col-lg-6">
                                <label class="col-lg-4 control-label text-primary">TA:</label>
                                <div class="col-lg-4">
                                    <input v-uppercase :readonly="campo_informativo" v-model="json_signos_vitales.ta" style="font-size:13px" type="text" class="form-control"/>
                                </div>
                                <label class="col-lg-4 control-label text-primary">mm/Hg</label>
                            </div>

                            <div class="form-group row col-lg-6">
                                <label class="col-lg-4 control-label text-primary">Temp:</label>
                                <div class="col-lg-4">
                                    <input v-uppercase :readonly="campo_informativo" v-model="json_signos_vitales.temp" style="font-size:13px" type="text" class="form-control"/>
                                </div>
                                <label class="col-lg-4 control-label text-primary">`C</label>
                            </div>
                        </div>

                        <div class="form-group row col-lg-12">
                            <div class="form-group row col-lg-6">
                                <label class="col-lg-4 control-label text-primary">FR:</label>
                                <div class="col-lg-4">
                                    <input v-uppercase :readonly="campo_informativo" v-model="json_signos_vitales.fr" style="font-size:13px" type="text" class="form-control"/>
                                </div>
                                <label class="col-lg-3 control-label text-primary">/min</label>
                            </div>

                            <div class="form-group row col-lg-6">
                                <label class="col-lg-4 control-label text-primary">FC:</label>
                                <div class="col-lg-4">
                                    <input v-uppercase :readonly="campo_informativo" v-model="json_signos_vitales.fc" style="font-size:13px" type="text" class="form-control"/>
                                </div>
                                <label class="col-lg-3 control-label text-primary">/min</label>
                            </div>
                        </div>

                        <div class="form-group row col-lg-12">
                            <div class="form-group row col-lg-6">
                                <label class="col-lg-4 control-label text-primary">SAT DE 02:</label>
                                <div class="col-lg-4">
                                    <input v-uppercase :readonly="campo_informativo" v-model="json_signos_vitales.sat_o2" style="font-size:13px" type="text" class="form-control"/>
                                </div>
                                <label class="col-lg-3 control-label text-primary">%</label>
                            </div>

                            <div class="form-group row col-lg-6">
                                <label class="col-lg-4 control-label text-primary">GLUCOSA:</label>
                                <div class="col-lg-4">
                                    <input v-uppercase :readonly="campo_informativo" v-model="json_signos_vitales.glucosa" style="font-size:13px" type="text" class="form-control"/>
                                </div>
                                <label class="col-lg-3 control-label text-primary">mg/dl</label>
                            </div>
                        </div>

                        <hr>

                    </div>


                    <div class="col-lg-12 pt-3">
                        <div class="form-group row col">
                            <label class="col-lg-12 control-label text-primary" style="font-size:20px">MEDIDAS ANTROPOMÉTRICAS</label>
                        </div>

                        <div class="form-group row col-lg-12">
                            <div class="form-group row col-lg-6">
                                <label class="col-lg-4 control-label text-primary">TALLA:</label>
                                <div class="col-lg-5">
                                    <input v-uppercase :readonly="campo_informativo" @change="calcularIMC" v-model="json_signos_vitales.talla" style="font-size:13px" type="text" class="form-control"/>
                                </div>
                                <label class="col-lg-3 control-label text-primary">m</label>
                            </div>

                            <div class="form-group row col-lg-6">
                                <label class="col-lg-4 control-label text-primary">PESO:</label>
                                <div class="col-lg-5">
                                    <input v-uppercase :readonly="campo_informativo" @change="calcularIMC" v-model="json_signos_vitales.peso" style="font-size:13px" type="text" class="form-control"/>
                                </div>
                                <label class="col-lg-3 control-label text-primary">Kg</label>
                            </div>
                        </div>

                        <div class="form-group row col-lg-12">
                            <div class="form-group row col-lg-6">
                                <label class="col-lg-4 control-label text-primary">CADERA:</label>
                                <div class="col-lg-5">
                                    <input v-uppercase :readonly="campo_informativo" v-model="json_signos_vitales.cadera" style="font-size:13px" type="text" class="form-control"/>
                                </div>
                                <label class="col-lg-3 control-label text-primary">cm</label>
                            </div>

                            <div class="form-group row col-lg-6">
                                <label class="col-lg-4 control-label text-primary">CINTURA:</label>
                                <div class="col-lg-5">
                                    <input v-uppercase :readonly="campo_informativo" v-model="json_signos_vitales.cintura" style="font-size:13px" type="text" class="form-control"/>
                                </div>
                                <label class="col-lg-3 control-label text-primary">cm</label>
                            </div>
                        </div>                        

                        <hr>

                        <div v-if="!data.semanas_grafica && motivo.clave != 'NOTEV'" class="form-group row col-lg-12">
                            <div class="form-group row col-lg-6">
                                <label class="col-lg-3 control-label text-primary">IMC:</label>
                                <div class="col-lg-7">
                                    <input v-uppercase readonly v-model="json_signos_vitales.imc" style="font-size:13px" type="text" class="form-control"/>
                                </div>
                            </div>

                            <div class="form-group row col-lg-6">
                                <div class="form-group row col-lg-12">
                                    <div class="form-group row col-lg-5">
                                        <span :class="{'text-warning' : json_signos_vitales.peso_ideal == 'Bajo peso'}" class="control-label text-primary col-lg-12">Bajo peso</span>
                                        <span :class="{'text-success' : json_signos_vitales.peso_ideal == 'Normal'}" class="control-label text-primary col-lg-12">Normal</span>
                                        <span :class="{'text-warning' : json_signos_vitales.peso_ideal == 'Sobrepeso'}" class="control-label text-primary col-lg-12">Sobrepeso</span>
                                    </div>
                                    <div class="form-group row col-lg-7">
                                        <span :class="{'text-danger' : json_signos_vitales.peso_ideal == 'Obesidad grado I'}" class="control-label text-primary col-lg-12">Obesidad grado I</span>
                                        <span :class="{'text-danger' : json_signos_vitales.peso_ideal == 'Obesidad grado II'}" class="control-label text-primary col-lg-12">Obesidad grado II</span>
                                        <span :class="{'text-danger' : json_signos_vitales.peso_ideal == 'Obesidad grado III'}" class="control-label text-primary col-lg-12">Obesidad grado III</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>

                        <section v-if="data.semanas_grafica && motivo.clave != 'NOTIN'">
                            <div class="form-group row col">
                                <label class="col-lg-12 control-label text-primary" style="font-size:20px">GRÁFICAS</label>
                            </div>

                            <div class="form-group row col-lg-12">
                                <div class="form-group row col-lg-6">
                                    <label class="col-lg-5 control-label text-primary">EDAD (SEMANAS):</label>
                                    <div class="col-lg-5">
                                        <input :readonly="true" v-model="data.semanas_grafica" style="font-size:13px" type="text" class="form-control"/>
                                    </div>
                                </div>
                            </div>

                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item">
                                    <button role="tab" class="btn btn-outline-secondary" :class="{ active: (tipo_grafica == 'peso') }" @click="cambioPestanaGrafica('peso')">
                                        PESO
                                    </button>
                                </li>
                                <li class="nav-item">
                                    <button role="tab" class="btn btn-outline-secondary" :class="{ active: (tipo_grafica == 'Talla') }" @click="cambioPestanaGrafica('Talla')">
                                        TALLA
                                    </button>
                                </li>
                                <li class="nav-item">
                                    <button role="tab" class="btn btn-outline-secondary" :class="{ active: (tipo_grafica == 'peso_completo') }" @click="cambioPestanaGrafica('peso_completo')">
                                        PESO GRÁFICA COMPLETA
                                    </button>
                                </li>
                                <li class="nav-item">
                                    <button role="tab" class="btn btn-outline-secondary" :class="{ active: (tipo_grafica == 'talla_completo') }" @click="cambioPestanaGrafica('talla_completo')">
                                        TALLA GRÁFICA COMPLETA
                                    </button>
                                </li>
                            </ul>
                            <div class="card card-body">
                                <Percentiles :fecha="data.fecha_creacion" :talla="json_signos_vitales.talla" :peso="json_signos_vitales.peso" :id_paciente="id_paciente" :semanas="data.semanas_grafica" :key="tipo_grafica + data.semanas_grafica + json_signos_vitales.peso + json_signos_vitales.talla" :tipo_grafica="tipo_grafica"></Percentiles>
                            </div>
                        </section>
                    </div>

                    <div class="col-lg-12 pt-3" v-if="motivo.clave != 'NOTEV'">
                        <div class="form-group row col">
                            <label class="col-lg-12 control-label text-primary" style="font-size:20px">COLESTEROL Y TRIGLICÉRIDOS</label>
                        </div>

                        <div class="form-group row col-lg-12">
                            <div class="form-group row col-lg-6">
                                <label class="col-lg-6 control-label text-primary">TRIGLICERIDOS:</label>
                                <div class="col-lg-3">
                                    <input v-uppercase :readonly="campo_informativo" v-model="data.trigliceridos" style="font-size:13px" type="text" class="form-control"/>
                                </div>
                                <label class="col-lg-3 control-label text-primary">mg/dl</label>
                            </div>

                            <div class="form-group row col-lg-6">
                                <label class="col-lg-5 control-label text-primary">COLESTEROL:</label>
                                <div class="col-lg-3">
                                    <input v-uppercase :readonly="campo_informativo" v-model="data.colesterol" style="font-size:13px" type="text" class="form-control"/>
                                </div>
                                <label class="col-lg-3 control-label text-primary">mg/dl</label>
                            </div>
                        </div>
                        <hr>
                    </div>

                    <div class="col-lg-12 pt-3" v-if="motivo.clave != 'NOTEV'">
                        <div class="form-group row col">
                            <label class="col-lg-12 control-label text-primary" style="font-size:20px">HEMOGLOBINA</label>
                        </div>

                        <div class="form-group row col-lg-12">
                            <label class="col-lg-2 control-label text-primary">A1C:</label>
                            <div class="col-lg-4">
                                <input v-uppercase :readonly="campo_informativo" v-model="data.hemoglobina" style="font-size:13px" type="text" class="form-control"/>
                            </div>
                            <label class="col-lg-3 control-label text-primary">%</label>
                        </div>
                        <hr>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>



<script>

import Percentiles from '../graficas/Percentiles';

export default {
    name: 'Exploracion',

    props: ['exploracion', 'campo_informativo', 'id_paciente', 'motivo'],

    components:{
        Percentiles
    },

    data: () => ({
        tipo_grafica: 'peso',
        seleccion: 'Normal',
        json_signos_vitales:{
            ta: '',
            temp: '',
            fr: '',
            fc: '',
            talla: 0,
            peso: 0,
            cadera: '',
            cintura: '',
            imc: '',
            peso_ideal: '',
        },
        data:{
            json_signos_vitales: '',
            trigliceridos: '',
            colesterol: '',
            hemoglobina: '',
            semanas_grafica: '',
            fecha_creacion: null
        }
    }),

    created: function(){
        if(this.exploracion){
            this.data.trigliceridos   = this.exploracion.trigliceridos;
            this.data.colesterol      = this.exploracion.colesterol;
            this.data.hemoglobina     = this.exploracion.hemoglobina;
            this.data.semanas_grafica = this.exploracion.semanas_grafica;
            this.data.fecha_creacion  = this.exploracion.fecha_creacion;

            if(this.exploracion.json_signos_vitales){
                this.data.json_signos_vitales    = this.exploracion.json_signos_vitales;
                this.json_signos_vitales = JSON.parse(this.data.json_signos_vitales);
            }
        }
    },

    methods:{
        cambioPestanaGrafica(tipo){
            this.tipo_grafica = tipo;
        },
        getInfo(){
            this.data.json_signos_vitales = JSON.stringify(this.json_signos_vitales);

            return this.data;
        },
        calcularIMC(){
            var calculo = this.json_signos_vitales.peso / (Math.pow(this.json_signos_vitales.talla,2));

            if(calculo <= 18.5){
               this.json_signos_vitales.peso_ideal = 'Bajo peso';
            }else if(calculo > 18.5 && calculo <= 25){
                this.json_signos_vitales.peso_ideal = 'Normal';
            }else if(calculo > 25 && calculo <= 30){
                this.json_signos_vitales.peso_ideal = 'Sobrepeso';
            }else if(calculo > 30 && calculo <= 35){
                this.json_signos_vitales.peso_ideal = 'Obesidad grado I';
            }else if(calculo > 35 && calculo <= 40){
                this.json_signos_vitales.peso_ideal = 'Obesidad grado II';
            }else if(calculo > 40){
                this.json_signos_vitales.peso_ideal = 'Obesidad grado III';
            }

            this.json_signos_vitales.imc = calculo.toFixed(2);
        }
    }
}
</script>