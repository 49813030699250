<template>
    <div>
        <div class="">
            <section class="row">
                <div class="col-md-6 card-body">
                    <div class="form-group row" v-for="col in col_1" :key="col.clave">
                        <label for="nombre" style="font-size:13px" class="col-lg-4 control-label text-primary">{{ col.label }}</label>
                        <div class="col-lg-8">
                            <textarea class="form-control" v-model="personales_patologicos[col.clave]" style="width:100%; font-size:13px"></textarea>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="nombre" style="font-size:13px" class="col-lg-4 control-label text-primary">Adicciones</label>
                        <div class="col-lg-8">
                            <div class="form-check-inline">
                                <label style="font-size:13px" for="tabaco" class="col control-label text-primary">Tabaco:<toggle-button :sync="true" v-model="json_adicciones.tabaco" color="#43aee6" :labels="{ checked: 'SI', unchecked: 'NO' }"/></label>
                            </div>
                            <div class="form-check-inline">
                                <label style="font-size:13px" for="alcohol" class="col control-label text-primary">Alcohol:<toggle-button :sync="true" v-model="json_adicciones.alcohol" color="#43aee6" :labels="{ checked: 'SI', unchecked: 'NO' }"/></label>
                            </div>
                            <div class="form-check-inline">
                                <label style="font-size:13px" for="drogas" class="col control-label text-primary">Drogas:<toggle-button :sync="true" v-model="json_adicciones.drogas" color="#43aee6" :labels="{ checked: 'SI', unchecked: 'NO' }"/></label>
                            </div>
                            <textarea class="form-control" v-uppercase v-model="json_adicciones.otros" style="width:100%; font-size:13px"></textarea>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 card-body">
                    <div class="form-group row" v-for="col in col_2" :key="col.clave">
                        <label for="nombre" style="font-size:13px" class="col-lg-4 control-label text-primary">{{ col.label }}</label>
                        <div class="col-lg-8">
                            <textarea class="form-control" v-model="personales_patologicos[col.clave]" style="width:100%; font-size:13px"></textarea>
                        </div>
                    </div>
                </div>

            </section>
        </div>
    </div>
</template>

<script>
export default{
    name: "PersonalesPatologicos",

    props: ['data_personales'],

    data: () => ({
        personales_patologicos:{
            enfermedad_actual: '',
            quirurgicos: '',
            transfusionales: '',
            traumaticos: '',
            hospitalizaciones_previas: '',
            glaucoma: '',
            calculo_biliar: '',
            reflujo_gastroesofalico: '',
            incontinencia_urinaria: '',
            dislipidemias: '',
            patologico_otro: '',
            json_adicciones: '',
        },
        json_adicciones:{tabaco: false, alcohol: false, drogas: false, otros: ''},

        col_1:[
            {clave:'enfermedad_actual', label:'Enfermedades actuales'},
            {clave:'quirurgicos', label:'Quirúrgicos'},
            {clave:'transfusionales', label:'Transfusionales'},
            {clave:'traumaticos', label:'Traumáticos'},
            {clave:'hospitalizaciones_previas', label:'Hospitalizaciones previas'},
        ],

        col_2:[
            {clave:'glaucoma', label:'Glaucoma'},
            {clave:'calculo_biliar', label:'Cálculo biliar'},
            {clave:'reflujo_gastroesofalico', label:'Reflujo gastroesofálico'},
            {clave:'incontinencia_urinaria', label:'Incontinencia urinaria'},
            {clave:'dislipidemias', label:'Dislipidemias'},
            {clave:'patologico_otro', label:'Otro'},
        ],
    }),

    created: function(){
        if(this.data_personales){
            //this.personales_patologicos = this.data_personales;

            this.personales_patologicos.enfermedad_actual = this.data_personales.enfermedad_actual;
            this.personales_patologicos.quirurgicos = this.data_personales.quirurgicos;
            this.personales_patologicos.transfusionales = this.data_personales.transfusionales;
            this.personales_patologicos.traumaticos = this.data_personales.traumaticos;
            this.personales_patologicos.hospitalizaciones_previas = this.data_personales.hospitalizaciones_previas;
            this.personales_patologicos.glaucoma = this.data_personales.glaucoma;
            this.personales_patologicos.calculo_biliar = this.data_personales.calculo_biliar;
            this.personales_patologicos.reflujo_gastroesofalico = this.data_personales.reflujo_gastroesofalico;
            this.personales_patologicos.incontinencia_urinaria = this.data_personales.incontinencia_urinaria;
            this.personales_patologicos.dislipidemias = this.data_personales.dislipidemias;
            this.personales_patologicos.patologico_otro = this.data_personales.patologico_otro;
            this.personales_patologicos.json_adicciones = this.data_personales.json_adicciones;

            if(this.personales_patologicos.json_adicciones){
                this.json_adicciones = JSON.parse(this.personales_patologicos.json_adicciones);
            }
        }
    },

    methods: {
        getInfo(){
            this.personales_patologicos.json_adicciones = JSON.stringify(this.json_adicciones);
            return this.personales_patologicos;
        }
    }
}

</script>