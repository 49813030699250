<template>
    <div class="listado_citas">
        
        <div class="tabla_listado">
            <div class="text-center mb-2 mt-2" v-if="listado_citas.length > 0">
                <span class="p-2 bg-primary text-white">{{fecha_seleccionada}}</span>
            </div>
            <table cellpadding="0" cellspacing="0" class="tabla">
                <thead>
                    <tr>
                        <th>Horario</th>
                        <th>Paciente</th>
                        <th>tipo</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="listado in listado_citas" :key="listado.contador" :class="listado.class" @mouseover="hover_cita = listado.contador" @mouseleave="hover_cita = false" @dblclick="(listado.id_paciente != '')?getInfoPaciente(listado.id_paciente):generarCita(listado.hora_cita, listado.fecha, listado.duracion)">
                        <th style="width:60px !important"> {{ listado.hora }} </th>
                        <td v-if="hover_cita != listado.contador || listado.id_cita == ''"> {{ listado.paciente|uppercase }} </td>
                        <td v-if="hover_cita != listado.contador || listado.id_cita == ''"> {{ listado.tipo|uppercase }} </td>

                        <td class="align-middle" v-if="hover_cita == listado.contador && listado.id_cita != ''" colspan="2"> 
                            <div class="row col-12">
                                <div class="col-6 mt-2">
                                    <span class="h7 text-light font-weight-bold">{{ listado.paciente|uppercase }}</span>
                                </div>
                                <div class="col-3">
                                    <button type="button" class="btn btn-primary btn-sm" @click="verCita(listado.id_cita, listado.hora_cita, listado.fecha, listado.duracion, listado.estatus_consulta)">Cita</button>
                                </div>
                                <div class="col-3">
                                    <button type="button" class="btn btn-success btn-sm" @click="getInfoPaciente(listado.id_paciente, listado.id_cita)">{{listado.motivo}}</button>
                                </div>
                            </div>
                        </td>
                    </tr> 
                </tbody>
            </table>
        </div>

        <b-modal v-model="show_cita_listado" id="registrar_cita_listado" :title="(id_cita_ver)?'Editar Cita':'Nueva Cita'">
            <NuevaCita :estatus_consulta="estatus_consulta" @fechaListado="getListadoCitas" :id_cita="id_cita_ver" :id_medico_seleccionado="id_medico" :hora="hora" :fecha_cita="fecha_cita" :duracion_cita="duracion_cita" ></NuevaCita>
            <template v-slot:modal-footer>
                <div class="container-fluid"> 
                <div class="align-items-center">
                    <div class="text-center" style="margin-left:10px; margin-right:10px">
                    <b-button @click="show_cita_listado=false" class="btn btn-danger  btn-lg btn-block"> Cerrar </b-button>
                    </div>	
                </div>
                </div>
            </template>
        </b-modal>
              
    </div>
</template>

<script>
import NuevaCita from "../components/NuevaCita.vue";

export default {
    name: "ListadoCitas",

    components:{
        NuevaCita,
    },

    data: () => ({
        estatus_consulta: null,
        id_cita_ver: null,
        show_cita_listado: false,
        hora: null, 
        fecha_cita: null, 
        duracion_cita: null,
        hover_cita: false,
    }),

    props: ['listado_citas',
            'id_medico',
            'fecha_seleccionada'],

    methods:{
        getInfoPaciente(id_paciente, id_cita){
            this.$router.push('/editar_paciente/' + id_paciente + '/' + id_cita);
        },

        generarCita(hora, fecha_cita, duracion_cita){
            this.id_cita_ver = null;
            this.hora = hora; 
            this.fecha_cita = fecha_cita; 
            this.duracion_cita = duracion_cita;
            this.estatus_consulta = null;

            this.show_cita_listado = true;
        },

        verCita(id_cita, hora, fecha_cita, duracion_cita, estatus_consulta){
            this.estatus_consulta = estatus_consulta;
            this.hora = hora; 
            this.fecha_cita = fecha_cita; 
            this.duracion_cita = duracion_cita;
            this.id_cita_ver = id_cita;
            this.show_cita_listado = true;
        },

        getListadoCitas(fecha, getCalendarioCompleto){
            this.show_cita_listado = false;
            this.$emit('fechaListado', fecha, getCalendarioCompleto);
        }
  }
};
</script>