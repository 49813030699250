<template>
  <div class="body-login">
    <main>
      <div class="contenedor__todo">
        <div class="caja__trasera">
          <div class="caja__trasera_login">
            <h3>¿Ya tienes una cuenta?</h3>
            <!-- <p>Algun texto chido</p> -->
            <button id="btn__iniciar-sesion" @click="iniciarSesion">Inicia sesión</button>
          </div>

         <!-- <div class="caja__trasera_register">
            <h3>¿Aun no tienes una cuenta?</h3>
            <p>Algun texto chido</p> 
            <button id="btn__register" @click="register">Registrarse</button>
          </div> -->
        </div>

        <div class="contenedor__login_register">
          <form  @submit.prevent="login" class="formulario__login">
            <h2>Iniciar Sesión</h2>
            <input v-model="form_sesion.email" type="email" placeholder="Correo electronico" />
            <div v-if="login_error"><small id="passwordHelp" class="text-danger"> {{ login_error }}</small></div>
            <input v-model="form_sesion.password" type="password" placeholder="contraseña" />
            <button v-if="!iniciar_sesion" class="btn btn-primary" id="btn__iniciar-sesion">Entrar</button>

            <button v-else class="btn btn-primary" disabled>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Iniciando...
            </button>
          </form>

          <!-- <form @submit.prevent="registrar" class="formulario__register">
            <h2>Registrate</h2>
            
            <input class="form-control" v-bind:class="{ 'is-invalid': nombre_error }" v-model="form_register.nombre" type="text" placeholder="Nombre" />
            <div v-if="nombre_error"><small id="passwordHelp" class="text-danger"> {{ nombre_error }}</small></div>
            
            <input class="form-control" v-bind:class="{ 'is-invalid': apellido_paterno_error }" v-model="form_register.apellido_paterno" type="text" placeholder="Apellido paterno" />
            <div v-if="apellido_paterno_error"><small id="passwordHelp" class="text-danger"> {{ apellido_paterno_error }}</small></div>
            
            <input class="form-control" v-bind:class="{ 'is-invalid': apellido_materno_error }" v-model="form_register.apellido_materno" type="text" placeholder="Apellido materno" />
            <div v-if="apellido_materno_error"><small id="passwordHelp" class="text-danger"> {{ apellido_materno_error }}</small></div>

            <div class="col-md-12 mt-4">
                <div class="form-group row">
                    <label class="col-lg-6 control-label">Es medico:</label>
                    <label for="es_medico" class="col text-primary"><toggle-button :sync="true" v-model="es_medico" color="#43aee6" :labels="{ checked: 'SI', unchecked: 'NO' }"/></label>
                </div>
            </div>

            <input v-if="!es_medico" class="form-control" v-model="form_register.rol" type="text" placeholder="Rol" />

            <input class="form-control" v-bind:class="{ 'is-invalid': email_error }" v-model="form_register.email" type="email" placeholder="Correo electronico" />
            <div v-if="email_error"><small id="passwordHelp" class="text-danger"> {{ email_error }}</small></div>
            
            <input class="form-control" v-bind:class="{ 'is-invalid': password_error }" v-model="form_register.password" type="password" placeholder="contraseña" />
            <div v-if="password_error"><small id="passwordHelp" class="text-danger"> {{ password_error }}</small></div>
            
            <input class="form-control" v-bind:class="{ 'is-invalid': password_confirm_error }" v-model="form_register.confirm_password" type="password" placeholder="confirmar contraseña" />
            <div v-if="password_confirm_error"><small id="passwordHelp" class="text-danger"> {{ password_confirm_error }}</small></div>

            <button v-if="!registrar_usuario" class="btn btn-primary" id="btn__iniciar-sesion">Registrarse</button>

            <button v-else class="btn btn-primary" type="button" disabled>
              <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
              Registrando...
            </button>
          </form> -->
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import api from "../config.js"
var init = 0;
export default {

  data: () => ({
    form_sesion: {
      email: "ADMIN@HOTMAIL.COM",
      password: "admin"
    },
    form_register: {
      nombre: "",
      email: "",
      password: "",
      confirm_password: "",
      apellido_paterno: "",
      apellido_materno: "",
      es_medico: true,
      rol: '',
    },
    es_medico: true,
    iniciar_sesion: false,
    registrar_usuario: false,
    email_error: false,
    password_error: false,
    password_confirm_error: false,
    apellido_paterno_error: false,
    apellido_materno_error: false,
    nombre_error: false,
    login_error: false
  }),

  mounted(){
      init = 1;
      //this.anchoPagina();
      window.addEventListener("resize", this.anchoPagina);
  },

  updated(){
    //this.anchoPagina();
  },
  
  methods: {
    login() {
      this.iniciar_sesion = true;
      this.$store.dispatch("login", this.form_sesion).then(() => {
        location.reload();
      })
      .catch(() => {
        this.$toast.error('Favor de verificar los datos', {
          dismissible: true,
          position: 'top'
        });
        this.login_error = 'Usuario o contrasenia incorrectos'
        this.iniciar_sesion = false;
      });
    },

    async registrar(){
      (this.es_medico)? this.form_register.es_medico = '1' : this.form_register.es_medico = '0';

      this.registrar_usuario = true;
      var cookie = await api.get("/sanctum/csrf-cookie");
      if(cookie.status == 204){
        await api.post("/register", this.form_register).then(data => {
          if(data.status == 200){
            this.$toast.success('Registro creado exitosamente, ya puedes iniciar sesion', {
              dismissible: true,
              position: 'top'
            });
            this.iniciarSesion();
            this.form_register.nombre = "";
            this.form_register.email = "";
            this.form_register.password = "";
            this.form_register.confirm_password = "";
            this.form_register.apellido_paterno = "";
            this.form_register.apellido_materno = "";

            this.registrar_usuario = false;
          }
        }).catch((erro) =>{
          this.$toast.error('Favor de capturar los datos correctamente', {
            dismissible: true,
            position: 'top'
          });
          this.nombre_error           = (erro.response.data.error.nombre)?erro.response.data.error.nombre[0]:false;
          this.apellido_paterno_error = (erro.response.data.error.apellido_paterno)?erro.response.data.error.apellido_paterno[0]:false;
          this.apellido_materno_error = (erro.response.data.error.apellido_materno)?erro.response.data.error.apellido_materno[0]:false;
          this.email_error            = (erro.response.data.error.email)?erro.response.data.error.email[0]:false;
          this.password_error         = (erro.response.data.error.password)?erro.response.data.error.password[0]:false;
          this.password_confirm_error = (erro.response.data.error.confirm_password)?erro.response.data.error.confirm_password[0]:false;

          this.registrar_usuario = false;
        });
      }
      this.registrar_usuario = false;
    },

    register() {
        var contenedor_login_register = document.querySelector(".contenedor__login_register");
        var formulario_login          = document.querySelector(".formulario__login");
        var formulario_register       = document.querySelector(".formulario__register");
        var caja_trasera_login        = document.querySelector(".caja__trasera_login");
        var caja_trasera_register     = document.querySelector(".caja__trasera_register");

        init = 0;
        if(window.innerWidth > 950){
            formulario_register.style.display    = "block";
            contenedor_login_register.style.left = "510px";
            formulario_login.style.display       = "none";
            caja_trasera_register.style.opacity  = "0";
            caja_trasera_login.style.opacity     = "1";
        }else{
            formulario_register.style.display    = "block";
            contenedor_login_register.style.left = "0px";
            formulario_login.style.display       = "none";
            caja_trasera_register.style.display  = "none";
            caja_trasera_login.style.display     = "block";
            caja_trasera_login.style.opacity     = "1";
        }
    },

    iniciarSesion() {
        var contenedor_login_register = document.querySelector(".contenedor__login_register");
        var formulario_login          = document.querySelector(".formulario__login");
        var formulario_register       = document.querySelector(".formulario__register");
        var caja_trasera_login        = document.querySelector(".caja__trasera_login");
        var caja_trasera_register     = document.querySelector(".caja__trasera_register");

        init = 1;
        if(window.innerWidth > 950){
            formulario_register.style.display    = "none";
            contenedor_login_register.style.left = "10px";
            formulario_login.style.display       = "block";
            caja_trasera_register.style.opacity  = "1";
            caja_trasera_login.style.opacity     = "0";
        }else{
            formulario_register.style.display    = "none";
            contenedor_login_register.style.left = "0px";
            formulario_login.style.display       = "block";
            caja_trasera_register.style.display  = "block";
            caja_trasera_login.style.display     = "none";
        }
    },

    anchoPagina() {
        var contenedor_login_register = document.querySelector(".contenedor__login_register");
        var formulario_login          = document.querySelector(".formulario__login");
        var formulario_register       = document.querySelector(".formulario__register");
        var caja_trasera_login        = document.querySelector(".caja__trasera_login");
        var caja_trasera_register     = document.querySelector(".caja__trasera_register");

        if(window.innerWidth > 950){
            caja_trasera_login.style.display     = "block";
            caja_trasera_register.style.display  = "block";
        }else{
            caja_trasera_register.style.display  = "block";
            caja_trasera_register.style.opacity  = "1";
            caja_trasera_login.style.display     = "none";
            formulario_login.style.display       = "block";
            formulario_register.style.display    = "none";
            contenedor_login_register.style.left = "0px";
        }

        if(init == 1){
            this.iniciarSesion();
        }else{
            this.register();
        }
    }

  }
};
</script>

<style>
    @import "../assets/css/login.css";
</style>