<template>
    <div>
        <div class="">
            <section class="row">
                <div class="col-md-6 card-body">
                    <div class="form-group row" v-for="col in col_1" :key="col.clave">
                        <label for="nombre" style="font-size:13px" class="col-lg-4 control-label text-primary">{{ col.label }}</label>
                        <div class="col-lg-8">
                            <textarea class="form-control" v-model="aparatos_sistemas[col.clave]" style="width:100%; font-size:13px"></textarea>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 card-body">
                    <div class="form-group row" v-for="col in col_2" :key="col.clave">
                        <label for="nombre" style="font-size:13px" class="col-lg-4 control-label text-primary">{{ col.label }}</label>
                        <div class="col-lg-8">
                            <textarea class="form-control" v-model="aparatos_sistemas[col.clave]" style="width:100%; font-size:13px"></textarea>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
export default{
    name: "AparatosSistemas",

    props: ['data_aparato_sistemas'],

    data: () => ({
        aparatos_sistemas:{
            digestivo: '',
            cardiovascular: '',
            respiratorio: '',
            urinario: '',
            genital: '',
            hematologico: '',
            endocrino: '',
            osteomuscular: '',
            nervioso: '',
            sensorial: '',
            psicosomatico: '',
            aparato_sistemas_otro: ''
        },

        col_1:[
            {clave:'digestivo', label:'Digestivo'},
            {clave:'cardiovascular', label:'Cardiovascular'},
            {clave:'respiratorio', label:'Respiratorio'},
            {clave:'urinario', label:'Urinario'},
            {clave:'genital', label:'Genital'},
            {clave:'hematologico', label:'Hematológico'},
        ],

        col_2:[
            {clave:'endocrino', label:'Endocrino'},
            {clave:'osteomuscular', label:'Osteomuscular'},
            {clave:'nervioso', label:'Nervioso'},
            {clave:'sensorial', label:'Sensorial'},
            {clave:'psicosomatico', label:'Psicosomático'},
            {clave:'aparato_sistemas_otro', label:'Otro'},
        ],
    }),

    created: function(){
        if(this.data_aparato_sistemas){
            //this.aparatos_sistemas = this.data_aparato_sistemas;

            this.aparatos_sistemas.digestivo = this.data_aparato_sistemas.digestivo;
            this.aparatos_sistemas.cardiovascular = this.data_aparato_sistemas.cardiovascular;
            this.aparatos_sistemas.respiratorio = this.data_aparato_sistemas.respiratorio;
            this.aparatos_sistemas.urinario = this.data_aparato_sistemas.urinario;
            this.aparatos_sistemas.genital = this.data_aparato_sistemas.genital;
            this.aparatos_sistemas.hematologico = this.data_aparato_sistemas.hematologico;
            this.aparatos_sistemas.endocrino = this.data_aparato_sistemas.endocrino;
            this.aparatos_sistemas.osteomuscular = this.data_aparato_sistemas.osteomuscular;
            this.aparatos_sistemas.nervioso = this.data_aparato_sistemas.nervioso;
            this.aparatos_sistemas.sensorial = this.data_aparato_sistemas.sensorial;
            this.aparatos_sistemas.psicosomatico = this.data_aparato_sistemas.psicosomatico;
            this.aparatos_sistemas.aparato_sistemas_otro = this.data_aparato_sistemas.aparato_sistemas_otro;
        }
    },

    methods: {
        getInfo(){
            return this.aparatos_sistemas;
        }
    }
}

</script>