<template>
    <div>

        <div v-if="editar_info_medicamentos">
            <label for="nuevo_medicamento" class="col control-label text-primary"><span v-if="!reg_medicamento.id_medicamento">Nuevo medicamento </span> <span v-else>Editar medicamento </span><toggle-button :sync="true" v-model="nuevo_medicamento" color="#43aee6" :labels="{ checked: 'SI', unchecked: 'NO' }"/></label>
            <div v-if="nuevo_medicamento" class="card">
                <div class="card-body">
                    <div class="row col-12">
                        <div class="col-4">
                            <span class="col-12">Sustancia(s) activa(s)</span>
                            <input v-uppercase v-model="reg_medicamento.sustancia" class="col-12" type="text">
                        </div>

                        <div class="col-4">
                            <span class="col-12">Presentación</span>
                            <input v-uppercase v-model="reg_medicamento.presentacion" class="col-12" type="text">
                        </div>

                        <div class="col-4">
                            <span class="col-12">Nombre</span>
                            <input v-uppercase v-model="reg_medicamento.nombre" class="col-12" type="text">
                        </div>

                        <div class="col-4">
                            <span class="col-12">Indicación terapéutica</span>
                            <input v-uppercase v-model="reg_medicamento.indicacion" class="col-12" type="text">
                        </div>

                        <div v-if="!desde_alergia" class="col-4">
                            <span class="col-12">Concentración</span>
                            
                            <b-button class="btn-warning btn-sm" v-b-popover.hover.top="'La concentración debe de ir separada por coma (500mg, 200g, etc)'" >
                                !
                            </b-button>
                            
                            <input v-uppercase v-model="reg_medicamento.concentracion" class="col-12" type="text">
                        </div>

                        <div v-if="!desde_alergia" class="col-4">
                            <span class="col-12">Dosificación</span>
                            <input v-uppercase v-model="reg_medicamento.dosificacion" class="col-12" type="text">
                        </div>

                        <div class="col-4">
                            <span class="col-12">Farmacéutica</span>
                            <input v-uppercase v-model="reg_medicamento.farmaceutica" class="col-12" type="text">
                        </div>
                    </div>

                    <div class="col-12 mt-3">
                        <button v-if="!reg_medicamento.id_medicamento" class="btn btn-primary btn-lg btn-block" @click="registrarMedicamento">Agregar</button>
                        <div v-else >
                            <button class="btn btn-warning btn-lg btn-block" @click="editarMedicamento">Editar</button>
                            <button class="btn btn-secondary btn-lg btn-block" @click="cancelarEdicion">Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ul v-if="listado_medicamentos_mas_usados && listado_medicamentos_mas_usados.length > 0" class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <button role="tab" class="btn btn-outline-secondary" :class="{ active: general }" @click="cambioPestana('general')">
                    GENERAL
                </button>
            </li>
            <li class="nav-item">
                <button role="tab" class="btn btn-outline-secondary" :class="{ active: mas_usados }" @click="cambioPestana('mas_usados')">
                    MAS USADOS
                </button>
            </li>
        </ul>

        <b-row class="my-1">
            <b-col sm="1" class="ml-3 pt-2">
                <label for="input-none">Buscar:</label>
            </b-col>
            <b-col sm="9">
               <!-- <b-form-input @change="buscarMedicamento" @keyup.enter="buscarMedicamento" v-model="medicamento_buscar" type="search" id="filterInput" placeholder="Buscar" ></b-form-input> -->
               <b-form-input v-model="keyword" type="search" id="filterInput" placeholder="Buscar" ></b-form-input>
            </b-col>
        </b-row>

        <!-- <b-table :busy="isBusy" :filter="filter" :per-page="perPage" :items="listado_medicamentos" :bordered="true" :fields="fields" striped hover outlined responsive :current-page="currentPage"> -->
        <b-table :busy="isBusy" :keyword="keyword" :items="items" :bordered="false" :fields="fields" striped hover outlined responsive>
            <template #cell(concentracion)="row">
                <select v-if="row.item.concentracion" v-model="concentracion[row.item.id_medicamento]"  class="form-control">
                    <option :value="items" v-for="(items, index) in (row.item.concentracion).split(',')" :key="index">{{items}}</option>
                </select>
            </template>
            
            <template #cell(opciones)="row">
                <div v-if="!botones[row.item.id_medicamento] && !desde_catalogo" class="btn btn-outline-success mr-1 btn-sm" @click="addMedicamento(row.item.id_medicamento, row.item.nombre, row.item.dosificacion)">Registrar</div>
                <div v-if="editar_info_medicamentos" class="btn btn-outline-warning mr-1 btn-sm" @click="selectEditarMedicamento(row.item)">Editar</div>
                <div v-if="editar_info_medicamentos" class="btn btn-outline-danger mr-1 btn-sm" @click="selectEliminarMedicamento(row.item)">Eliminar</div>
            </template>

            <template #table-busy>
                <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
                </div>
            </template>
        </b-table>

        <div class="ml-3">
            <b-pagination :per-page="perPage" v-model="currentPage" pills :total-rows="rows"></b-pagination>
        </div>

        <b-overlay no-wrap fixed :show="show_eliminar_medicamento" rounded="sm">
            <template #overlay>
                <div>
                <p><strong id="form-confirm-label">¿Estás seguro de eliminar el medicamento {{ medicamento_eliminar }}?</strong></p>
                <div>
                    <b-button variant="outline-danger" class="mr-3" @click="btnCancelar">
                        NO
                    </b-button>
                    <b-button variant="outline-success" @click="btnAceptar">SI</b-button>
                </div>
                </div>
            </template>
        </b-overlay>

    </div>
</template>

<script>
const $ = require("jquery");

import api from "../config.js";

export default {
    name: 'ListadoMedicamentos',

    props: ['desde_alergia','desde_catalogo', 'clave'],

    data: () => ({
        show_eliminar_medicamento: false,
        medicamento_eliminar: null,
        id_eliminar: null,
        general: true,
        mas_usados: false,
        keyword: '',
        editar_info_medicamentos: false,
        nuevo_medicamento: false,
        reg_medicamento: {
            id_medicamento: null,
            nombre: '',
            sustancia: '',
            presentacion: '',
            concentracion: '',
            indicacion: '',
            dosificacion: '',
            farmaceutica: '',
        },
        isBusy: true,
        concentracion: [],
        filter: null,
        medicamento_buscar: null,
        currentPage: 1,
        perPage: 10,
        botones:[],
        inputs:[],
        listado_medicamentos: [],
        listado_medicamentos_mas_usados: [],
        listado_medicamentos_general: [],
        fields: [
            {
                key: "sustancia",
                label: "Sustancia(s) activa(s)",
                sortable: true
            },
            {
                key: "presentacion",
                label: "Presentación",
                sortable: true
            },
            {
                key: "nombre",
                label: "Nombre",
                sortable: true
            },
            {
                key: "indicacion",
                label: "Indicación terapéutica",
                sortable: true
            },
        ],
    }),

    created: async function(){
        var permisos = await this.$store.dispatch("getPermisos");

        if(permisos){
            this.editar_info_medicamentos = permisos.includes('CATAL');
        }

        if(!this.desde_alergia){
            this.fields.push({
                key: "concentracion",
                label: "Concentración",
                sortable: true
            });

            this.fields.push({
                key: "dosificacion",
                label: "Dosificación",
                sortable: true
            });
        }

        this.fields.push({
            key: "opciones",
            label: "",
            sortable: false
        });
        this.getListadoMedicamentos();

    },

    methods:{
        selectEliminarMedicamento(medicamento){
            this.show_eliminar_medicamento = true;
            this.medicamento_eliminar = medicamento.sustancia;
            this.id_eliminar = medicamento.id_medicamento;
        },
        btnCancelar(){
            this.show_eliminar_medicamento = false;
            this.medicamento_eliminar = null;
            this.id_eliminar = null;
        },
        async btnAceptar(){
            var listado = await api.post("api/eliminar_medicamento/" + this.id_eliminar);
            this.listado_medicamentos = listado.data;

            this.$toast.success("Se ha eliminado el medicamento", {
                dismissible: true,
                position: "top"
            });

            this.btnCancelar(); 
        },
        cambioPestana(pestana){
            switch(pestana){
                case 'general':
                    this.general = true;
                    this.mas_usados = false;

                    this.listado_medicamentos = this.listado_medicamentos_general;
                    break;
                case 'mas_usados':
                    this.mas_usados = true;
                    this.general = false;

                    this.listado_medicamentos = this.listado_medicamentos_mas_usados;
                    break;
            }
        },
        irArriba(){
            $('#catalogo_medicamentos').animate({ scrollTop:'1px' },500);
        },
        cancelarEdicion(){
            this.keyword = '';

            this.reg_medicamento.id_medicamento = null;
            this.reg_medicamento.nombre = '';
            this.reg_medicamento.sustancia = '';
            this.reg_medicamento.presentacion = '';
            this.reg_medicamento.concentracion ='';
            this.reg_medicamento.indicacion = '';
            this.reg_medicamento.dosificacion = '';
            this.reg_medicamento.farmaceutica = '';
        },
        selectEditarMedicamento(medicamento){
            this.irArriba();
            this.keyword = medicamento.sustancia;
            this.reg_medicamento.id_medicamento = medicamento.id_medicamento;
            this.reg_medicamento.nombre = medicamento.nombre;
            this.reg_medicamento.sustancia = medicamento.sustancia;
            this.reg_medicamento.presentacion = medicamento.presentacion;
            this.reg_medicamento.concentracion = medicamento.concentracion;
            this.reg_medicamento.indicacion = medicamento.indicacion;
            this.reg_medicamento.dosificacion = medicamento.dosificacion;
            this.reg_medicamento.farmaceutica = medicamento.farmaceutica;
        },
        async registrarMedicamento(){
            if(!this.reg_medicamento.nombre || !this.reg_medicamento.sustancia || !this.reg_medicamento.presentacion || !this.reg_medicamento.nombre || !this.reg_medicamento.indicacion || !this.reg_medicamento.farmaceutica){
                this.$toast.warning("Favor de llenar todos los campos", {
                    dismissible: true,
                    position: "top"
                });
            }else{
                var listado = await api.post("api/registrar_medicamento",this.reg_medicamento);
                this.listado_medicamentos = listado.data;

                this.$toast.success("Se ha registrado el medicamento", {
                    dismissible: true,
                    position: "top"
                });

                this.cancelarEdicion();
            }
        },
        
        async editarMedicamento(){
            if(!this.reg_medicamento.nombre || !this.reg_medicamento.sustancia || !this.reg_medicamento.presentacion || !this.reg_medicamento.nombre || !this.reg_medicamento.indicacion || !this.reg_medicamento.farmaceutica){
                this.$toast.warning("Favor de llenar todos los campos", {
                    dismissible: true,
                    position: "top"
                });
            }else{
                var id = this.reg_medicamento.id_medicamento;
                var listado = await api.post("api/actualizar_medicamento/" + id,this.reg_medicamento);
                this.listado_medicamentos = listado.data;

                this.$toast.success("El medicamento se ha actualizado", {
                    dismissible: true,
                    position: "top"
                });
            }
        },
        buscarMedicamento(){
            this.filter = this.medicamento_buscar;
        },
        async getListadoMedicamentos() {

            var url = "api/get_medicamentos";
            if(this.clave){
                url = "api/get_medicamentos?clave=" + this.clave;
            }
            
            var listado = await api.get(url);
            this.listado_medicamentos_general = listado.data.medicamentos;
            this.listado_medicamentos_mas_usados = listado.data.relacionado;

             this.listado_medicamentos = listado.data.medicamentos;

            this.isBusy = false;
        },
        addMedicamento(id_medicamento, nombre, dosis){
            var concent = this.concentracion[id_medicamento];
            this.botones[id_medicamento] = true;
            //FIXME ver porque funciona solamente con el splice
            this.botones.splice(0, 0 );
            this.inputs[id_medicamento] = false;
            //FIXME ver porque funciona solamente con el splice
            this.botones.splice(0, 0 );
            this.$emit('addMedicamento', id_medicamento, nombre, dosis, concent);
        },

        showInput(id_medicamento){
            this.inputs[id_medicamento] = !this.inputs[id_medicamento];
            //FIXME ver porque funciona solamente con el splice
            this.botones.splice(0, 0 );
        }
    },

    computed: {
        filtrado(){
            return this.keyword ? this.listado_medicamentos.filter(
                                           item => item.nombre.toLowerCase().includes(this.keyword.toLowerCase()) 
                                        || item.sustancia.toLowerCase().includes(this.keyword.toLowerCase()) 
                                        || item.presentacion.toLowerCase().includes(this.keyword.toLowerCase()) 
                                        || ((item.concentracion)?item.concentracion.toLowerCase().includes(this.keyword.toLowerCase()): '')
                                        || item.indicacion.toLowerCase().includes(this.keyword.toLowerCase()) 
                                        || item.farmaceutica.toLowerCase().includes(this.keyword.toLowerCase()) 
                                        || ((item.dosificacion)?item.dosificacion.toLowerCase().includes(this.keyword.toLowerCase()): '')
                                        ) : this.listado_medicamentos;
        },
        rows() {
            return this.filtrado.length;
        },

        items(){
            return this.filtrado.slice((this.currentPage - 1) * this.perPage,this.perPage * this.currentPage);
        }
    }
}
</script>