<template>
    <div class="container">
        <div class="card">
            <div cclass="card-body">
                <div class="form-group row">
                    <label class="col-lg-4 control-label text-primary">Contraseña actual:</label>
                    <div class="col-lg-8">
                        <input type="password" class="form-control text-center">
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-lg-4 control-label text-primary">Contraseña Nueva:</label>
                    <div class="col-lg-8">
                        <input type="password" class="form-control text-center">
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-lg-4 control-label text-primary">Confirmar Contraseña:</label>
                    <div class="col-lg-8">
                        <input type="password" class="form-control text-center">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    export default{
        name: 'CambioContraseña',
    }
</script>