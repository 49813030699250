<template>
    <div>
        <div v-if="editar_logotipo">
            <label for="nuevo_logotipo" class="col control-label text-primary"><span v-if="!reg_logotipo.id_logotipo">Nuevo logotipo </span> <span v-else>Editar logotipo </span><toggle-button :sync="true" v-model="nuevo_logotipo" color="#43aee6" :labels="{ checked: 'SI', unchecked: 'NO' }"/></label>
            <div v-if="nuevo_logotipo" class="card">
                <div class="card-body">
                    <div class="row col-12">
                        <div class="col-12">
                            <span class="col-12">Nombre</span>
                            <input v-uppercase v-model="reg_logotipo.nombre" class="col-12" type="text">
                        </div>

                        <ImageUpload ref="ref_file"></ImageUpload>
                    </div>

                    <div class="col-12 mt-3">
                        <button v-if="!reg_logotipo.id_logotipo" class="btn btn-primary btn-lg btn-block" @click="registrarLogotipo">Agregar</button>
                        <div v-else >
                            <button class="btn btn-warning btn-lg btn-block" @click="editarLogotipoSubmit">Editar</button>
                            <button class="btn btn-secondary btn-lg btn-block" @click="cancelarEdicion">Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div style="float: left; margin: 20px" v-for="(logo, index) in listado_logotipos" :key="index" class="imagen-preview">
            <div class="img-wrapper">
                <img height="150" width="150" :src="logo.url_imagen" alt="imagen">
                <div class="details">
                    <span style="width: 150px; display: inline-block; background: #fff; color: #000; font-size: 12px" class="name">{{ logo.nombre }}</span>
                </div>
                <div>
                    <button class="btn btn-danger btn-sm mr-2" @click="eliminarLogotipo(logo.id_logotipo)">Eliminar</button>
                    <button class="btn btn-warning btn-sm" @click="editarLogotipo(logo.id_logotipo, logo.url_imagen, logo.nombre)">Editar</button>
                </div>
            </div>
        </div>
        <Loading :show="showLoading"></Loading>
    </div>
</template>

<script>
import api from "../../config.js";
import Loading from "../Loading.vue";

import ImageUpload from '../dragAndDrop/ImageUpload.vue';
export default {
    name: 'Logotipos',

    components: {
        ImageUpload,
        Loading
    },

    data: () => ({
        showLoading: false,
        show_mensaje_eliminar_logotipo:false,
        id_logotipo_eliminar: null,
        logotipo_eliminar: '',
        keyword: '',
        editar_logotipo: true,
        nuevo_logotipo: true,
        reg_logotipo: {
            id_logotipo: null,
            nombre: '',
        },
        listado_logotipos: [],
    }),

    created: async function(){
        await this.getLogotipos();
    },

    methods:{
        async registrarLogotipo(){
            const formData = new FormData();
            var file = this.$refs.ref_file.getFile();

            if(!file){
                this.$toast.error("Favor de seleccionar una imagen", {
                    dismissible: true,
                    position: "top"
                });
                return;
            }

            if(!this.reg_logotipo.nombre || this.reg_logotipo.nombre.trim() == ''){
                this.$toast.error("Favor de poner un nombre", {
                    dismissible: true,
                    position: "top"
                });
                return;
            }

            this.reg_logotipo.nombre = this.reg_logotipo.nombre.toUpperCase().trim();

            formData.append('image', file, file.name);
            formData.append('nombre', this.reg_logotipo.nombre);

            this.showLoading = true;
            var datos = await api.post("api/nuevo_logotipo", formData);

            this.showLoading = false;

            if(datos.data.tipo == 'Exito'){
                this.$refs.ref_file.cleanFile();
                this.reg_logotipo.nombre = '';
                this.$toast.success("El logotipo se ha registrado", {
                    dismissible: true,
                    position: "top"
                });
            }else{
                this.$toast.error("Ocurrio un error vuelve a intentarlo", {
                    dismissible: true,
                    position: "top"
                });
            }

            this.listado_logotipos = datos.data.logotipos;
        },
        async eliminarLogotipo(id_logotipo){
            if(!confirm('Estas seguro de eliminar el logotipo')){
                return;
            }
            this.showLoading = true;
            var datos = await api.get("api/eliminar_logotipo/" + id_logotipo);
            this.showLoading = false;

            if(datos.data.tipo == 'Exito'){
                this.$toast.success("El logotipo se ha eliminado", {
                    dismissible: true,
                    position: "top"
                });
            }else{
                this.$toast.error("Ocurrio un error vuelve a intentarlo", {
                    dismissible: true,
                    position: "top"
                });
            }

            this.listado_logotipos = datos.data.logotipos;
        },
        async editarLogotipoSubmit(){
            const formData = new FormData();
            var file = this.$refs.ref_file.getFile();
            if(file){
                formData.append('image', file, file.name);
            }

            if(!this.reg_logotipo.nombre || this.reg_logotipo.nombre.trim() == ''){
                this.$toast.error("Favor de poner un nombre", {
                    dismissible: true,
                    position: "top"
                });
                return;
            }

            this.reg_logotipo.nombre = this.reg_logotipo.nombre.toUpperCase().trim();

            formData.append('nombre', this.reg_logotipo.nombre);
            formData.append('id_logotipo', this.reg_logotipo.id_logotipo);

            this.showLoading = true;
            var datos = await api.post("api/editar_logotipo", formData);
            this.showLoading = false;

            if(datos.data.tipo == 'Exito'){
                this.$toast.success("Se ha editado el logotipo correctamente", {
                    dismissible: true,
                    position: "top"
                });

                this.cancelarEdicion();
            }

            this.listado_logotipos = datos.data.logotipos;
        },
        editarLogotipo(id, url, nombre){
            this.reg_logotipo.nombre = nombre;
            this.reg_logotipo.id_logotipo = id;
            this.$refs.ref_file.setImagen(url, nombre);
        },
        cancelarEdicion(){
            this.reg_logotipo.id_logotipo = null;
            this.reg_logotipo.nombre = '';
            this.$refs.ref_file.cleanFile();
        },

        async getLogotipos(){
            var datos = await api.get("api/get_logotipos");

            this.listado_logotipos = datos.data;
        }
    },
}
</script>