<template>
    <div style="margin:0">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" v-if="ver_receta">
                <button role="tab" class="btn btn-outline-secondary" :class="{ active: receta }" @click="cambioPestana('receta')">
                    Receta Medica
                </button>
            </li>
            <li class="nav-item" v-if="ver_estudios">
                <button role="tab" class="btn btn-outline-secondary" :class="{ active: estudios }" @click="cambioPestana('estudios')">
                    Estudios
                </button>
            </li>
            <li class="nav-item" v-if="ver_nota_interconsulta">
                <button role="tab" class="btn btn-outline-secondary" :class="{ active: nota_interconsulta }" @click="cambioPestana('nota_interconsulta')">
                    Nota de Interconsulta
                </button>
            </li>
        </ul>

        <div id="div_imprimir">
            <div class="page-header" style="text-align: center">
                <div class="header-info" style="width:100%;">
                    <div style="float:left; width:20%">
                        <img height="140" width="140" src="../../assets/imagenes/consultorio.jpeg" />
                        <!-- <img id="img_schedule" height="140" width="140" src="../../assets/imagenes/medical_schedule.png" /> -->
                        <!-- <img id="img_schedule" height="140" width="140" src="../../assets/imagenes/fanyta.png" /> -->
                    </div>

                    <div style="float:left; width:60%;">
                        <div class="nombreMedico"><center><span><strong>{{ alias }} {{ datos_generales.nombre_doctor | uppercase }}</strong></span></center></div>
                        <hr>
                        <div style="width:100%">
                            <div style="float:left;" class="tamanioLetra">
                                <span><strong>Ced. Prof. {{ datos_generales.cedula | uppercase }}</strong></span>
                            </div>

                            <div style="padding-right:40%; float:right;" class="tamanioLetra">
                                <span><strong>MEDICINA GENERAL</strong></span>
                            </div>
                        </div>
                        
                        <br>
                        <br>
        
                        <div style="width:100%">
                            <div style="float:left; padding-left:10px;" class="tamanioLetra">
                                <span><strong>Nombre del paciente:</strong> {{ datos_generales.nombre_paciente | uppercase }}</span>
                            </div>
            
                            <div style="float:right; padding-right:10px;" class="tamanioLetra">
                                <span>Fecha: {{ datos_generales.fecha }}</span>
                            </div>
                        </div>
                    </div>

                    <div style="float:left; width:20%">
                        <img height="140" width="115" :src="this.url_logotipo" />
                    </div>
                </div>
            </div>

            <table width="100%">
                <thead>
                    <tr>
                        <td>
                            <div class="page-header-space"></div>
                        </td>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>
                            <div style="width:100%">
                                <div v-if="!nota_interconsulta" class="td_fixed tamanioLetra">
                                    <br>
                                    <div class="tamanioLetra" v-if="datos_generales.fecha_nacimiento"><strong>Edad:</strong> {{ calcularEdad(datos_generales.fecha_nacimiento) }} años</div>
                                    <div class="tamanioLetra SignosVitales" v-if="json_signos_vitales.peso"><strong>Peso:</strong> {{ json_signos_vitales.peso }} Kg</div>
                                    <div class="tamanioLetra SignosVitales" v-if="json_signos_vitales.talla "><strong>Talla:</strong> {{ json_signos_vitales.talla }} cm.</div>
                                    <div class="tamanioLetra SignosVitales" v-if="datos_generales.tipo_sangre"><strong>Grupo:</strong> {{ datos_generales.tipo_sangre }}</div>
                                    <div class="tamanioLetra SignosVitales" v-if="json_signos_vitales.temp"><strong>Temperatura:</strong> {{ json_signos_vitales.temp }}°C</div>
                                    <div class="tamanioLetra SignosVitales" v-if="json_signos_vitales.ta"><strong>T.A.:</strong> {{ json_signos_vitales.ta }}</div>
                                    <div class="tamanioLetra SignosVitales" v-if="json_signos_vitales.fc"><strong>F.C:</strong> {{ json_signos_vitales.fc }}</div>
                                    <div class="tamanioLetra SignosVitales" v-if="json_signos_vitales.fr"><strong>F.R.:</strong> {{ json_signos_vitales.fr }}</div>
                                    <div class="tamanioLetra SignosVitales" v-if="json_signos_vitales.sat_o2"><strong>SAT DE 02.:</strong> {{ json_signos_vitales.sat_o2 }}</div>
                                    <div class="tamanioLetra SignosVitales" v-if="json_signos_vitales.glucosa"><strong>GLUCOSA.:</strong> {{ json_signos_vitales.glucosa }}</div>
                                </div>
                                
                                <div class="page div_container" style="width:100%">
                                    <div style="width:100%">
                                        <div v-if="receta">
                                            <div style="padding-bottom:20px" class="tamanioLetra no_salto_de_linea" v-for="(medicamento, index) in medicamentos" :key="index">
                                                <div style="width:65%; text-align: justify;"><strong>{{ index + 1 }}. {{ medicamento.sustancia | uppercase }} {{ medicamento.presentacion | uppercase }} {{ medicamento.concentracion | uppercase }}</strong></div>
                                                <div style="padding-left:20px; width:64%; text-align: justify;"><span style="white-space: break-spaces" class="tamanioLetra"><i>{{ medicamento.nombre | uppercase }}</i></span></div>
                                                <div style="padding-left:20px; width:63%; text-align: justify;"><span style="white-space: break-spaces" class="tamanioLetra">{{ medicamento.dosis | uppercase }}</span></div>
                                            </div>
                                            <br>
                                            <div class="no_salto_de_linea">
                                                <div class="tamanioLetra">
                                                    <span><strong>Medidad generales/Indicaciones:</strong></span>
                                                </div>
                                                <div class="tamanioLetra" style="width:65%;">
                                                    <span class="tamanioLetra" style="white-space: break-spaces">{{ datos_generales.indicaciones | uppercase }}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div v-if="estudios">
                                            <div class="tamanioLetra" style="padding-left:100px">
                                                <strong>* SOLICITUD DE ESTUDIOS *</strong>
                                            </div>
                                            <br>
                                            <div style="padding-bottom:20px" class="tamanioLetra no_salto_de_linea" v-for="(listado, index) in listado_estudios" :key="index">
                                                <div><strong>• {{ listado.nombre | uppercase }}</strong></div>
                                                <div style="padding-left:20px; width:65%; white-space: break-spaces">{{ listado.notas  | uppercase}}</div>
                                            </div>

                                            <br>
                                            <div class="no_salto_de_linea">
                                                <div class="tamanioLetra">
                                                    <span><strong>Notas de estudio:</strong></span>
                                                </div>
                                                <div class="tamanioLetra" style="width:65%;">
                                                    <span class="tamanioLetra" style="white-space: break-spaces">{{ datos_generales.notas_estudios | uppercase }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div v-if="nota_interconsulta">
                                            <div class="tamanioLetra" style="padding-left:260px;">
                                                <strong>* SOLICITUD DE INTERCONSULTA *</strong>
                                            </div>
                                            <br>
                                            <div class="tamanioLetra">
                                                <span class="tamanioLetra">SERVICIO QUE SOLICITA LA INTERCONSULTA:  </span><span class="tamanioLetra">{{ datos_generales.servicio_interconsulta | uppercase }}</span>
                                            </div>
                                            <br>
                                            <br>
                                            <div class="tamanioLetra">
                                                <span class="tamanioLetra">SERVICIO CONSULTADO:  </span><span class="tamanioLetra">{{ datos_generales.servicio_consultado | uppercase }}</span>
                                            </div>
                                            <br>
                                            <div class="tamanioLetra" style="width:100%">
                                                <table style="width:100%">
                                                    <tr>
                                                        <td><span v-if="json_signos_vitales.ta">TENSION ARTERIAL: {{ json_signos_vitales.ta }}</span></td>
                                                        <td><span v-if="json_signos_vitales.ta">FRECUENCIA CARDIACA: {{ json_signos_vitales.fc }}</span></td>
                                                        <td><span v-if="json_signos_vitales.ta">FRECUENCIA RESPIRATORIA: {{ json_signos_vitales.fr }}</span></td>
                                                        <td><span v-if="json_signos_vitales.ta">TEMPERATURA: {{ json_signos_vitales.fr }}</span></td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <hr>
                                            <div class="tamanioLetra">
                                                <span style="width:65%; white-space: break-spaces" class="tamanioLetra">{{ datos_generales.interconsulta | uppercase }}</span>
                                            </div>

                                            <br>
                                            
                                            <div class="tamanioLetra">
                                                <span class="tamanioLetra" style="width:65%; white-space: break-spaces">{{ datos_generales.sintomas | uppercase }}</span>
                                            </div>
                                            
                                            <br>

                                            <div class="tamanioLetra">
                                                <div>
                                                    <span class="tamanioLetra"><strong>HABITUS EXTERIOR:</strong></span>
                                                </div>
                                                <div>
                                                    <span style="width:65%; white-space: break-spaces" class="tamanioLetra">{{ datos_generales.habitus_exterior | uppercase }}</span>
                                                </div>
                                            </div>

                                            <br>

                                            <div class="tamanioLetra">
                                                <div>
                                                    <span class="tamanioLetra"><strong>CABEZA:</strong></span>
                                                </div>
                                                <div>
                                                    <span style="width:65%; white-space: break-spaces" class="tamanioLetra">{{ datos_generales.cabeza | uppercase }}</span>
                                                </div>
                                            </div>

                                            <br>

                                            <div class="tamanioLetra">
                                                <div>
                                                    <span class="tamanioLetra"><strong>CUELLO:</strong></span>
                                                </div>
                                                <div>
                                                    <span style="width:65%; white-space: break-spaces" class="tamanioLetra">{{ datos_generales.cuello | uppercase }}</span>
                                                </div>
                                            </div>

                                            <br>

                                            <div class="tamanioLetra">
                                                <div>
                                                    <span class="tamanioLetra"><strong>TÓRAX Y CARDIOPULMONAR:</strong></span>
                                                </div>
                                                <div>
                                                    <span style="width:65%; white-space: break-spaces" class="tamanioLetra">{{ datos_generales.torax | uppercase }}</span>
                                                </div>
                                            </div>

                                            <br>

                                            <div class="tamanioLetra">
                                                <div>
                                                    <span class="tamanioLetra"><strong>GENITALES EXT.RECTAL Y/O VAGINAL:</strong></span>
                                                </div>
                                                <div>
                                                    <span style="width:65%; white-space: break-spaces" class="tamanioLetra">{{ datos_generales.genitales | uppercase }}</span>
                                                </div>
                                            </div>

                                            <br>

                                            <div class="tamanioLetra">
                                                <div>
                                                    <span class="tamanioLetra"><strong>EXTREMIDADES:</strong></span>
                                                </div>
                                                <div>
                                                    <span style="width:65%; white-space: break-spaces" class="tamanioLetra">{{ datos_generales.extremidades | uppercase }}</span>
                                                </div>
                                            </div>

                                            <br>

                                            <div class="tamanioLetra">
                                                <div>
                                                    <span class="tamanioLetra"><strong>COLUMNA VERTEBRAL:</strong></span>
                                                </div>
                                                <div>
                                                    <span style="width:65%; white-space: break-spaces" class="tamanioLetra">{{ datos_generales.columna_vertebral | uppercase }}</span>
                                                </div>
                                            </div>

                                            <br>

                                            <div class="tamanioLetra">
                                                <div>
                                                    <span class="tamanioLetra"><strong>ABDOMEN:</strong></span>
                                                </div>
                                                <div>
                                                    <span style="width:65%; white-space: break-spaces" class="tamanioLetra">{{ datos_generales.abdomen | uppercase }}</span>
                                                </div>
                                            </div>

                                            <br>
                                            <br>

                                            <div style="padding-bottom:20px" v-for="(medicam_agrupados, index) in medicamentos_agrupados" :key="index">
                                                <div><strong>{{ index }} - {{ medicam_agrupados.nombre }}</strong></div>
                                                <div class="no_salto_de_linea" v-for="(agrupacion, index2) in medicam_agrupados" :key="index2">
                                                    <div v-if="parseInt(index2) + 1" style="padding-left:20px; width:65%; text-align: justify;"><strong>{{ parseInt(index2) + 1 }} . {{ agrupacion.sustancia | uppercase }} {{ agrupacion.presentacion | uppercase }} {{ agrupacion.concentracion | uppercase }}</strong></div>
                                                    <div style="padding-left:40px; width:64%; text-align: justify;"><span style="white-space: break-spaces" class="tamanioLetra"><i>{{ agrupacion.nombre | uppercase }}</i></span></div>
                                                    <div style="padding-left:40px; width:63%; text-align: justify;"><span style="white-space: break-spaces" class="tamanioLetra">{{ agrupacion.dosis | uppercase }}</span></div>
                                                </div> 
                                            </div>

                                            <br>
                                            <div class="no_salto_de_linea">
                                                <div class="tamanioLetra">
                                                    <span class="tamanioLetra"><strong>Medidad generales/Indicaciones:</strong></span>
                                                </div>
                                                <div class="tamanioLetra" style="width:65%;">
                                                    <pre><span class="tamanioLetra" style="white-space: break-spaces">{{ datos_generales.indicaciones | uppercase }}</span></pre>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>

                <tfoot>
                    <tr>
                        <td>
                        <div class="page-footer-space"></div>
                        </td>
                    </tr>
                </tfoot>
            </table>

            <div class="page-footer footer_vista">
                <hr>
                <div style="width: 100%; padding-bottom:15px; padding-left:100px">
                    <div style="float:left; width: 30%" class="tamanioLetra">
                        <span>Tel: {{ datos_generales.tel }}</span>
                    </div>
                    <div style="float:right; width: 65%" class="tamanioLetra">
                        <span>{{ datos_generales.domicilio }}</span>
                    </div>
                </div>
            </div>
        </div> 
    </div> 
</template>

<script>
import api from "../../config.js";

export default {
    name: 'RecetaMedica',
    props: ['id_cita', 'ver_receta', 'ver_estudios', 'ver_nota_interconsulta'],

    data: () => ({
        receta: true,
        estudios: false,
        nota_interconsulta: false,
        alias: 'DR.',
        datos_generales: [],
        json_signos_vitales: [],
        medicamentos:[],
        listado_estudios:[],
        url_logotipo: "",
        medicamentos_agrupados: []
    }),

    created: function(){
        if(this.ver_receta && this.ver_estudios && this.ver_nota_interconsulta){
            this.receta = true;
            this.estudios = false;
            this.nota_interconsulta = false;
        }else if(this.ver_receta){
            this.receta = true;
            this.estudios = false;
            this.nota_interconsulta = false;
        }else{
            this.receta = false;
            this.estudios = true;
            this.nota_interconsulta = false;
        }
        this.getInfoReceta();
        
    },

    methods:{
        imprimir(){
            const mi_div = document.getElementById('div_imprimir').innerHTML; 

            const WinPrint = window.open('', '', 'left=0,width=1200,height=500,toolbar=0,scrollbars=1,estatus=0');
            
            var estilos = `.tamanioLetra{
                                font-size: 9pt;
                            }`;
            if(!this.nota_interconsulta){
            estilos = `.page-header, .page-header-space {
                                    height: 150px;
                                }

                                .SignosVitales{
                                    padding-top:30px;
                                }

                                .page-footer, .page-footer-space {
                                    height: 50px;
                                }

                                .page-footer {
                                    position: fixed;
                                    bottom: 0;
                                    width: 100%;
                                }

                                .page-header {
                                    position: fixed;
                                    top: 0mm;
                                    width: 100%;
                                }

                                .page {
                                    page-break-after: always;
                                }

                                @page {
                                    size: letter;
                                    margin-bottom: 140mm;
                                    margin-top: 4mm;
                                    margin-left: 4mm;
                                    margin-right: 2mm;
                                }

                                @media print {
                                    thead {display: table-header-group;} 
                                    tfoot {display: table-footer-group;}
                                    
                                    button {display: none;}
                                    
                                    body {margin: 0;}
                                }

                                .nombreMedico{
                                    font-family: Arial, Helvetica, sans-serif;
                                    font-size: 16pt;
                                }

                                .tamanioLetra{
                                    font-family: Arial, Helvetica, sans-serif;
                                    font-size: 12pt;
                                }

                                .no_salto_de_linea{
                                    page-break-inside: avoid;
                                }

                                .td_fixed{
                                    padding-left:30px;
                                    position:fixed; 
                                    top:135px;
                                    width:30%;
                                }                            

                                .div_container{
                                    padding-left:25%;
                                }`;
            }

            WinPrint.document.write(`<!DOCTYPE html>
                                        <html>
                                            <head>
                                                <meta name="viewport" content="width=device-width, initial-scale=1">
                                                <style>
                                                    ${estilos}
                                                </style>
                                            </head>
                                            <body>
                                                ${mi_div}
                                            </body>
                                        </html>`);

            setTimeout(function(){
                    WinPrint.document.close();
                    WinPrint.focus();
                    WinPrint.print();
              },1000);
            
        },
        calcularEdad(fecha) {
            var hoy = new Date();
            var cumpleanos = new Date(fecha);
            var edad = hoy.getFullYear() - cumpleanos.getFullYear();
            var m = hoy.getMonth() - cumpleanos.getMonth();

            if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
                edad--;
            }

            return edad;
        },

        cambioPestana(pestana){
            switch(pestana){
                case 'receta':
                    this.receta = true;
                    this.estudios = false;
                    this.nota_interconsulta = false;
                    break;
                case 'estudios':
                    this.estudios = true;
                    this.receta = false;
                    this.nota_interconsulta = false;
                    break;
                case 'nota_interconsulta':
                    this.estudios = false;
                    this.receta = false;
                    this.nota_interconsulta = true
                    break;
            }
        },

        async getInfoReceta() {
            var listado = await api.post("api/get_info_receta/"+this.id_cita);
            this.datos_generales = listado.data.datos_generales;
            this.medicamentos_agrupados = listado.data.medicamentos_agrupados;

            this.medicamentos = listado.data.medicamentos;
            this.listado_estudios = listado.data.estudios;

            if(listado.data.datos_generales.json_signos_vitales){
                this.json_signos_vitales = JSON.parse(listado.data.datos_generales.json_signos_vitales);
            }

            this.datos_generales.nombre_doctor   = this.datos_generales.nombre_doctor.toUpperCase();
            this.datos_generales.nombre_paciente = this.datos_generales.nombre_paciente.toUpperCase();
            
            this.url_logotipo = listado.data.url_logotipo.url_imagen;

            if(this.datos_generales.sexo == 'F'){
                this.alias = 'DRA.';
            }
        },
    }
}
</script>

<style lang="css">
.td_fixed{
    float:left; 
    padding-top: 10px;
}

.div_container{
    float:left; 
    padding-left:10%;
    padding-top: 10px;
}
    /* main{
        margin-top: auto !important;
    }

    .div_imprimit{
        margin: 0;
    } */

    @page {
        /* size: letter;
        margin: 3mm 1mm 150mm 1mm; */
    }

    /* table.report-container {
      page-break-after:always;
}
thead.report-header {
      display:table-header-group;
}
tfoot.report-footer {
      display:table-footer-group;
}  */



    /*@media print{
        .principal{
            height: 140mm; width: 216mm;
        }

        header{
            position: fixed;
        }
        .lateral{
            position: fixed;
            padding-top: 160px;
        }
        .contenido{
            padding-top: 160px;
            padding-left: 25%;
        }
        footer {
            position: fixed;
            bottom: 0;
            margin-top: 100px !important;
           
        }
    }*/

    /*@media print {
        .contenido{
            height: 100px;
        }
        header {
            margin-top: 0px !important;
            width: 100%;
            text-align: center;
        }

        footer {
            margin-top: 10px !important;
            width: 100%;
            position: fixed;
            bottom: 0;
            text-align: center;
        }

        body {
            width: 216mm;
            height: 279mm;
        }
    }*/

    .tamanioLetra{
        font-family: Arial, Helvetica, sans-serif;
        font-size: 10pt;
    }

    /* @media print {
        @page { margin-top: 10px; }
    } */
</style>