<template>
    <div class="container">
        <div class="card">
            <div class="card-body">
                <b-row class="my-1">
                    <b-col sm="1" class="ml-3 pt-2">
                        <label for="input-none">Buscar:</label>
                    </b-col>
                    <b-col sm="7">
                        <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Buscar" ></b-form-input>
                        <p class="text-primary">Usuarios registrados: {{ rows }}</p>
                    </b-col>
                    <b-col v-if="agregar_nuevo_usuario" sm="3" class="ml-1">
                        <button class="btn btn-success" @click="nuevo_usuario = true">Registrar nuevo usuario</button>
                    </b-col>
                </b-row>

                <b-table :items="usuarios" :fields="fields" :busy="false" :per-page="perPage" :small="true" :bordered="true" :foot-clone="true" :current-page="currentPage" caption-top striped hover outlined responsive :filter="filter">
                    <template #cell(opciones)="row">
                        <b-link v-if="editar_info_otros_usuarios || id_usuario_sesion == row.item.id_usuario" class="btn btn-outline-success mr-1 btn-sm" @click="showInfoUsuario(row.item.id_usuario)">Ver</b-link>
                        <b-link v-if="row.item.es_medico == '1' && (editar_horarios_otros_usuarios || id_usuario_sesion == row.item.id_usuario)" class="btn btn-outline-success mr-1 btn-sm" @click="showHorarioUsuario(row.item.id_usuario)">Horario</b-link>
                        <b-link v-if="editar_permisos" class="btn btn-outline-success mr-1 btn-sm" @click="showListadoPermisos(row.item.id_usuario)">Permisos</b-link>
                    </template>
                </b-table>

                <div class="ml-3">
                    <b-pagination :per-page="perPage" v-model="currentPage" pills :total-rows="rows"></b-pagination>
                </div>
            </div>
        </div>

        <b-modal v-model="usuario_modal" size="xl" id="perfil_usuario_listado" title="Usuario">
            <PerfilUsuario :id_usuario="id_usuario_mostrar"></PerfilUsuario>
        </b-modal>

        <b-modal v-model="horario_modal" size="xl" id="horario_modal_listado" title="Horario">
            <HorarioMedico :id_usuario="id_usuario_mostrar"></HorarioMedico>
        </b-modal>

        <b-modal v-model="permiso_modal" size="xl" id="permiso_modal_listado" title="Permisos">
            <Permisos :id_usuario="id_usuario_mostrar"></Permisos>
        </b-modal>

         <b-modal :hide-footer="true" v-model="nuevo_usuario" size="xl" id="nuevo_usuario_modal" title="Nuevo Usuario">
            <NuevoUsuario></NuevoUsuario>
        </b-modal>
        
    </div>
</template>

<script>
import api from "../config.js";

import PerfilUsuario from "./PerfilUsuario.vue";
import HorarioMedico from "../components/HorarioMedico.vue";
import Permisos from "../components/usuario/Permisos.vue";
import NuevoUsuario from "../components/NuevoUsuario.vue";

export default {
    name: 'ListadoUsuario',

    components:{
        PerfilUsuario,
        HorarioMedico,
        Permisos,
        NuevoUsuario
    },

    data: () => ({
        nuevo_usuario: false,
        editar_permisos: false,
        id_usuario_sesion: null,
        filter: null,
        perPage: 20,
        currentPage: 1,
        id_usuario_mostrar: null,
        usuario_modal:false,
        horario_modal:false,
        permiso_modal:false,
        editar_info_otros_usuarios:false,
        editar_horarios_otros_usuarios:false,
        agregar_nuevo_usuario: false,
        permisos: [],

        fields: [
        {
            key: "nombre",
            sortable: true
        },
        {
            key: "opciones",
            label: "",
            sortable: false
        }
        ],
        usuarios: []
    }),

  created: async function() {
    var usuario = JSON.parse(localStorage.getItem('usuario'));
    this.id_usuario_sesion = usuario.id_usuario;

    await this.getListadoUsuarios();

    this.permisos.forEach(permiso => {
        if(permiso.id_permiso_to_usuario){
            switch(permiso.clave){
                case 'INFOU':
                    this.editar_info_otros_usuarios = true;
                    break;
                case 'HORAU':
                    this.editar_horarios_otros_usuarios = true;
                    break;
                case 'REGUS':
                    this.agregar_nuevo_usuario = true;
                    break;
                case 'PERMI':
                    this.editar_permisos = true;
                    break;
            }
        }
    });
    
  },

  methods: {
    async getListadoUsuarios() {
        var listado = await api.get("api/get_listado_usuarios");
        this.usuarios = listado.data.usuarios;
        this.permisos = listado.data.permisos;
    },

    showHorarioUsuario(id_usuario){
        this.horario_modal = true;
        this.id_usuario_mostrar = id_usuario;
    },

    showInfoUsuario(id_usuario){
        this.usuario_modal = true;
        this.id_usuario_mostrar = id_usuario;
    },

    showListadoPermisos(id_usuario){
        this.permiso_modal = true;
        this.id_usuario_mostrar = id_usuario;
    },
  },

  computed: {
    rows() {
      return this.usuarios.length;
    }
  }
}
</script>