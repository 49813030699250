<template>
    <div>
        <PercentilesLine :chartData="datacollection" :options="options"></PercentilesLine>
    </div>
</template>

<script>
import PercentilesLine from './PercentilesLine.js';
import api from "../../config.js";

export default {
    name: 'Percentiles',

    props: ['tipo_grafica','id_paciente', 'semanas', 'peso', 'talla', 'fecha'],
    
    components:{
        PercentilesLine
    },

    data(){
        return{
            talla_percentil: null,
            datacollection: null,
            loaded: false,
            options: null,
            rango_edades: null,
            rango_grafica: null
        }
    },

    created: async function(){
        await this.getDatos();
        this.fillData();
    },

    mounted(){
        //this.fillData();
    },

    methods:{
        async getDatos(){
            var tipo = 'peso';
            switch(this.tipo_grafica){
                case 'peso':
                case 'peso_completo':
                    tipo = 'peso';
                    break;
                case 'Talla':
                case 'talla_completo':
                    tipo = 'altura';
                    break;
            }

            if(this.talla){
                this.talla_percentil = this.talla * 100;
            }
            
            var info = await api.get("api/grafica_percentiles?tipo=" + tipo + "&id_paciente=" + this.id_paciente + "&semanas=" + this.semanas + '&tipo_grafica=' + this.tipo_grafica + '&peso=' + this.peso + '&talla=' + this.talla_percentil + "&fecha=" + this.fecha);

            this.rango_edades = info.data.rango_edades;
            this.rango_grafica = info.data.rango_grafica;
        },

        fillData(){
            this.datacollection = {
                labels: this.rango_edades,
                datasets: this.rango_grafica,// [
                    /*{
                        label: 'Ni�o',
                        borderColor: 'black',
                        pointBackgroundColor: 'black',
                        pointerBorderColor: 'black',
                        data: [null,null,null,null,1],
                        fill: false,
                    },
                    {
                        label: '3rd',
                        borderColor: 'red',
                        pointBackgroundColor: 'red',
                        pointerBorderColor: 'red',
                        data: [1,2,3,4,5,6,7,8,9,10],
                        fill: false,
                    },
                    {
                        label: '15th',
                        borderColor: 'yellow',
                        pointBackgroundColor: 'yellow',
                        pointerBorderColor: 'yellow',
                        data: [2,3,4,5,6,7,8,9,10,11],
                        fill: false,
                    },
                    {
                        label: '50th',
                        borderColor: 'green',
                        pointBackgroundColor: 'green',
                        pointerBorderColor: 'green',
                        data: [3,4,5,6,7,8,9,10,11,12],
                        fill: false,
                    },
                    {
                        label: '85th',
                        borderColor: 'orange',
                        pointBackgroundColor: 'orange',
                        pointerBorderColor: 'orange',
                        data: [4,5,6,7,8,9,10,11,12,13],
                        fill: false,
                    },
                    {
                        label: '97th',
                        borderColor: 'blue',
                        pointBackgroundColor: 'blue',
                        pointerBorderColor: 'blue',
                        data: [5,6,7,8,9,10,11,12,13,14],
                        fill: false,
                    }*/
               // ],
            },
            this.options= {
                responsive: true,
                title: {
                    display: true,
                    text: 'Percentiles'
                },
                hover: {
					mode: 'nearest',
					intersect: true
				},
                scales: {
					xAxes: [{
						display: true,
						scaleLabel: {
							display: true,
							labelString: 'Edad'
						}
					}],
					yAxes: [{
						display: true,
						scaleLabel: {
							display: true,
							labelString: this.tipo_grafica
						}
					}]
				}
            }
        }
    }
}
</script>