<template>
    <div>
        <b-overlay :show="show" rounded="sm" no-wrap fixed style="z-index:1000">
            <template #overlay>
                <div class="text-center">
                <b-icon icon="stopwatch" font-scale="4" animation="spin" shift-v="8"></b-icon>
                <p v-if="mensaje" id="cancel-label">{{mensaje}}</p>
                <p v-else id="cancel-label">Espere un momento...</p>
                </div>
            </template>
        </b-overlay>
    </div>
</template>


<script>

export default {
  name: "Loading",

  props: ['show','mensaje'],
}

</script>