<template>
    <div>
        <b-table :items="listado_enfermedades" :bordered="true" :fields="fields" striped hover outlined responsive>
            <template #cell(opciones)="row">
                <button class="btn btn-outline-success mr-1 btn-sm" @click="addEnfermedad(row.item.nombre, row.item.codigo)">Registrar</button>
            </template>
        </b-table>
    </div>
</template>

<script>
export default {
    props: ['listado_enfermedades'],

    data: () => ({
        fields: [
        {
            key: "codigo",
            label: "Codigo",
            sortable: true
        },
        {
            key: "nombre",
            label: "Descripción",
            sortable: true
        },
        {
            key: "opciones",
            label: "",
            sortable: false
        }
        ],
    }),

    methods: {
        async addEnfermedad(nombre, clave) {
            this.$emit('addEnfermedad', nombre, clave);
        },
    }
}
</script>