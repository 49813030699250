<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="card-body row">
                    <div class="card">
                        <div class="form-group">
                            <label for="nombre" class="col-lg-12 control-label text-primary">Edad de primera Menstruación (Menarquía)</label>
                            <div class="col-lg-6">
                                <input v-uppercase v-model="d_menstruacion.edad_primer_menstruacion" type="text" class="form-control" id="edad_primer_menstruacion"/>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="nombre" class="col-lg-12 control-label text-primary">Características de la menstruación</label>
                            <div class="col-lg-12">
                                <select v-model="d_menstruacion.caracteristicas_menstruacion" class="form-control" id="caracteristicas_menstruacion">
                                    <option>Normal</option>
                                    <option>Dismenorrea</option>
                                    <option>Hipermenorrea</option>
                                    <option>Hipomenorrea</option>
                                    <option>Polimenorrea</option>
                                    <option>Oligomenorrea</option>
                                    <option>Amenorrea</option>
                                    <option>Metrorragia</option>
                                    <option>Opsomenorrea</option>
                                    <option>Nictomenorrea</option>
                                    <option>Braquimenorrea</option>
                                    <option>Criptomenorrea</option>
                                    <option>Menorragia</option>
                                    <option>Proiomenorrea</option>
                                    <option>Disovulia</option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="nombre" class="col-lg-12 control-label text-primary">Edad de inicio de vida sexual activa (IVSA)</label>
                            <div class="col-lg-6">
                                <input v-uppercase v-model="d_menstruacion.inicio_vida_sexual" type="text" class="form-control" id="inicio_vida_sexual"/>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="nombre" class="col-lg-12 control-label text-primary">Edad de menopausia:</label>
                            <div class="col-lg-6">
                                <input v-uppercase v-model="d_menstruacion.menopausia" type="text" class="form-control" id="menopausia"/>
                            </div>
                        </div>
                    </div>

                    <div class="card col-lg-6">
                        <div class="form-group row">
                            <label for="nombre" class="col-lg-12 control-label text-primary">Otros:</label>
                            <div class="col-lg-12">
                                <textarea class="form-control" v-uppercase v-model="d_menstruacion.menstruacion_otros" style="width:100%; font-size:13px"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Menstruaciones',

    props: ['data_menstruacion'],

    data: () => ({
        d_menstruacion:{
            edad_primer_menstruacion: '',
            caracteristicas_menstruacion: 'Normal',
            inicio_vida_sexual: '',
            menopausia: '',
            menstruacion_otros: '',
        },
    }),

    created: function(){
        if(this.data_menstruacion){
            this.d_menstruacion.edad_primer_menstruacion = this.data_menstruacion.edad_primer_menstruacion;
            this.d_menstruacion.caracteristicas_menstruacion = this.data_menstruacion.caracteristicas_menstruacion;
            this.d_menstruacion.inicio_vida_sexual = this.data_menstruacion.inicio_vida_sexual;
            this.d_menstruacion.menopausia = this.data_menstruacion.menopausia;
            this.d_menstruacion.menstruacion_otros = this.data_menstruacion.menstruacion_otros;
        }
    },

    methods: {
        getInfo(){
            return this.d_menstruacion;
        }
    }
}
</script>