<template>
  <div class="container">

     <div v-if="!info_personal && !historial && id_paciente" class="container h-100 mb-3">
        <div class="row justify-content-center h-100">
            <div class="col-sm-8 align-self-center text-center">
                <div class="card shadow">
                    <div class="card-body">           
                      <div class="text-center control-label text-primary"><span>Paciente: <strong> {{ datos_personales.nombre }} {{ datos_personales.apellido_paterno }} {{ datos_personales.apellido_materno }} </strong></span></div>
                      <div class="text-center control-label text-primary"><span>Fecha de nacimiento: <strong> {{ formatoFecha(datos_personales.fecha_nacimiento) }} </strong></span></div>
                      <div class="text-center control-label text-primary"><span>Edad: <strong> {{ calcularEdadCompleta(datos_personales.fecha_nacimiento) }} </strong></span></div>
                      <div v-if="tiene_alergias" class="text-center text-danger"><span>Alergias: Si</span></div>   
                    </div>
                </div>
            </div>
        </div>
    </div> 

    <Loading :mensaje="'Obteniendo información del paciente'" :show="showLoading"></Loading>
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item">
        <button role="tab" class="btn btn-outline-secondary" :class="{ active: info_personal }" @click="cambioPestana('info_personal')">
          INFORMACIÓN PERSONAL
        </button>
      </li>
      <li class="nav-item">
        <button role="tab" class="btn btn-outline-secondary" :class="{ active: enfermedades }" @click="cambioPestana('enfermedades')">
          ENFERMEDADES
        </button>
      </li>
      <li class="nav-item" v-if="id_paciente">
        <button role="tab" class="btn btn-outline-secondary" :class="{ active: antecedentes }" @click="cambioPestana('antecedentes')">
          ANTECEDENTES
        </button>
      </li>
      <li class="nav-item" v-if="id_paciente">
        <button role="tab" class="btn btn-outline-secondary" :class="{ active: historial }" @click="cambioPestana('historial')">
          HISTORIAL MÉDICO
        </button>
      </li>
      <li class="nav-item" v-if="tiene_cita">
        <button role="tab" class="btn btn-outline-secondary" :class="{ active: consulta }" @click="cambioPestana('consulta')">
          {{ this.motivo }}
        </button>
      </li>
    </ul>

    <Antecedentes :edad="edad" :sexo="datos_personales.sexo" v-if="id_paciente" :id_paciente="id_paciente" v-show="antecedentes"></Antecedentes>
    <Consulta v-if="tiene_cita" :id_cita="id_cita" :id_paciente="id_paciente" v-show="consulta"></Consulta>
    <HistorialMedico v-if="id_paciente && historial" :id_paciente="id_paciente"></HistorialMedico>

    <form @submit.prevent="registrar" v-if="info_personal || enfermedades">
      <section v-if="info_personal" id="informacion_personal" class="row">
        <div class="col-md-6 card card-body">
          <div class="form-group row">
            <label for="nombre" class="col-lg-4 control-label text-primary">Nombre:</label>
            <div class="col-lg-8">
              <input v-uppercase required type="text" v-model="datos_personales.nombre" class="form-control" id="nombre"/>
            </div>
          </div>

          <div class="form-group row">
            <label for="apellido_paterno" class="col-lg-4 control-label text-primary">Apellido Paterno:</label>
            <div class="col-lg-8">
              <input v-uppercase required type="text" v-model="datos_personales.apellido_paterno" class="form-control" id="apellido_paterno"/>
            </div>
          </div>

          <div class="form-group row">
            <label for="apellido_materno" class="col-lg-4 control-label text-primary">Apellido Materno:</label>
            <div class="col-lg-8">
              <input v-uppercase required type="text" v-model="datos_personales.apellido_materno" class="form-control" id="apellido_materno"/>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-lg-4 control-label text-primary">Fecha nacimiento:</label>
            <div class="col-lg-8">
              <date-picker required :open="state_fecha" v-model="datos_personales.fecha_nacimiento" id="fecha_consulta" placeholder="Seleccione una fecha" :lang="lang" valueType="format"></date-picker>
              <!-- <b-form-datepicker v-model="datos_personales.fecha_nacimiento" class="form-control" id="fecha_consulta" placeholder="Seleccione una fecha"></b-form-datepicker> -->
            </div>
          </div>

          <div class="form-group row">
            <label class="col-lg-4 control-label text-primary">Sexo:</label>
            <div class="col-lg-8">
              <div class="form-check-inline">
                <label class="form-check-label text-primary">
                  <input v-model="datos_personales.sexo" type="radio" class="form-check-input" value="M" />Masculino
                </label>
              </div>
              <div class="form-check-inline">
                <label class="form-check-label text-primary">
                  <input type="radio" v-model="datos_personales.sexo" class="form-check-input" value="F" />Femenino
                </label>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="tipo_sangre" class="col-lg-4 control-label text-primary">Tipo de sangre:</label>
            <div class="col-lg-8">
              <select v-model="datos_personales.tipo_sangre" class="form-control" id="tipo_sangre">
                <option>NA</option>
                <option>A+</option>
                <option>A-</option>
                <option>B+</option>
                <option>B-</option>
                <option>AB+</option>
                <option>AB-</option>
                <option>O+</option>
                <option>O-</option>
              </select>
            </div>
          </div>

          <div class="form-group row">
            <label for="correo" class="col-lg-4 control-label text-primary">Correo electrónico:</label>
            <div class="col-lg-8">
              <input v-uppercase type="text" v-model="datos_personales.correo" class="form-control" id="correo"/>
            </div>
          </div>

          <div class="form-group row">
            <label for="escolaridad" class="col-lg-4 control-label text-primary">Escolaridad:</label>
            <div class="col-lg-8">
              <input v-uppercase type="text" v-model="datos_personales.escolaridad" class="form-control" id="escolaridad"/>
            </div>
          </div>
        </div>

        <div class="col-md-6 card card-body">
          <div class="form-group row">
            <label for="ocupacion" class="col-lg-4 control-label text-primary">Ocupación:</label>
            <div class="col-lg-8">
              <input v-uppercase type="text" v-model="datos_personales.ocupacion" class="form-control" id="ocupacion"/>
            </div>
          </div>

          <div class="form-group row">
            <label for="empresa" class="col-lg-4 control-label text-primary">Empresa:</label>
            <div class="col-lg-8">
              <input v-uppercase type="text" v-model="datos_personales.empresa" class="form-control" id="empresa"/>
            </div>
          </div>

          <div class="form-group row">
            <label for="telefono_movil" class="col-lg-4 control-label text-primary" >Telefóno móvil:</label>
            <div class="col-lg-8">
              <input v-uppercase type="text" v-model="datos_personales.telefono_movil" class="form-control" id="telefono_movil"/>
            </div>
          </div>

          <div class="form-group row">
            <label for="telefono_fijo" class="col-lg-4 control-label text-primary">Telefóno fijo:</label>
            <div class="col-lg-8">
              <input v-uppercase type="text" v-model="datos_personales.telefono_fijo" class="form-control" id="telefono_fijo"/>
            </div>
          </div>

          <div class="form-group row">
            <label for="codigo_postal" class="col-lg-4 control-label text-primary">CP.:</label>
            <div class="col-lg-8">
              <input type="text" class="form-control" v-model="codigo_postal_object" @change="selectCP">
              <!-- <v-select v-model="codigo_postal_object" :options="codigos" label="numero" @input="selectCP"/> -->
            </div>
          </div>

          <div class="form-group row" v-if="!codigo_postal_object">
            <label for="calle" class="col-lg-4 control-label text-primary">Domicilio:</label>
            <div class="col-lg-8">
              <input v-uppercase type="text" v-model="datos_personales.otro_domicilio" class="form-control" id="calle"/>
            </div>
          </div>

          <div class="form-group row" v-if="pais">
            <label for="pais" class="col-lg-4 control-label text-primary">País:</label>
            <div class="col-lg-8">
              <input v-uppercase type="text" v-model="pais" class="form-control" id="pais" readonly/>
            </div>
          </div>

          <div class="form-group row" v-if="estado">
            <label for="estado" class="col-lg-4 control-label text-primary">Estado:</label>
            <div class="col-lg-8">
              <input v-uppercase type="text" v-model="estado" class="form-control" id="estado" readonly />
            </div>
          </div>

          <div class="form-group row" v-if="municipio">
            <label for="municipio" class="col-lg-4 control-label text-primary">Municipio:</label>
            <div class="col-lg-8">
              <input v-uppercase type="text" v-model="municipio" class="form-control" id="municipio" readonly />
            </div>
          </div>

          <div class="form-group row" v-if="colonias">
            <label for="codigo_postal" class="col-lg-4 control-label text-primary">Colonia:</label>
            <div class="col-lg-8">
              <v-select v-model="colonia_object" :options="colonias" label="colonia" />
            </div>
          </div>

          <div class="form-group row">
            <label for="calle" class="col-lg-4 control-label text-primary">Calle y número:</label>
            <div class="col-lg-8">
              <input v-uppercase type="text" v-model="datos_personales.calle" class="form-control" id="calle" />
            </div>
          </div>

          <!-- <div class="form-group row">
            <label for="id_aseguradora" class="col-lg-4 control-label text-primary">Aseguradora:</label>
            <div class="col-lg-8">
              <input type="text" v-model="datos_personales.id_aseguradora" class="form-control" id="id_aseguradora"/>
            </div>
          </div> -->
        </div>
      </section>

      <section v-if="enfermedades" class="row">
        <div class="col-md-6 card card-body">
          <div class="card pt-3">
            <div class="form-group row text-center">
              <label class="col-lg-6 control-label text-primary">Hipertensión:</label>
              <div class="col-lg-4">
                <toggle-button :sync="true" v-model="datos_personales.hipertencion" :value="datos_personales.hipertencion" color="#43aee6" :labels="{ checked: 'SI', unchecked: 'NO' }" />
              </div>
            </div>

            <div class="form-group row col-sm-12 align-self-center text-center" v-if="datos_personales.hipertencion">
              <div class="col-lg-12">
                <textarea class="form-control" v-uppercase placeholder="Tiempo de diagnóstico y tratamiento" v-model="datos_personales.hipertencion_notas" style="width:100%"></textarea>
              </div>
            </div>
          </div>

          <div class="card pt-3">
            <div class="form-group row text-center">
              <label class="col-lg-6 control-label text-primary">Obesidad:</label>
              <div class="col-lg-4">
                <toggle-button v-model="datos_personales.obesidad" :value="datos_personales.obesidad" color="#43aee6" :labels="{ checked: 'SI', unchecked: 'NO' }" />
              </div>
            </div>

            <div class="form-group row col-sm-12 align-self-center text-center" v-if="datos_personales.obesidad">
              <div class="col-lg-12">
                <textarea class="form-control" v-uppercase placeholder="Tiempo de diagnóstico y tratamiento" v-model="datos_personales.obesidad_notas" style="width:100%"></textarea>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 card card-body">
          <div class="card pt-3">
            <div class="form-group row text-center">
              <label class="col-lg-6 control-label text-primary">Diabetes:</label>
              <div class="col-lg-4">
                <toggle-button v-model="datos_personales.diabetes" :value="datos_personales.diabetes" color="#43aee6" :labels="{ checked: 'SI', unchecked: 'NO' }"/>
              </div>
            </div>

            <div class="form-group row col-sm-12 align-self-center text-center" v-if="datos_personales.diabetes">
              <div class="col-lg-12">
                <textarea class="form-control"  v-uppercase placeholder="Tiempo de diagnóstico y tratamiento" v-model="datos_personales.diabetes_notas" style="width:100%"></textarea>
              </div>
            </div>
          </div>

          <div class="card pt-3">
            <div class="form-group row text-center">
              <label class="col-lg-6 control-label text-primary">Artritis:</label>
              <div class="col-lg-4">
                <toggle-button v-model="datos_personales.artritis" :value="datos_personales.artritis" color="#43aee6" :labels="{ checked: 'SI', unchecked: 'NO' }"/>
              </div>
            </div>

            <div class="form-group row col-sm-12 align-self-center text-center" v-if="datos_personales.artritis">
              <div class="col-lg-12">
                <textarea class="form-control"  v-uppercase placeholder="Tiempo de diagnóstico y tratamiento" v-model="datos_personales.artritis_notas" style="width:100%"></textarea>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="container-fluid mt-3">
        <div class="align-items-center">
          <div v-if="!cargando" class="text-center">
            <button v-if="id_paciente" class="btn btn-primary  btn-lg btn-block">
              Actualizar información
            </button>
            <button v-else class="btn btn-primary  btn-lg btn-block">
              Registrar
            </button>
          </div>

          <div v-else class="text-center">
            <button class="btn btn-primary btn-lg btn-block" type="button" disabled>
              <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
              Cargando...
            </button>
          </div>
        </div>
      </div>

    </form>
  </div>
</template>

<script>
import api from "../config.js";
import Loading from "../components/Loading.vue";
import Antecedentes from "../components/Antecedentes.vue";
import Consulta from "../components/Consulta.vue";
import HistorialMedico from "../components/HistorialMedico.vue";

export default {
  name: "NuevoPaciente",

  props: ["id_paciente",
          "id_cita",
          "desde_cita"],

  components: {
    Loading,
    Antecedentes,
    Consulta,
    HistorialMedico
  },

  beforecreated: function() {
    this.showLoading = false;
  },

  created: async function() {
    if (this.id_paciente) {
      await this.getInfoPaciente();
      //this.selectCP();
    }
    this.showLoading = false;
    this.getCodigos();

    if(this.datos_personales.fecha_nacimiento){
      this.edad = this.calcularEdad(this.datos_personales.fecha_nacimiento);
      this.fecha_nacimiento = this.formatoFecha(this.datos_personales.fecha_nacimiento);
    }
  },

  data: () => ({
    lang:{
      default : 'es'
    },
    state_fecha: null,
    tiene_alergias: null,
    fecha_nacimiento: null,
    edad: null,
    showLoading: true,
    datos_personales: {
      nombre: "",
      apellido_materno: "",
      apellido_paterno: "",
      sexo: "M",
      tipo_sangre: "NA",
      telefono_movil: "",
      telefono_fijo: "",
      correo: "",
      codigo_postal: "",
      id_pais: "",
      id_estado: "",
      id_municipio: "",
      colonia: "",
      calle: "",
      empresa: "",
      id_aseguradora: "",
      otro_domicilio: null,
      hipertencion: false,
      obesidad: false,
      diabetes: false,
      artritis: false,
      hipertencion_notas: '',
      obesidad_notas: '',
      diabetes_notas: '',
      artritis_notas: '',
      fecha_nacimiento: null,
      ocupacion: '',
      escolaridad: ''
    },
    info_personal: true,
    enfermedades: false,
    antecedentes: false,
    consulta: false,
    historial: false,
    codigo_postal_object: "",
    colonia_object: "",
    pais: "",
    estado: "",
    municipio: "",
    codigos: [],
    colonias: null,
    cargando: false,
    tiene_cita: false,
    motivo: null,
    mes_nombre: [
        {nombre: 'Enero'},
        {nombre: 'Febrero'},
        {nombre: 'Marzo'},
        {nombre: 'Abril'},
        {nombre: 'Mayo'},
        {nombre: 'Junio'},
        {nombre: 'Julio'},
        {nombre: 'Agosto'},
        {nombre: 'Septiembre'},
        {nombre: 'Octubre'},
        {nombre: 'Noviembre'},
        {nombre: 'Diciembre'},
      ],
  }),

  methods: {
    cambioPestana(pestana){
      switch(pestana){
        case 'info_personal':
          this.info_personal = true;
          this.enfermedades = false;
          this.antecedentes = false;
          this.consulta = false;
          this.historial = false
          break;
        case 'enfermedades':
          this.info_personal = false;
          this.enfermedades = true;
          this.antecedentes = false;
          this.consulta = false;
          this.historial = false
          break;
        case 'antecedentes':
          this.info_personal = false;
          this.enfermedades = false;
          this.antecedentes = true;
          this.historial = false
          this.consulta = false;
          break;
        case 'consulta':
          this.info_personal = false;
          this.enfermedades = false;
          this.antecedentes = false;
          this.historial = false
          this.consulta = true;
          break;
        case 'historial':
          this.historial = true
          this.info_personal = false;
          this.enfermedades = false;
          this.antecedentes = false;
          this.consulta = false;
          break;
      }
    },
    async getInfoPaciente() {
      var cookie = await api.get("/sanctum/csrf-cookie");
      if (cookie.status == 204) {
        var paci = await api.post("api/paciente/" + this.id_paciente, {'id_cita':this.id_cita});
        
        if(paci.data.tiene_cita == 'Si'){
          this.tiene_cita = true;
          this.motivo = paci.data.motivo.motivo;
        }

        if(paci.data.tiene_alergias == 'Si' || paci.data.paciente.alergias_otras){
          this.tiene_alergias = true;
        }

        this.datos_personales = paci.data.paciente;

        this.datos_personales.hipertencion = (paci.data.paciente.hipertencion == "1") ? true : false;
        this.datos_personales.obesidad = paci.data.paciente.obesidad == "1" ? true : false;
        this.datos_personales.diabetes = paci.data.paciente.diabetes == "1" ? true : false;
        this.datos_personales.artritis = paci.data.paciente.artritis == "1" ? true : false;

        if (!paci.data.otro_domicilio) {
          this.pais = paci.data.paciente.pais;
          this.estado = paci.data.paciente.estado;
          this.municipio = paci.data.paciente.municipio;
          this.codigo_postal_object = this.datos_personales.codigo_postal;
          this.colonia_object = this.datos_personales.colonia;
        }
      }
    },

    async getCodigos() {
      /*var cookie = await api.get("/sanctum/csrf-cookie");
      if (cookie.status == 204) {*/
        var codi = await api.get("api/codigo_postale");
        this.codigos = codi.data;
      /*}*/
    },

    async selectCP() {
      /*var cookie = await api.get("/sanctum/csrf-cookie");*/
      if (this.codigo_postal_object) {
        if (typeof this.codigo_postal_object === "object") {
          this.datos_personales.codigo_postal = this.codigo_postal_object.numero;
        } else {
          this.datos_personales.codigo_postal = this.codigo_postal_object;
        }

        /*if (cookie.status == 204) {*/
          var coloni = await api.get(
            "api/colonias/" + this.datos_personales.codigo_postal
          );

          if(coloni.data.length > 0){
            this.colonias = coloni.data;
            this.municipio = coloni.data[0].municipio;
            this.estado = coloni.data[0].estado;
            this.pais = coloni.data[0].pais;
            this.datos_personales.id_municipio = coloni.data[0].id_municipio;
            this.datos_personales.id_estado = coloni.data[0].id_estado;
            this.datos_personales.id_pais = coloni.data[0].id_pais;
            this.datos_personales.otro_domicilio = null;
          }
        /*}*/
      } else {
        this.colonias = null;
        this.municipio = "";
        this.estado = "";
        this.pais = "";
        this.datos_personales.id_municipio = null;
        this.datos_personales.id_estado = null;
        this.datos_personales.id_pais = null;
        this.datos_personales.colonia = null;
      }
    },

    calcularEdad(fecha) {
      var edad = '--';
      if(fecha){
        var hoy = new Date();
        var cumpleanos = new Date(fecha);
        edad = hoy.getFullYear() - cumpleanos.getFullYear();
        var m = hoy.getMonth() - cumpleanos.getMonth();

        if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
            edad--;
        }
      }

      return edad;
    },

    calcularEdadCompleta(fecha) {
        // Si la fecha es correcta, calculamos la edad

        //if (typeof fecha != "string" && fecha && esNumero(fecha.getTime())) {
            //fecha = formatDate(fecha, "yyyy-MM-dd");
        //}

        var values = fecha.split("-");
        var dia = values[2];
        var mes = values[1];
        var ano = values[0];

        // cogemos los valores actuales
        var fecha_hoy = new Date();
        var ahora_ano = fecha_hoy.getYear();
        var ahora_mes = fecha_hoy.getMonth() + 1;
        var ahora_dia = fecha_hoy.getDate();

        // realizamos el calculo
        var edad = (ahora_ano + 1900) - ano;
        if (ahora_mes < mes) {
            edad--;
        }
        if ((mes == ahora_mes) && (ahora_dia < dia)) {
            edad--;
        }
        if (edad > 1900) {
            edad -= 1900;
        }

        // calculamos los meses
        var meses = 0;

        if (ahora_mes > mes && dia > ahora_dia)
            meses = ahora_mes - mes - 1;
        else if (ahora_mes > mes)
            meses = ahora_mes - mes
        if (ahora_mes < mes && dia < ahora_dia)
            meses = 12 - (mes - ahora_mes);
        else if (ahora_mes < mes)
            meses = 12 - (mes - ahora_mes + 1);
        if (ahora_mes == mes && dia > ahora_dia)
            meses = 11;

        // calculamos los dias
        /* var dias = 0;
        if (ahora_dia > dia)
            dias = ahora_dia - dia;
        if (ahora_dia < dia) {
            var ultimoDiaMes = new Date(ahora_ano, ahora_mes - 1, 0);
            dias = ultimoDiaMes.getDate() - (dia - ahora_dia);
        } */

        return edad + " años, " + meses + " meses";
    },

    formatoFecha(fecha){
      var formato_fecha = '--';
      if(fecha){
        var formato = new Date(fecha.replace(/-/g, '/'));
        formato_fecha = (formato.getDate()) + ' de ' + this.mes_nombre[formato.getMonth()].nombre + ' de ' + formato.getFullYear();
      }

      return formato_fecha;
    },

    registrar: async function() {
      this.cargando = true;
      if(!this.datos_personales.fecha_nacimiento){
        this.state_fecha = true;
        this.cargando = false;
        return;
      }

      this.state_fecha = false;

      var cookie = await api.get("/sanctum/csrf-cookie");
      if (this.colonia_object) {
        this.datos_personales.colonia = this.colonia_object.colonia;
      }

      if (cookie.status == 204) {
        var ruta = "nuevo_paciente";
        if (this.id_paciente) {
          ruta = "editar_paciente/" + this.id_paciente;
        }

        this.datos_personales.nombre = this.datos_personales.nombre.toUpperCase(); 
        this.datos_personales.apellido_paterno = this.datos_personales.apellido_paterno.toUpperCase();
        this.datos_personales.apellido_materno = this.datos_personales.apellido_materno.toUpperCase();

        api.post("api/" + ruta, this.datos_personales)
          .then(respuesta => {
            if (respuesta.data.tipo == "Exito") {
              if (!this.id_paciente) {
                  this.$emit('cerrarModalNuevoPaciente');

                  if(this.desde_cita){
                    this.$emit('getListadoPacientes', respuesta.data.id_paciente, respuesta.data.nombre, respuesta.data.apellido_paterno, respuesta.data.apellido_materno);
                  }else{
                    var recargar = false;
                    if((this.$router.history.current.fullPath).includes('editar_paciente')){
                      recargar = true;
                    }

                    this.$router.replace("/editar_paciente/" + respuesta.data.id_paciente + "/true");

                    if(recargar){
                      this.$router.go(0);
                    }
                  }
              } else {
                this.edad = this.calcularEdad(this.datos_personales.fecha_nacimiento);
                this.fecha_nacimiento = this.formatoFecha(this.datos_personales.fecha_nacimiento);

                this.$toast.success("Datos actualizados correctamente", {
                  dismissible: true,
                  position: "top"
                });
              }
            } else {
              this.$toast.error("Ocurrio un error vuelve a intentarlo", {
                dismissible: true,
                position: "top"
              });
            }
            this.cargando = false;
          })
          .catch(() => {
            this.cargando = false;
          });
      }
    }
  }
};
</script>
    
<style>
</style>