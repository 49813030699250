<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="card-body row">
                    <div class="col-lg-12 pt-3">
                        <div class="form-group row col">
                            <label class="col-lg-12 control-label text-primary">Enfermedades del CIE-11</label>
                            <div class="col-lg-8">
                                <input style="font-size:13px" @keyup.enter="getListadoEnfermedades" v-model="enfermedad_buscar" type="text" class="form-control"/>
                            </div>

                            <div class="col-lg-4">
                                <button type="button" @click="getListadoEnfermedades" class="btn btn-success btn-sm btn-block">Buscar</button>
                            </div>
                        </div>

                        <b-table :items="data_enfermedades" :bordered="true" :fields="fields" striped hover outlined responsive>
                            <template #cell(opciones)="row">
                                <button class="btn btn-outline-danger mr-1 btn-sm" @click="eliminarEnfermedad(row.item.id_enfermedad_cie_to_paciente, row.item.codigo)">Eliminar</button>
                            </template>
                        </b-table>
                    </div>
                </div>
            </div>
        </div>

         <b-overlay no-wrap fixed :show="confirm_eliminar" rounded="sm">
            <template #overlay>
                <div>
                <p><strong id="form-confirm-label">¿Estas seguro de eliminar el registro con codigo: {{ clave_eliminar }} ?</strong></p>
                <div>
                    <b-button variant="outline-danger" class="mr-3" @click="btnCancelar">
                    Cancelar
                    </b-button>
                    <b-button variant="outline-success" @click="btnAceptar">OK</b-button>
                </div>
                </div>
            </template>
        </b-overlay>
    </div>
</template>

<script>

export default {
    name: 'EnfermedadCIE',

    props: ['data_enfermedades'],

    data: () => ({
        showLoading: false,
        clave_eliminar: '',
        id_eliminar: null,
        enfermedad_buscar: null,
        confirm_eliminar: false,

        fields: [
        {
            key: "codigo",
            label: "Codigo",
            sortable: true
        },
        {
            key: "descripcion",
            label: "Descripción",
            sortable: true
        },
        {
            key: "opciones",
            label: "",
            sortable: false
        }
        ],
    }),

  methods: {
    async getListadoEnfermedades() {
        this.$emit('getListadoEnfermedades', this.enfermedad_buscar);
    },
    async eliminarEnfermedad(id, codigo){
        this.id_eliminar = id;
        this.clave_eliminar = codigo;
        this.confirm_eliminar = true;
    },

    btnCancelar(){
        this.id_eliminar = null;
        this.clave_eliminar = '';
        this.confirm_eliminar = false;
    },

    btnAceptar(){
        if(this.id_eliminar){
            this.$emit('eliminarEnfermedad', this.id_eliminar);
        }else{
            this.$toast.error("Ocurrio un error vuelve a intentarlo", {
            dismissible: true,
            position: "top"
            });
        }
        this.confirm_eliminar = false;
    }
  }

}
</script>