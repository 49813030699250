<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="row card-body">
                    <div class=" col-lg-12">
                        <div class="form-group row">
                            <label for="fecha_ultimo_papanicolau" class="col-lg-5 control-label text-primary">Fecha del último papanicolaou:</label>
                            <div class="col-lg-6">
                                <date-picker v-model="otros_ginecologicos.fecha_ultimo_papanicolau" class="mb-2" placeholder="Seleccione una fecha" valueType="format"></date-picker>
                                <!-- <b-form-datepicker v-model="otros_ginecologicos.fecha_ultimo_papanicolau" class="mb-2" show-decade-nav placeholder="Seleccione una fecha"></b-form-datepicker> -->
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="fecha_ultima_colposcopia" class="col-lg-5 control-label text-primary">Fecha de la última colposcopia:</label>
                            <div class="col-lg-6">
                                <date-picker v-model="otros_ginecologicos.fecha_ultima_colposcopia" class="mb-2" placeholder="Seleccione una fecha" valueType="format"></date-picker>
                                <!-- <b-form-datepicker v-model="otros_ginecologicos.fecha_ultima_colposcopia" class="mb-2" show-decade-nav placeholder="Seleccione una fecha"></b-form-datepicker> -->
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="fecha_ultima_mamografia" class="col-lg-5 control-label text-primary">Fecha de la última mamografía:</label>
                            <div class="col-lg-6">
                                <date-picker v-model="otros_ginecologicos.fecha_ultima_mamografia" class="mb-2" placeholder="Seleccione una fecha" valueType="format"></date-picker>
                                <!-- <b-form-datepicker v-model="otros_ginecologicos.fecha_ultima_mamografia" class="mb-2" show-decade-nav placeholder="Seleccione una fecha"></b-form-datepicker> -->
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="no_parejas_sexuales" class="col-lg-5 control-label text-primary">No. de parejas sexuales:</label>
                            <div class="col-lg-2">
                                <input :maxlength="4" @keydown="filterKey" v-model="otros_ginecologicos.no_parejas_sexuales" type="text" class="form-control" id="no_parejas_sexuales"/>
                            </div>
                        </div>

                        <div class="row col-lg-12">
                            <div class="row card-body col-lg-6">
                                <div class="container-fluid">
                                    <label for="metodos_anticonceptivos" class="col control-label text-primary text-center">Métodos anticonceptivos:</label>
                                    <div class="col-lg-12">
                                        <textarea class="form-control"  v-uppercase v-model="otros_ginecologicos.metodos_anticonceptivos" style="width:100%; font-size:13px"></textarea>
                                    </div>
                                </div>

                                <div class="container-fluid">
                                    <label for="presencia_flujos_vaginales" class="col control-label text-primary text-center">Presencia de otros flujos vaginales:</label>
                                    <div class="col-lg-12">
                                        <textarea class="form-control" v-uppercase v-model="otros_ginecologicos.presencia_flujos_vaginales" style="width:100%; font-size:13px"></textarea>
                                    </div>
                                </div>
                                
                            </div>


                            <div class="row card-body col-lg-6">
                                <div class="container-fluid">
                                    <label for="procedimientos_ginecologicos" class="col control-label text-primary text-center">Procedimientos ginecológicos:</label>
                                    <div class="col-lg-12">
                                        <textarea class="form-control"  v-uppercase v-model="otros_ginecologicos.procedimientos_ginecologicos" style="width:100%; font-size:13px"></textarea>
                                    </div>
                                </div>

                                <div class="container-fluid">
                                    <label for="habitos" class="col control-label text-primary text-center">Hábito:</label>
                                    <div class="col-lg-12">
                                        <textarea class="form-control"  v-uppercase v-model="otros_ginecologicos.habitos" style="width:100%; font-size:13px"></textarea>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="container-fluid">
                            <label for="nombre" class="col control-label text-primary text-center">Otros:</label>
                            <div class="col-lg-12">
                                <textarea class="form-control" v-uppercase v-model="otros_ginecologicos.ginecologicos_otros" style="width:100%; font-size:13px"></textarea>
                            </div>
                        </div>               
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OtrosGinecologicos',

    props: ['data_otros_ginecologicos'],

    data: () => ({
        otros_ginecologicos:{
            fecha_ultimo_papanicolau: '',
            fecha_ultima_colposcopia: '',
            fecha_ultima_mamografia: '',
            no_parejas_sexuales: '',
            metodos_anticonceptivos: '',
            procedimientos_ginecologicos: '',
            presencia_flujos_vaginales: '',
            habitos: '',
            ginecologicos_otros: ''
        },
    }),

    created: function(){
        if(this.data_otros_ginecologicos){
            //this.otros_ginecologicos = this.data_otros_ginecologicos;

            this.otros_ginecologicos.fecha_ultimo_papanicolau = this.data_otros_ginecologicos.fecha_ultimo_papanicolau;
            this.otros_ginecologicos.fecha_ultima_colposcopia = this.data_otros_ginecologicos.fecha_ultima_colposcopia;
            this.otros_ginecologicos.fecha_ultima_mamografia = this.data_otros_ginecologicos.fecha_ultima_mamografia;
            this.otros_ginecologicos.no_parejas_sexuales = this.data_otros_ginecologicos.no_parejas_sexuales;
            this.otros_ginecologicos.metodos_anticonceptivos = this.data_otros_ginecologicos.metodos_anticonceptivos;
            this.otros_ginecologicos.procedimientos_ginecologicos = this.data_otros_ginecologicos.procedimientos_ginecologicos;
            this.otros_ginecologicos.presencia_flujos_vaginales = this.data_otros_ginecologicos.presencia_flujos_vaginales;
            this.otros_ginecologicos.habitos = this.data_otros_ginecologicos.habitos;
            this.otros_ginecologicos.ginecologicos_otros = this.data_otros_ginecologicos.ginecologicos_otros;
        }
    },

    methods: {
        getInfo(){
            return this.otros_ginecologicos;
        },
        filterKey(e){
            if((e.key >= 0 && e.key <= 9) || e.key == 'Backspace') {
                //
            }else{
                e.preventDefault();
            }
        }
    }
}
</script>