<template>
    <div class="container">
        <section class="row">
            <div class="card col-md-2">
                <div class="card-body">
                    <div class="form-group row justify-content-center"  v-for="opcion in opciones" :key="opcion.clave">
                        <div v-if="campos_cargando[opcion.clave] == true" class="spinner-grow text-primary"></div>
                        <label v-else class="col-lg-12 btn btn-info btn-sm" :class="{ active: boton_activo == opcion.clave }" @click="cambioBoton(opcion.clave)"> {{ opcion.label }} </label>
                    </div>
                </div>
            </div>

            <div class="card col">
                <Padecimiento :key="boton_activo + is_informativo" :tipo="boton_activo" :motivo="motivo" :campo_informativo="is_informativo" :padecimiento="padecimiento" v-if="boton_activo == 'padecimiento' || boton_activo == 'subjetivo' || boton_activo == 'objetivo' || boton_activo == 'analisis' || boton_activo == 'plan'" ref="ref_padecimiento"></Padecimiento>
                <Exploracion :motivo="motivo" :id_paciente="id_paciente" :campo_informativo="is_informativo" :exploracion="exploracion" v-if="boton_activo == 'exploracion'" ref="ref_exploracion"></Exploracion>
                <ExploracionFisica :motivo="motivo" :campo_informativo="is_informativo" :exploracion_fisica="exploracion_fisica" v-if="boton_activo == 'exploracion_fisica'" ref="ref_exploracion_fisica"></ExploracionFisica>
                <DiagnosticoTratamiento :motivo="motivo" :campo_informativo="is_informativo" :id_consulta="id_consulta" :diagnostico="diagnostico" v-if="boton_activo == 'diagnostico_tratamiento'" ref="ref_diagnostico_tratamiento"></DiagnosticoTratamiento>
                <SolicitudEstudios :id_paciente="id_paciente" :motivo="motivo" :campo_informativo="is_informativo" :id_consulta="id_consulta" :estudios="estudios" v-if="boton_activo == 'solicitud_estudios'" ref="ref_solicitud_estudios"></SolicitudEstudios>
                <Interconsulta :motivo="motivo" :id_paciente="id_paciente" :campo_informativo="is_informativo" :interconsulta="interconsulta" v-if="boton_activo == 'interconsulta'" ref="ref_interconsulta"></Interconsulta>
                <CertificadoMedico v-if="boton_activo == 'cetificado_medico'"></CertificadoMedico>

                <div class="form-group">
                    <label for="edad_mama" class="col-lg-3 control-label text-primary">PRECIO CONSULTA:</label>
                    <div class="col-lg-2">
                        <input :readonly="is_informativo || !editar_precio_consulta" v-model="costo_consulta" type="text" class="form-control" id="nombre"/>
                    </div>
                </div>
                
                <div v-if="estatus != 'cancelada'" class="container-fluid mt-3 pb-4">
                    <div class="align-items-center row">
                        <div v-if="boton_activo != 'solicitud_estudios' && !is_informativo && opciones.length > 1" class="text-center col-lg-4">
                            <button class="btn btn-primary  btn-lg btn-block" @click="Continuar(false, true)">CONTINUAR</button>
                        </div>

                        <div v-if="!is_informativo && motivo" class="text-center col-lg-4">
                            <button v-if="estatus == 'activo' || !estatus" class="btn btn-success  btn-lg btn-block" @click="finalizarConsulta">Terminar {{ motivo.motivo.toLowerCase() }}</button>
                            <button v-else class="btn btn-success  btn-lg btn-block" @click="finalizarConsulta">TERMINAR EDICIÓN</button>
                        </div>

                        <div v-if="!is_informativo && (estatus == 'activo' || !estatus)" class="text-center col-lg-4">
                            <button class="btn btn-warning btn-lg btn-block" @click="showSinoCancelar">CANCELAR CITA</button>
                        </div>

                        <div v-if="is_informativo && motivo && ver_boton_editar" class="text-center col-lg-4">
                            <button class="btn btn-primary  btn-lg btn-block" @click="edicion()">EDITAR</button>
                        </div>

                        <div v-if="is_informativo && (imprimir_receta || imprimir_estudios || imprimir_nota_interconsulta || imprimir_nota_traslado)" class="text-center col-lg-4">
                            <button class="btn btn-success  btn-lg btn-block" @click="ver_receta = true">IMPRESIONES</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Loading :show="showLoading"></Loading>
         
         <b-modal v-model="ver_receta" size="xl" id="ver_receta_medica" title="Receta Medica">
            <RecetaMedica :ver_nota_traslado="imprimir_nota_traslado" :ver_nota_interconsulta="imprimir_nota_interconsulta" :ver_receta="imprimir_receta" :ver_estudios="imprimir_estudios" :id_cita="id_cita" ref="ref_receta"></RecetaMedica>
            <template v-slot:modal-footer>
                <div class="container-fluid"> 
                    <div class="align-items-center">
                        <div class="text-center" style="margin-left:10px; margin-right:10px">
                            <b-button @click="$refs.ref_receta.imprimir()" class="btn btn-info  btn-lg btn-block"> Imprimir </b-button>
                        </div>	
                    </div>
                </div>
            </template>
        </b-modal>

        <b-overlay no-wrap fixed :show="show_cancelar_cita" rounded="sm">
            <template #overlay>
                <div>
                <p><strong id="form-confirm-label">¿Estas seguro de cancelar la cita?</strong></p>
                <div v-if="!cancelando">
                    <b-button variant="outline-danger" class="mr-3" @click="btnCancelar">
                    No
                    </b-button>
                    <b-button variant="outline-success" @click="btnAceptarCancelar">Si</b-button>
                </div>
                </div>
            </template>
        </b-overlay>
    </div>
</template>

<script>
const $ = require("jquery");

import api from "../config.js";

import Loading from "../components/Loading.vue";
import Padecimiento from "../components/consulta/Padecimiento.vue";
import Exploracion from "../components/consulta/Exploracion.vue";
import DiagnosticoTratamiento from "../components/consulta/DiagnosticoTratamiento.vue";
import ExploracionFisica from "../components/consulta/ExploracionFisica.vue";
import SolicitudEstudios from "../components/consulta/SolicitudEstudios.vue";
import RecetaMedica from "../components/consulta/RecetaMedica.vue";
import Interconsulta from "../components/consulta/Interconsulta.vue";
import CertificadoMedico from "../components/consulta/CertificadoMedico.vue";

export default {
    name: 'Consulta',

    props: ['id_cita', 'id_paciente', 'desde_historial'],

    components:{
        Loading,
        Padecimiento,
        Exploracion,
        DiagnosticoTratamiento,
        ExploracionFisica,
        SolicitudEstudios,
        RecetaMedica,
        Interconsulta,
        CertificadoMedico
    },

    data: () => ({
        editar_precio_consulta: false,
        imprimir_receta: true,
        imprimir_estudios: true,
        imprimir_nota_interconsulta: false,
        imprimir_nota_traslado: false,
        cancelando: false,
        show_cancelar_cita: false,
        ver_receta: false,
        es_edicion: false,
        estatus: 'activo',
        is_informativo: false,
        id_consulta: null,
        listado_enfermedades: null,
        show_listado_enfermedad: false,
        showLoading: false,
        campos_cargando:[],
        arreglo_campos: [],
        registros: {},
        padecimiento: [],
        exploracion: [],
        exploracion_fisica: [],
        diagnostico: [],
        interconsulta: [],
        estudios: [],
        boton_activo: '',
        motivo: null,
        opciones:[],
        costo_consulta: null,
        ver_boton_editar: true,
    }),

    created: async function(){
        if(this.desde_historial){
            this.ver_boton_editar = false;
        }

        var permisos = await this.$store.dispatch("getPermisos");

        if(permisos){
            this.editar_precio_consulta = permisos.includes('PRECO');
        }

        this.showLoading = true;
        await this.getInfoByCita();
        this.showLoading= false;
    },

    methods:{
        btnCancelar(){
            this.show_cancelar_cita = false;
        },
        showSinoCancelar(){
            this.show_cancelar_cita = true;
        },

        async btnAceptarCancelar(){
            this.cancelando = true;
            var info = await api.post("api/cancelar_cita/" + this.id_cita);

            if(info.data.tipo == 'Exito'){
                this.$toast.success("Cita cancelada correctamente", {
                    dismissible: true,
                    position: "top"
                });

                this.estatus = 'cancelada';

                this.es_edicion = false;
                this.is_informativo = true;

                this.btnCancelar();
            }else{
                this.$toast.error("Ocurrio un error vuelve a intentarlo", {
                    dismissible: true,
                    position: "top"
                });
            }

            this.cancelando = false;
        },
        edicion(){
            this.es_edicion = true;
            this.is_informativo = false;
        },
        cambioBoton(key){
            if(this.boton_activo == key){
                //FIXME ver porque funciona solamente con el splice
                this.campos_cargando.splice( -1, 1 );
            }
            this.Continuar(false,false);
            
            this.boton_activo = key;
        },
        Continuar(finalizar = false, next = true){
            this.irArriba();
            if(!this.is_informativo){
                this.campos_cargando[this.boton_activo] = true;

                switch(this.boton_activo){
                    case 'interconsulta':
                        this.registros = this.$refs.ref_interconsulta.getInfo();
                        break;

                    case 'padecimiento':
                        this.registros = this.$refs.ref_padecimiento.getInfo();
                        break;

                    case 'exploracion':
                        this.registros = this.$refs.ref_exploracion.getInfo();
                        break;

                    case 'exploracion_fisica':
                        this.registros = this.$refs.ref_exploracion_fisica.getInfo();
                        break;

                    case 'diagnostico_tratamiento':
                        this.registros = this.$refs.ref_diagnostico_tratamiento.getInfo();
                        break;

                    case 'solicitud_estudios':
                        this.registros = this.$refs.ref_solicitud_estudios.getInfo();
                        break;
                    
                    case 'subjetivo':
                        this.registros = this.$refs.ref_padecimiento.getInfo();
                        break;

                    case 'objetivo':
                        this.registros = this.$refs.ref_padecimiento.getInfo();
                        break;

                    case 'analisis':
                        this.registros = this.$refs.ref_padecimiento.getInfo();
                        break;

                    case 'plan':
                        this.registros = this.$refs.ref_padecimiento.getInfo();
                        break;
                }

                if(finalizar){
                    this.registros.estatus = 'terminada';
                }

                this.actualizarRegistro(this.boton_activo);
            }
            if(next){
                this.next();
            }
        },
        next(){
            var next = (this.arreglo_campos.indexOf(this.boton_activo)) + 1;
            
            if(next < this.arreglo_campos.length){
                this.boton_activo = this.opciones[next].clave;
            }
        },
        finalizarConsulta(){
            //FIXME ver porque funciona solamente con el splice
            this.campos_cargando.splice( -1, 1 );
            this.Continuar(true, false);

            this.es_edicion = false;
        },
        actualizarRegistro(btn_aux){
            this.registros.costo = this.costo_consulta;

            api.post("api/update_consulta/" + this.id_cita, this.registros).then(respuesta => {
                if(respuesta.data.tipo == 'Exito'){
                    this.data = respuesta.data;
                    
                    this.separarData(respuesta.data.consulta);
                    
                    this.$toast.success("Datos actualizados correctamente", {
                        dismissible: true,
                        position: "top"
                    });
                }else{
                    this.$toast.error("Ocurrio un error vuelve a intentarlo", {
                        dismissible: true,
                        position: "top"
                    });
                }

                //FIXME ver porque funciona solamente con el splice
                this.campos_cargando[btn_aux] = false;
                this.campos_cargando.splice( -1, 1 );
            });

        },
        async getInfoByCita(){
            this.registros.id_paciente = this.id_paciente;
            this.registros.id_cita = this.id_cita;

            await api.post("api/get_info_by_cita/" + this.id_cita, this.registros).then(respuesta => {
                if(respuesta.data.tipo == 'Exito'){
                    this.separarData(respuesta.data.consulta);
                    this.motivo = respuesta.data.motivo;
                }

                switch(this.motivo.clave ){
                    case 'NOTEV':
                        //this.opciones.push({clave:'padecimiento', label:'Padecimiento'});
                        this.opciones.push({clave:'subjetivo', label:'SUBJETIVO'});
                        this.opciones.push({clave:'exploracion', label:'SIGNOS VITALES'});
                        this.opciones.push({clave:'objetivo', label:'OBJETIVO'});
                        this.opciones.push({clave:'analisis', label:'ANÁLISIS'});
                        this.opciones.push({clave:'plan', label:'PLAN'});
                        this.opciones.push({clave:'solicitud_estudios', label:'SOLICITUD DE ESTUDIOS'});

                        //this.arreglo_campos.push('padecimiento');
                        this.arreglo_campos.push('subjetivo');
                        this.arreglo_campos.push('exploracion');
                        this.arreglo_campos.push('objetivo');
                        this.arreglo_campos.push('analisis');
                        this.arreglo_campos.push('plan');
                        this.arreglo_campos.push('solicitud_estudios');

                        this.imprimir_receta = false;
                        this.imprimir_estudios = true;
                        this.imprimir_nota_interconsulta = false;

                        this.boton_activo = 'subjetivo';
                        break;
                    
                    case 'CONSU':
                        this.opciones.push({clave:'padecimiento', label:'PADECIMIENTO'});
                        this.opciones.push({clave:'exploracion', label:'EXPLORACIÓN'});
                        this.opciones.push({clave:'exploracion_fisica', label:'EXPLORCIÓN FISICA'});
                        this.opciones.push({clave:'diagnostico_tratamiento', label:'DIAGNÓSTICO Y TRATAMIENTO'});
                        this.opciones.push({clave:'solicitud_estudios', label:'SOLICITUD DE ESTUDIOS'});

                        this.arreglo_campos.push('padecimiento');
                        this.arreglo_campos.push('exploracion');
                        this.arreglo_campos.push('exploracion_fisica');
                        this.arreglo_campos.push('diagnostico_tratamiento');
                        this.arreglo_campos.push('solicitud_estudios');

                        this.imprimir_receta = true;
                        this.imprimir_estudios = true;
                        this.imprimir_nota_interconsulta = false;

                        this.boton_activo = 'padecimiento';
                        break;
                    
                    case 'ESTD':
                        this.opciones.push({clave:'solicitud_estudios', label:'SOLICITUD DE ESTUDIOS'});
                        this.opciones.push({clave:'diagnostico_tratamiento', label:'DIAGNÓSTICO Y TRATAMIENTO'});

                        this.arreglo_campos.push('solicitud_estudios');
                        this.arreglo_campos.push('diagnostico_tratamiento');

                        this.imprimir_receta = true;
                        this.imprimir_estudios = true;
                        this.imprimir_nota_interconsulta = false;

                        this.boton_activo = 'solicitud_estudios';
                        break;
                    
                    case 'NOTIN':
                        this.opciones.push({clave:'interconsulta', label:'INTERCONSULTA'});
                        this.opciones.push({clave:'exploracion', label:'SIGNOS VITALES'});
                        this.opciones.push({clave:'padecimiento', label:'EVOLUCION DEL PADECIMIENTO ACTUAL'});
                        
                        this.opciones.push({clave:'exploracion_fisica', label:'EXPLORCIÓN FISICA'});
                        this.opciones.push({clave:'solicitud_estudios', label:'SOLICITUD DE ESTUDIOS'});
                        this.opciones.push({clave:'diagnostico_tratamiento', label:'DIAGNÓSTICO Y TRATAMIENTO'});

                        this.arreglo_campos.push('interconsulta');
                        this.arreglo_campos.push('exploracion');
                        this.arreglo_campos.push('padecimiento');
                        
                        this.arreglo_campos.push('exploracion_fisica');
                        this.arreglo_campos.push('solicitud_estudios');
                        this.arreglo_campos.push('diagnostico_tratamiento');

                        this.boton_activo = 'interconsulta';

                        this.imprimir_receta = false;
                        this.imprimir_estudios = true;
                        this.imprimir_nota_interconsulta = true;
                        break;
                    
                    case 'NOTRA':
                        this.opciones.push({clave:'interconsulta', label:'NOTA DE TRASLADO'});
                        this.opciones.push({clave:'exploracion', label:'SIGNOS VITALES'});
                        this.opciones.push({clave:'diagnostico_tratamiento', label:'DIAGNÓSTICO Y MANEJO'});

                        this.arreglo_campos.push('interconsulta');
                        this.arreglo_campos.push('exploracion');
                        this.arreglo_campos.push('diagnostico_tratamiento');

                        this.boton_activo = 'interconsulta';

                        this.imprimir_receta = false;
                        this.imprimir_estudios = false;
                        this.imprimir_nota_interconsulta = false;
                        this.imprimir_nota_traslado = true;
                        break;
                    
                    case 'CERME':
                        this.opciones.push({clave:'cetificado_medico', label:'CERTIFICADO MEDICO'});
                        this.arreglo_campos.push('cetificado_medico');

                        this.boton_activo = 'cetificado_medico';
                        break;
                }
            });
            
        },
        separarData(datos){
            this.estatus = datos.estatus;

            if(this.estatus == 'terminada' && !this.es_edicion){
                this.is_informativo = true;
            }

            this.costo_consulta = datos.costo;

            this.interconsulta.interconsulta = datos.interconsulta;
            this.interconsulta.servicio_interconsulta = datos.servicio_interconsulta;
            this.interconsulta.servicio_consultado = datos.servicio_consultado;
            this.interconsulta.resumen_clinico = datos.resumen_clinico;

            this.padecimiento.padecimiento = datos.sintomas;
            this.padecimiento.subjetivo = datos.subjetivo;
            this.padecimiento.objetivo = datos.objetivo;
            this.padecimiento.analisis = datos.analisis;
            this.padecimiento.plan = datos.plan;
            
            this.padecimiento.estatus  = datos.estatus;

            this.exploracion.json_signos_vitales = datos.json_signos_vitales;
            this.exploracion.trigliceridos       = datos.trigliceridos;
            this.exploracion.colesterol          = datos.colesterol;
            this.exploracion.hemoglobina         = datos.hemoglobina;
            this.exploracion.semanas_grafica     = datos.semanas_grafica;
            this.exploracion.estatus             = datos.estatus;
            this.exploracion.fecha_creacion      = datos.fecha_creacion;

            this.exploracion_fisica.habitus_exterior  = datos.habitus_exterior;
            this.exploracion_fisica.cabeza            = datos.cabeza;
            this.exploracion_fisica.cuello            = datos.cuello;
            this.exploracion_fisica.torax             = datos.torax;
            this.exploracion_fisica.genitales         = datos.genitales;
            this.exploracion_fisica.extremidades      = datos.extremidades;
            this.exploracion_fisica.columna_vertebral = datos.columna_vertebral;
            this.exploracion_fisica.abdomen           = datos.abdomen;
            this.exploracion_fisica.estatus           = datos.estatus;

            this.diagnostico.tratamiento = datos.tratamiento;
            this.diagnostico.pronostico  = datos.pronostico;
            this.diagnostico.estatus     = datos.estatus;

            this.estudios.notas_estudios = datos.notas_estudios;
            this.estudios.estatus        = datos.estatus;

            this.id_consulta = datos.id_consulta;
        },
        irArriba(){
            $('body,html').animate({ scrollTop:'1px' },500);
        }
    }
}
</script>