<template>
    <div class="container">
        <div class="card">
            <div class="card-body">
                <div class="col-md-12">
                    <div class="tamanioLetra text-center"><span><strong>{{ datos.medico.nombre_medico | uppercase }}</strong></span></div>
                    <div class="tamanioLetra text-center"><span><strong>MEDICINA GENERAL CED. PROF.: {{ datos.medico.cedula }}</strong></span></div>
                    <div class="tamanioLetra text-center"><span><strong>TELEFONO MÓVIL: {{ datos.medico.telefono }}</strong></span></div>
                    <br>
                    <div class="tamanioLetra text-center"><span><strong>HISTORIA CLÍNICA GENERAL</strong></span></div>
                </div>
                <br>
                <div class="col-md-12 mb-1">
                    <div class="tamanioLetra"><span><strong>DATOS DEL PACIENTE</strong></span></div>
                </div>
                <div class="row col-md-12 ml-2">
                    <div class="tamanioLetra col-md-3"><span><strong>NOMBRE:</strong></span></div>
                    <div class="tamanioLetra col-md-9"><span><strong>{{ datos.paciente.nombre_paciente | uppercase }}</strong></span></div>
                </div>
                <div class="row col-md-12 ml-2">
                    <div class="tamanioLetra col-md-3"><span><strong>FECHA DE NACIMIENTO:</strong></span></div>
                    <div class="tamanioLetra col-md-9"><span><strong>{{ datos.paciente.fecha_nacimiento | uppercase }}</strong></span></div>
                </div>
                <div class="row col-md-12 ml-2">
                    <div class="tamanioLetra col-md-3"><span><strong>EDAD:</strong></span></div>
                    <div class="tamanioLetra col-md-9"><span><strong>{{ datos.paciente.edad }}</strong></span></div>
                </div>
                <div class="row col-md-12 ml-2">
                    <div class="tamanioLetra col-md-3"><span><strong>SEXO:</strong></span></div>
                    <div class="tamanioLetra col-md-9"><span><strong>{{ datos.paciente.sexo | uppercase }}</strong></span></div>
                </div>
                <div class="row col-md-12 ml-2">
                    <div class="tamanioLetra col-md-3"><span><strong>GRUPO SANGUÍNEO:</strong></span></div>
                    <div class="tamanioLetra col-md-9"><span><strong>{{ datos.paciente.tipo_sangre }}</strong></span></div>
                </div>
                <div class="row col-md-12 ml-2">
                    <div class="tamanioLetra col-md-3"><span><strong>DOMICILIO:</strong></span></div>
                    <div class="tamanioLetra col-md-9"><span><strong>{{ datos.paciente.domicilio | uppercase }}</strong></span></div>
                </div>

                <div class="mb-1 mt-2">
                    <div class="float-left">
                        <span class="tamanioLetra"><strong>EXPANDIR TODO</strong></span><label for="expandir" class="tamanioLetra"><toggle-button :sync="true" v-model="mostrar_todo" @change="mostrarTodo" color="#43aee6" :labels="{ checked: 'SI', unchecked: 'NO' }"/></label>
                    </div>
                    <div class="tamanioLetra float-right"><span><strong>TOTAL DE CITAS: {{ datos.consultas.length }}</strong></span></div>
                </div>

                <br>
                <br>

                <div>
                    <ul class="ml-4 nav nav-tabs" id="myTab" role="tablist">
                        <li class="ml-2 nav-item">
                            <button :class="{ active : que_historial_ver == 'GENERAL', par_principal_GENERAL : que_historial_ver != 'GENERAL'}"  role="tab" class="nav_motivo" @click="cambioPestanaMotivo('GENERAL')">
                                GENERAL
                            </button>
                        </li>
                        <li class="ml-2 nav-item">
                            <button :class="{ active : que_historial_ver == 'CONSU', par_principal_CONSU : que_historial_ver != 'CONSU'}"  role="tab" class="nav_motivo" @click="cambioPestanaMotivo('CONSU')">
                                CONSULTA
                            </button>
                        </li>
                        <li class="ml-2 nav-item">
                            <button :class="{ active : que_historial_ver == 'ESTD', par_principal_ESTD : que_historial_ver != 'ESTD'}" role="tab" class="nav_motivo" @click="cambioPestanaMotivo('ESTD')">
                                ESTUDIOS
                            </button>
                        </li>
                        <li class="ml-2 nav-item">
                            <button :class="{ active : que_historial_ver == 'NOTEV', par_principal_NOTEV : que_historial_ver != 'NOTEV'}" role="tab" class="nav_motivo" @click="cambioPestanaMotivo('NOTEV')">
                                NOTA DE EVOLUCION
                            </button>
                        </li>
                        <li class="ml-2 nav-item">
                            <button :class="{ active : que_historial_ver == 'NOTIN', par_principal_NOTIN : que_historial_ver != 'NOTIN'}" role="tab" class="nav_motivo" @click="cambioPestanaMotivo('NOTIN')">
                                NOTA DE INTERCONSULTA
                            </button>
                        </li>
                        <!-- <li class="ml-2 nav-item">
                            <button :class="{ active : que_historial_ver == 'NOTRA', par_principal_NOTRA : que_historial_ver != 'NOTRA'}" role="tab" class="nav_motivo" @click="cambioPestanaMotivo('NOTRA')">
                                NOTA DE TRASLADO
                            </button>
                        </li> -->
                        <li class="ml-2 nav-item">
                            <button :class="{ active : que_historial_ver == 'CERME', par_principal_CERME : que_historial_ver != 'CERME'}" role="tab" class="nav_motivo" @click="cambioPestanaMotivo('CERME')">
                                CERTIFICADOS MEDICOS
                            </button>
                        </li>
                    </ul>
                </div>

                <div class="contenedor_historial">
                    <div style="clear:left" :class="{['par_principal_' + consulta.clave]:true}" class="tamanioLetra shadow mb-4 rounded" v-for="(consulta, index) in datos.consultas" :key="index">
                        <div>
                            <div style="float: left" class="ml-5">
                                <i class="fas fa-caret-right"></i><button @click="id_ver_consulta = consulta.id_cita; ver_consulta_ver = true" :class="{['par_principal_' + consulta.clave] :true}" class="btn btn-sm">VER</button>
                            </div>
                            <div @click="mostrarConsulta(index)" class="p-1 rounded shadow row" :class="{['par_principal_' + consulta.clave]:true, 'justify-content-center':(!mostrar_todo && !mostrar_consulta[index])}">
                                <div>
                                    {{ index + 1 }}. FECHA CONSULTA: {{ consulta.fecha_creacion }}
                                </div>

                                <div class="ml-5" v-if="!mostrar_todo && !mostrar_consulta[index]">
                                    <strong>{{ consulta.nombre_medico | uppercase }}</strong>
                                </div>
                                <div class="ml-5">{{ consulta.motivo | uppercase }}</div>
                            </div>
                        </div>

                        <div style="display: flow-root;" v-if="mostrar_todo || mostrar_consulta[index]">
                            <div class="row col-md-12 pt-2">
                                <div class="tamanioLetra col-md-2"><span><strong>MÉDICO:</strong></span></div>
                                <div class="tamanioLetra col-md-10"><span><strong>{{ consulta.nombre_medico | uppercase }}</strong></span></div>
                            </div>
                            <div class="row col-md-12">
                                <div class="tamanioLetra col-md-2"><span><strong>CÉDULA:</strong></span></div>
                                <div class="tamanioLetra col-md-10"><span><strong>{{ consulta.cedula }}</strong></span></div>
                            </div>

                            <section v-if="consulta.sintomas">
                                <hr>
                                <div class="pl-2">
                                    <div class="row col-md-12">
                                        <div><span><strong>PADECIMIENTOS</strong></span></div>
                                    </div>
                                    <div class="col-md-12">
                                        <div><span>{{ consulta.sintomas | uppercase }}</span></div>
                                    </div>
                                </div>
                            </section>

                            <section v-if="consulta.json_signos_vitales || consulta.trigliceridos || consulta.colesterol || consulta.hemoglobina || consulta.semanas_grafica">
                                <hr>
                                <div class="p-2">
                                    <div v-if="consulta.json_signos_vitales || consulta.trigliceridos || consulta.colesterol || consulta.hemoglobina" class="row col-md-12">
                                        <div><span><strong>EXPLORACIÓN</strong></span></div>
                                    </div>
                                    <div class="row col-md-12" v-if="consulta.json_signos_vitales">
                                        <div class="row col-md-6">
                                            <div class="col-md-12">
                                                <div><span><strong>SIGNOS VITALES</strong></span></div>
                                            </div>
                                            <div style="margin-left:20px" class="row col-md-12">
                                                <div class="col-md-4"><span><strong>TA:</strong></span></div>
                                                <div class="col-md-8"><span>{{ getObjJson(consulta.json_signos_vitales).ta }} mm/Hg.</span></div>
                                            </div>

                                            <div style="margin-left:20px" class="row col-md-12">
                                                <div class="col-md-4"><span><strong>TEMPERATURA:</strong></span></div>
                                                <div class="col-md-8"><span>{{ getObjJson(consulta.json_signos_vitales).temp }} °C.</span></div>
                                            </div>

                                            <div style="margin-left:20px" class="row col-md-12">
                                                <div class="col-md-4"><span><strong>FR:</strong></span></div>
                                                <div class="col-md-8"><span>{{ getObjJson(consulta.json_signos_vitales).fr }} /min.</span></div>
                                            </div>

                                            <div style="margin-left:20px" class="row col-md-12">
                                                <div class="col-md-4"><span><strong>FC:</strong></span></div>
                                                <div class="col-md-8"><span>{{ getObjJson(consulta.json_signos_vitales).fc }} /min.</span></div>
                                            </div>

                                            <div style="margin-left:20px" class="row col-md-12">
                                                <div class="col-md-4"><span><strong>SAT DE 02:</strong></span></div>
                                                <div class="col-md-8"><span>{{ getObjJson(consulta.json_signos_vitales).sat_o2 }} %.</span></div>
                                            </div>

                                            <div style="margin-left:20px" class="row col-md-12">
                                                <div class="col-md-4"><span><strong>GLUCOSA:</strong></span></div>
                                                <div class="col-md-8"><span>{{ getObjJson(consulta.json_signos_vitales).glucosa }} mg/dl.</span></div>
                                            </div>
                                        </div>

                                        <div class="row col-md-6">
                                            <div class="col-md-12">
                                                <div><span><strong>MEDIDAS ANTROPOMÉTRICAS</strong></span></div>
                                            </div>
                                            <div style="margin-left:20px" class="row col-md-12">
                                                <div class="col-md-4"><span><strong>TALLA:</strong></span></div>
                                                <div class="col-md-8"><span>{{ getObjJson(consulta.json_signos_vitales).talla }} m.</span></div>
                                            </div>

                                            <div style="margin-left:20px" class="row col-md-12">
                                                <div class="col-md-4"><span><strong>PESO:</strong></span></div>
                                                <div class="col-md-8"><span>{{ getObjJson(consulta.json_signos_vitales).peso }} kg.</span></div>
                                            </div>

                                            <div style="margin-left:20px" class="row col-md-12">
                                                <div class="col-md-4"><span><strong>IMC:</strong></span></div>
                                                <div class="col-md-8"><span>{{ getObjJson(consulta.json_signos_vitales).imc }}</span></div>
                                            </div>

                                            <div style="margin-left:20px" class="row col-md-12">
                                                <div class="col-md-12"><span>{{ getObjJson(consulta.json_signos_vitales).peso_ideal }}</span></div>
                                            </div>
                                        </div>
                                    </div>

                                    <br>

                                    <div class="row col-md-12" v-if="consulta.trigliceridos || consulta.colesterol || consulta.hemoglobina">
                                        <div class="row col-md-6" v-if="consulta.trigliceridos || consulta.colesterol">
                                            <div class="col-md-12">
                                                <div><span><strong>COLESTEROL Y TRIGLICÉRIDOS</strong></span></div>
                                            </div>
                                            <div style="margin-left:20px" class="row col-md-12">
                                                <div class="col-md-4"><span><strong>TRIGLICÉRIDOS:</strong></span></div>
                                                <div class="col-md-8"><span>{{ consulta.trigliceridos }} mg/dl.</span></div>
                                            </div>

                                            <div style="margin-left:20px" class="row col-md-12">
                                                <div class="col-md-4"><span><strong>COLESTEROL:</strong></span></div>
                                                <div class="col-md-8"><span>{{ consulta.colesterol }} mg/dl.</span></div>
                                            </div>
                                        </div>

                                        <div class="row col-md-6" v-if="consulta.hemoglobina">
                                            <div class="col-md-12">
                                                <div><span><strong>HEMOGLOBINA</strong></span></div>
                                            </div>
                                            <div style="margin-left:20px" class="row col-md-12">
                                                <div class="col-md-4"><span><strong>A1C:</strong></span></div>
                                                <div class="col-md-8"><span>{{ consulta.hemoglobina }} m%.</span></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="consulta.semanas_grafica">
                                        <div style="width:70%" class="container text-center h-100 d-flex justify-content-center align-items-center">
                                            <div style="margin-left:20px" class="row col-md-12">
                                                <div class="col-md-4"><span><strong>SEMANAS:</strong></span></div>
                                                <div class="col-md-8"><span>{{ consulta.semanas_grafica }} </span></div>
                                            </div>
                                        </div>
                                        <ul class="nav nav-tabs container text-center d-flex justify-content-center align-items-center" style="width:50%" id="myTab" role="tablist">
                                            <li class="nav-item">
                                                <button role="tab" class="btn btn-outline-secondary" :class="{ active: (tipo_grafica == 'peso') }" @click="cambioPestanaGrafica('peso')">
                                                    PESO
                                                </button>
                                            </li>
                                            <li class="nav-item">
                                                <button role="tab" class="btn btn-outline-secondary" :class="{ active: (tipo_grafica == 'Talla') }" @click="cambioPestanaGrafica('Talla')">
                                                    TALLA
                                                </button>
                                            </li>
                                            <li class="nav-item">
                                                <button role="tab" class="btn btn-outline-secondary" :class="{ active: (tipo_grafica == 'peso_completo') }" @click="cambioPestanaGrafica('peso_completo')">
                                                    PESO GRÁFICA COMPLETA
                                                </button>
                                            </li>
                                            <li class="nav-item">
                                                <button role="tab" class="btn btn-outline-secondary" :class="{ active: (tipo_grafica == 'talla_completo') }" @click="cambioPestanaGrafica('talla_completo')">
                                                    TALLA GRÁFICA COMPLETA
                                                </button>
                                            </li>
                                        </ul>
                                        <div style="width:70%" class="container text-center h-100 d-flex justify-content-center align-items-center body card-body">
                                            <Percentiles :fecha="consulta.fecha_creacion" :talla="getObjJson(consulta.json_signos_vitales).talla" :peso="getObjJson(consulta.json_signos_vitales).peso" :id_paciente="id_paciente" :semanas="consulta.semanas_grafica" :key="tipo_grafica" :tipo_grafica="tipo_grafica"></Percentiles>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section v-if="consulta.subjetivo">
                                <hr>
                                <div class="pl-2">
                                    <div class="row col-md-12">
                                        <div><span><strong>SUBJETIVO</strong></span></div>
                                    </div>
                                    <div class="col-md-12">
                                        <div><span>{{ consulta.subjetivo | uppercase }}</span></div>
                                    </div>
                                </div>
                                <hr>
                            </section>

                            <section v-if="consulta.objetivo">
                                <hr>
                                <div class="pl-2">
                                    <div class="row col-md-12">
                                        <div><span><strong>OBJETIVO</strong></span></div>
                                    </div>
                                    <div class="col-md-12">
                                        <div><span>{{ consulta.objetivo | uppercase }}</span></div>
                                    </div>
                                </div>
                                <hr>
                            </section>

                            <section v-if="consulta.analisis">
                                <hr>
                                <div class="pl-2">
                                    <div class="row col-md-12">
                                        <div><span><strong>ANALISIS</strong></span></div>
                                    </div>
                                    <div class="col-md-12">
                                        <div><span>{{ consulta.analisis | uppercase }}</span></div>
                                    </div>
                                </div>
                                <hr>
                            </section>

                            <section v-if="consulta.plan">
                                <hr>
                                <div class="pl-2">
                                    <div class="row col-md-12">
                                        <div><span><strong>PLAN</strong></span></div>
                                    </div>
                                    <div class="col-md-12">
                                        <div><span>{{ consulta.plan | uppercase }}</span></div>
                                    </div>
                                </div>
                                <hr>
                            </section>

                            <section v-if="consulta.habitus_exterior || consulta.cabeza || consulta.cuello || consulta.torax || consulta.genitales || consulta.extremidades || consulta.columna_vertebral || consulta.abdomen">
                                <hr>
                                <div class="p-2">
                                    <div class="row col-md-12">
                                        <div><span><strong>EXPLORACIÓN FÍSICA</strong></span></div>
                                    </div>
                                    <div class="row col-md-12">
                                        <div class="row col-md-6" v-if="consulta.habitus_exterior || consulta.cabeza || consulta.cuello || consulta.torax">
                                            <div style="margin-bottom:10px" class="row col-md-12" v-if="consulta.habitus_exterior">
                                                <div style="margin-left:5px" class="row col-md-12">
                                                    <div><strong>HABITUS EXTERIOR:</strong></div>
                                                </div>
                                                <div style="margin-left:20px" class="row col-md-12">
                                                    <div>{{ consulta.habitus_exterior | uppercase }}</div>
                                                </div>
                                            </div>

                                            <div style="margin-bottom:10px" class="row col-md-12" v-if="consulta.cabeza">
                                                <div style="margin-left:5px" class="row col-md-12">
                                                    <div><strong>CABEZA:</strong></div>
                                                </div>
                                                <div style="margin-left:20px" class="row col-md-12">
                                                    <div>{{ consulta.cabeza | uppercase }}</div>
                                                </div>
                                            </div>

                                            <div style="margin-bottom:10px" class="row col-md-12" v-if="consulta.cuello">
                                                <div style="margin-left:5px" class="row col-md-12">
                                                    <div><strong>CUELLO:</strong></div>
                                                </div>
                                                <div style="margin-left:20px" class="row col-md-12">
                                                    <div>{{ consulta.cuello | uppercase }}</div>
                                                </div>
                                            </div>

                                            <div style="margin-bottom:10px" class="row col-md-12" v-if="consulta.torax">
                                                <div style="margin-left:5px" class="row col-md-12">
                                                    <div><strong>TÓRAX:</strong></div>
                                                </div>
                                                <div style="margin-left:20px" class="row col-md-12">
                                                    <div>{{ consulta.torax | uppercase }}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row col-md-6" v-if="consulta.genitales || consulta.extremidades || consulta.columna_vertebral || consulta.abdomen">
                                            <div style="margin-bottom:10px" class="row col-md-12" v-if="consulta.genitales">
                                                <div style="margin-left:5px" class="row col-md-12">
                                                    <div><strong>GENITALES EXT.RECTAL Y/O VAGINAL:</strong></div>
                                                </div>
                                                <div style="margin-left:20px" class="row col-md-12">
                                                    <div>{{ consulta.genitales | uppercase }}</div>
                                                </div>
                                            </div>

                                            <div style="margin-bottom:10px" class="row col-md-12" v-if="consulta.extremidades">
                                                <div style="margin-left:5px" class="row col-md-12">
                                                    <div><strong>EXTREMIDADES:</strong></div>
                                                </div>
                                                <div style="margin-left:20px" class="row col-md-12">
                                                    <div>{{ consulta.extremidades | uppercase }}</div>
                                                </div>
                                            </div>

                                            <div style="margin-bottom:10px" class="row col-md-12" v-if="consulta.columna_vertebral">
                                                <div style="margin-left:5px" class="row col-md-12">
                                                    <div><strong>COLUMNA VERTEBRAL:</strong></div>
                                                </div>
                                                <div style="margin-left:20px" class="row col-md-12">
                                                    <div>{{ consulta.columna_vertebral | uppercase }}</div>
                                                </div>
                                            </div>

                                            <div style="margin-bottom:10px" class="row col-md-12" v-if="consulta.abdomen">
                                                <div style="margin-left:5px" class="row col-md-12">
                                                    <div><strong>ABDOMEN:</strong></div>
                                                </div>
                                                <div style="margin-left:20px" class="row col-md-12">
                                                    <div>{{ consulta.abdomen | uppercase }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section>
                                <hr>
                                <div class="p-2">
                                    <div v-if="consulta.diagnostico_tratamiento.length > 0">
                                        <div class="row col-md-12">
                                            <div><span><strong>DIAGNÓSTICO Y TRATAMIENTO</strong></span></div>
                                        </div>

                                        <div v-for="(diagnostico, index) in consulta.diagnostico_tratamiento" :key="index" style="width:50%; float:left">
                                            <div class="row col-md-12">
                                                <div style="margin-left:5px" class="row col-md-12">
                                                    <div><strong>DIAGNÓSTICO</strong></div>
                                                </div>
                                                <div style="margin-left:20px" class="row col-md-12">
                                                    <div>• {{ diagnostico.clave | uppercase }} - {{ diagnostico.nombre | uppercase }}</div>
                                                </div>
                                            </div>
                                            <div class="row col-md-12">
                                                <div style="margin-left:5px" class="row col-md-12">
                                                    <div><strong>TRATAMIENTO</strong></div>
                                                </div>
                                                <div style="margin-left:20px" class="row col-md-12" v-for="(medicamento, index_med) in diagnostico.medicamentos" :key="index_med">
                                                    <div>• {{ medicamento.sustancia_activa | uppercase }}, {{ medicamento.presentacion | uppercase }}, {{ medicamento.concentracion | uppercase }}, {{ medicamento.dosis | uppercase }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row col-md-12 pt-2" v-if="consulta.tratamiento || consulta.pronostico">
                                        <div class="row col-md-6" v-if="consulta.tratamiento">
                                            <div style="margin-left:5px" class="row col-md-12">
                                                <div><strong>INDICACIONES/MEDIDAS GENERALES:</strong></div>
                                            </div>
                                            <div style="margin-left:20px" class="row col-md-12">
                                                <div>{{ consulta.tratamiento | uppercase }}</div>
                                            </div>
                                        </div>

                                        <div class="row col-md-6" v-if="consulta.pronostico">
                                            <div style="margin-left:5px" class="row col-md-12">
                                                <div><strong>PRONÓSTICO:</strong></div>
                                            </div>
                                            <div style="margin-left:20px" class="row col-md-12">
                                                <div>{{ consulta.pronostico | uppercase }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section v-if="consulta.estudios_listado.length > 0">
                                <hr>
                                <div class="p-2">
                                    <div class="row col-md-12">
                                        <div><span><strong>ESTUDIOS E INTERPRETACIÓN DE RESULTADOS</strong></span></div>
                                    </div>

                                    <div class="row col-md-12">
                                        <div style="margin-left:5px" class="row col-md-12">
                                            <div><strong>ESTUDIOS</strong></div>
                                        </div>
                                    </div>

                                    <div style="width:48%; float:left; padding-left:30px;" v-for="(listado_estudio, index_listado_estudio) in consulta.estudios_listado" :key="index_listado_estudio">
                                        <div><strong>• {{ listado_estudio.nombre }}</strong></div>
                                        <div style="margin-left:30px">{{ listado_estudio.notas }}</div>
                                        <div class="form-group col-lg-12 pt-1 row" v-for="(lista_estudi, lista_estudios_index) in listado_estudio.archivos" :key="lista_estudios_index">
                                            <a target="_blank" class="col-lg-10 btn btn-primary btn-sm btn-block" :href="listado_estudio.ruta + lista_estudi">{{ lista_estudi.substr(lista_estudi.lastIndexOf("/") + 1) | uppercase }}</a>
                                        </div>
                                    </div>

                                    <br>

                                    <div v-if="consulta.notas_estudios" class="row col-md-12">
                                        <div style="margin-left:5px" class="row col-md-12">
                                            <div><strong>NOTAS GENERALES DE LOS ESTUDIOS</strong></div>
                                        </div>
                                        <div style="margin-left:20px" class="col-md-12">
                                            <div style="margin-left:30px">{{ consulta.notas_estudios | uppercase }}</div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal v-model="ver_consulta_ver" size="xl" id="ver_consulta_medica" title="Consulta">
            <Consulta :desde_historial="true" v-if="id_ver_consulta" :id_cita="id_ver_consulta" :id_paciente="id_paciente"></Consulta>
        </b-modal>

        <Loading :mensaje="'Obteniendo información del paciente'" :show="showLoading"></Loading>
    </div>
</template>

<script>
import api from "../config.js";

import Percentiles from './graficas/Percentiles';
import Consulta from './Consulta';
import Loading from "./Loading.vue";

export default {
    name: 'HistorialMedico',

    props: ['id_paciente'],

    components:{
        Percentiles,
        Consulta,
        Loading
    },

    data: () => ({
        que_historial_ver: 'GENERAL',
        ver_consulta_ver: false,
        id_ver_consulta: null,
        tipo_grafica: 'peso',
        mostrar_todo: false,
        showLoading: false,
        mostrar_consulta: [],
        datos: {
            medico:{
                nombre_medico:'',
            },
            paciente:{
                nombre_paciente: '',
                fecha_nacimiento: '',
                edad: '',
                sexo: '',
                tipo_sangre: '',
                domicilio: '', 
            },
            consultas:[],
        },
        mes_nombre: [
            {nombre: 'Enero'},
            {nombre: 'Febrero'},
            {nombre: 'Marzo'},
            {nombre: 'Abril'},
            {nombre: 'Mayo'},
            {nombre: 'Junio'},
            {nombre: 'Julio'},
            {nombre: 'Agosto'},
            {nombre: 'Septiembre'},
            {nombre: 'Octubre'},
            {nombre: 'Noviembre'},
            {nombre: 'Diciembre'},
        ],
    }),

    created: function() {
        this.getHistorial('GENERAL');
    },

    methods: {
        cambioPestanaMotivo(clave_motivo){
            this.que_historial_ver = clave_motivo;
            this.getHistorial(clave_motivo);
        },
        cambioPestanaGrafica(tipo){
            this.tipo_grafica = tipo;
        },
        mostrarTodo(){
            if(!this.mostrar_todo){
                this.mostrar_consulta = [];
            }
        },
        mostrarConsulta(index){
            this.mostrar_consulta[index] = !this.mostrar_consulta[index];
            this.mostrar_consulta.splice(0, 0 );
        },
        getObjJson(objeto){
            if(!objeto){
                objeto = '{"ta":"","temp":"","fr":"","fc":"","talla":"","peso":"","cadera":"","cintura":"","imc":"","peso_ideal":"","sat_o2":"","glucosa":""}';
            }
            var json = JSON.parse(objeto);
                
            return json;
        },
        calcularEdad(fecha) {
            var hoy = new Date();
            var cumpleanos = new Date(fecha);
            var edad = hoy.getFullYear() - cumpleanos.getFullYear();
            var m = hoy.getMonth() - cumpleanos.getMonth();

            if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
                edad--;
            }

            return edad;
        },
        formatoFecha(fecha){
            var formato = new Date(fecha.replace(/-/g, '/'));
            return (formato.getDate()) + ' de ' + this.mes_nombre[formato.getMonth()].nombre + ' de ' + formato.getFullYear();
        },
        async getHistorial(tipo){
            this.showLoading = true;

            var historial = await api.get("api/get_historial/" + this.id_paciente + "?tipo_motivo=" + tipo);
            this.datos = historial.data;
            this.datos.paciente.edad = this.calcularEdad(this.datos.paciente.fecha_nacimiento);
            this.datos.paciente.fecha_nacimiento = this.formatoFecha(this.datos.paciente.fecha_nacimiento);
            
            if(this.datos.paciente.sexo == 'F'){
                this.datos.paciente.sexo = 'Femenino';
            }else{
                this.datos.paciente.sexo = 'Masculino';
            }

            this.showLoading = false;
        },
    }
}
</script>