<template>
   <div class="container">
        <div class="row">
            <div class="col">
                <div class="card-body">
                    <div class="form-group row">
                        <h4 for="nombre" class="col-lg-12 control-label text-primary">Nacimiento</h4>
                    </div>

                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group row">
                                    <label class="col-lg-6 control-label text-primary">Anestesia:</label>
                                    <label for="anestesia" style="font-size:13px" class="control-label text-primary"><toggle-button :sync="true" v-model="anestesia" color="#43aee6" :labels="{ checked: 'SI', unchecked: 'NO' }"/></label>
                                </div>
                            </div>
                            <div v-if="anestesia" class="col-md-6">
                                <div class="form-group row">
                                    <label class="col-lg-3 control-label text-primary">Cuál:</label>
                                    <div class="col-lg-9">
                                        <input v-model="perinatales.cual_anestesia" style="font-size:13px" type="text" class="form-control" id="cual_anestesia"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group row">
                                    <label class="col-lg-6 control-label text-primary">Curso normal:</label>
                                    <label for="curso_normal" style="font-size:13px" class="control-label text-primary"><toggle-button :sync="true" v-model="curso_normal" color="#43aee6" :labels="{ checked: 'SI', unchecked: 'NO' }"/></label>
                                </div>
                            </div>
                            <div v-if="!curso_normal" class="col-md-6">
                                <div class="form-group row">
                                    <label class="col-lg-4 control-label text-primary">¿Por qué?:</label>
                                    <div class="col-lg-8">
                                        <input v-model="perinatales.curso_normal_porque" style="font-size:13px" type="text" class="form-control" id="cual_anestesia"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-lg-3 control-label text-primary">Sitio de atención:</label>
                            <div class="col-lg-9">
                                <input style="font-size:13px" v-model="perinatales.sitio_atencion" type="text" class="form-control" id="sitio_atencion"/>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-lg-3 control-label text-primary">Tipo de nacimiento:</label>
                            <div class="col-lg-9">
                                <select v-model="perinatales.tipo_nacimiento" class="form-control" id="tipo_nacimiento">
                                    <option>Parto</option>
                                    <option>Cesárea</option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-lg-3 control-label text-primary">Duración de parto:</label>
                            <div class="col-lg-9">
                                <input v-model="perinatales.duracion_parto" style="font-size:13px" type="text" class="form-control" id="duracion_parto"/>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div class="form-group row">
                        <h4 class="col-lg-12 control-label text-primary">Bebé</h4>
                    </div>

                    <div class="form-group row col">
                        <label class="col-lg-2 control-label text-primary">Peso:</label>
                        <div class="col-lg-2">
                            <input :maxlength="max" @keydown="filterKey" v-model="perinatales.peso_bebe" style="font-size:13px" type="text" class="form-control" id="peso_bebe"/>
                        </div>
                        <label class="col-lg-2 control-label text-primary">Kg.</label>
                    </div>

                    <div class="form-group row col">
                        <label class="col-lg-2 control-label text-primary">Talla:</label>
                        <div class="col-lg-2">
                            <input :maxlength="max" @keydown="filterKey" v-model="perinatales.talla_bebe" style="font-size:13px" type="text" class="form-control" id="talla_bebe"/>
                        </div>
                        <label class="col-lg-2 control-label text-primary">cm.</label>
                    </div>

                    <div class="form-group row col">
                        <label class="col-lg-2 control-label text-primary">APGAR:</label>
                        <div class="col-lg-2">
                            <input :maxlength="max" @keydown="filterKey" v-model="perinatales.apga_bebe" style="font-size:13px" type="text" class="form-control" id="apga_bebe"/>
                        </div>
                    </div>

                    <div class="form-group row col">
                        <label class="col-lg-4 control-label text-primary">Silverman-Anderson:</label>
                        <div class="col-lg-2">
                            <input :maxlength="2" @keydown="filterKey" v-model="perinatales.silverman" style="font-size:13px" type="text" class="form-control" id="silverman"/>
                        </div>
                    </div>

                    <div class="col-md-12 row">
                        <div class="col-lg-6">
                            <div class="form-group row">
                                <label for="nombre" class="col-lg-12 control-label text-primary">Maniobras de reanimación:</label>
                                <div class="col-lg-12">
                                    <textarea class="form-control" v-model="perinatales.maniobra_reanimacion" style="width:100%; font-size:13px"></textarea>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="form-group row">
                                <label for="nombre" class="col-lg-12 control-label text-primary">Otros:</label>
                                <div class="col-lg-12">
                                    <textarea class="form-control" v-model="perinatales.perinatales_otro" style="width:100%; font-size:13px"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
   </div>
</template>

<script>
export default {
    name: 'Perinatales',

    props: ['data_perinatales'],

    data: () => ({
        max: 4,
        perinatales:{
            anestesia: '',
            cual_anestesia: '',
            sitio_atencion: '',
            curso_normal: '',
            tipo_nacimiento: '',
            duracion_parto: '',
            peso_bebe: '',
            talla_bebe: '',
            apga_bebe: '',
            silverman: '',
            maniobra_reanimacion: '',
            perinatales_otro: ''
        },

        anestesia: false,
        curso_normal: false,
    }),

    created: function(){
        if(this.data_perinatales){
            (this.data_perinatales.anestesia == '1')? this.anestesia = true : this.anestesia = false;
            (this.data_perinatales.curso_normal == '1')? this.curso_normal = true : this.curso_normal = false;
            //this.perinatales = this.data_perinatales;

            this.perinatales.anestesia = this.data_perinatales.anestesia;
            this.perinatales.cual_anestesia = this.data_perinatales.cual_anestesia;
            this.perinatales.sitio_atencion = this.data_perinatales.sitio_atencion;
            this.perinatales.curso_normal = this.data_perinatales.curso_normal;
            this.perinatales.tipo_nacimiento = this.data_perinatales.tipo_nacimiento;
            this.perinatales.duracion_parto = this.data_perinatales.duracion_parto;
            this.perinatales.peso_bebe = this.data_perinatales.peso_bebe;
            this.perinatales.talla_bebe = this.data_perinatales.talla_bebe;
            this.perinatales.apga_bebe = this.data_perinatales.apga_bebe;
            this.perinatales.silverman = this.data_perinatales.silverman;
            this.perinatales.maniobra_reanimacion = this.data_perinatales.maniobra_reanimacion;
            this.perinatales.perinatales_otro = this.data_perinatales.perinatales_otro;
        }
    },

    methods: {
        getInfo(){
            (this.anestesia)? this.perinatales.anestesia = '1' : this.perinatales.anestesia = '0';
            (this.curso_normal)? this.perinatales.curso_normal = '1' : this.perinatales.curso_normal = '0';

            return this.perinatales;
        },
        filterKey(e){
            if((e.key >= 0 && e.key <= 9) || e.key == 'Backspace') {
                //
            }else{
                e.preventDefault();
            }
        }
    }
}
</script>