<template>
    <div class="container">
        <div class="card shadow-lg">
            <div class="card-body">
                <div v-show="ver_estadisticas_por_medico" class="container">
                    <div class="text-center">
                        <label class="text-primary">MEDICO</label>
                    </div>
                    <v-select multiple style="background:white" v-model="id_medico" :reduce="nombre => nombre.id_usuario" :options="medicos" label="nombre"/>
                </div>

                <div class="container mt-3 ml-5 text-center">
                    <div class="row col-12">
                        <div class="row col-6">
                            <span class="col-12 text-primary">Inicio período:</span>
                            <date-picker v-model="fecha_inicio" :lang="lang" class="col-12" id="fecha_inicio" placeholder="Seleccione una fecha" valueType="format"></date-picker>
                        </div>

                        <div class="row col-6">
                            <span class="col-12 text-primary">Fin período:</span>
                            <date-picker v-model="fecha_fin" :lang="lang" class="col-12" id="fecha_fin" placeholder="Seleccione una fecha" valueType="format"></date-picker>
                        </div>
                    </div>
                </div>

                <div class="text-center mt-3">
                    <button class="btn btn-primary" @click="Generar">Generar</button>
                </div>
            </div>
        </div>

        <div class="card shadow-lg">
            <div class="card-body">
                <div class="row col-12">
                    <div class="col-12 text-center">
                        <div class="bg-info text-white">Medicos</div>
                        <div v-for="(nombre_medico, index) in nombre_medicos" :key="index" class="row col mb-1 text-center">
                            <div class="text-center ml-5" style="width:30%; height:20px" :style="'background:' + colores[nombre_medico.id_usuario]"></div>
                            {{ nombre_medico.medico }}
                        </div>  
                    </div>
                </div>
            </div>
        </div>
        
        <div class="card shadow-lg">
            <div class="card-body">
                <div class="row col-12">
                    <div class="col-6 text-center">
                        <div class="bg-info text-white">Período</div>
                        <div class="bg-light">{{ fecha_inicio }} al {{ fecha_fin }}</div>
                    </div>

                    <div v-if="consultas.length > 0" class="col-4 text-center">
                        <div class="bg-info text-white">Última consulta</div>
                        <div :style="'background:' + colores[consulta.id_usuario]" v-for="(consulta, index) in consultas" :key="index" class="mb-1">
                            <span class="bg-light p-1">{{ consulta.ultima_consulta }}</span>
                        </div> 
                    </div>

                    <div class="col-2 text-center">
                        <div class="bg-info text-white">Citas pendientes</div>
                        <div :style="'background:' + colores[consulta.id_usuario]" v-for="(consulta, index) in consultas" :key="index" class="mb-1">
                            <span class="bg-light p-1">{{ parseInt(consulta.total_citas) - parseInt(consulta.total_atendidas) }}</span>
                        </div>  
                    </div>
                </div>
            </div>
        </div>

        <div class="card shadow-lg">
            <div class="card-body text-center">
                <div class="col-12 text-center bg-primary text-white">
                    PACIENTES REGISTRADOS 
                </div>
                <div class="row col-12">
                    <div class="col-4">
                        <div class="bg-info text-white">Mujeres</div>
                    </div>
                    <div class="col-4">
                        <div class="bg-info text-white">Hombres</div>
                    </div>
                    <div class="col-4">
                        <div class="bg-info text-white">Total</div>
                    </div>
                </div>

                <div :style="'background:' + colores[paciente.id_usuario]" class="mb-1 row col-12" v-for="(paciente, index) in pacientes" :key="index">
                    <div class="col-4">
                        <div class="bg-light">{{ paciente.femenino }}</div>
                    </div>
                    <div class="col-4">
                        <div class="bg-light">{{ paciente.masculino }}</div>
                    </div>
                    <div class="col-4">
                        <div class="bg-light">{{ parseInt(paciente.masculino) + parseInt(paciente.femenino) }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card shadow-lg">
            <div class="card-body text-center">
                <div class="col-12 text-center bg-primary text-white">
                    CONSULTAS ATENDIDAS
                </div>
                <div class="row col">
                    <div class="col">
                        <div class="bg-info text-white">Mujeres</div>
                    </div>
                    <div class="col">
                        <div class="bg-info text-white">Hombres</div>
                    </div>
                    <div class="col">
                        <div class="bg-info text-white">Total</div>
                    </div>
                    <div class="col">
                        <div class="bg-info text-white">Ganancias</div>
                    </div>
                </div>
                <div :style="'background:' + colores[consulta.id_usuario]" class="mb-1 row col-12" v-for="(consulta, index) in consultas" :key="index">
                    <div class="col">
                        <div class="bg-light">{{ consulta.consulta_atendida_femenino }}</div>
                    </div>
                    <div class="col">
                        <div class="bg-light">{{ consulta.consulta_atendida_masculino }}</div>
                    </div>
                    <div class="col">
                        <div class="bg-light">{{ parseInt(consulta.consulta_atendida_femenino) + parseInt(consulta.consulta_atendida_masculino) }}</div>
                    </div>
                    <div class="col">
                        <div class="bg-light text-danger">${{ consulta.ganancias }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card shadow-lg">
            <div class="card-body text-center">
                <div class="col-12 text-center bg-primary text-white">
                    CONSULTAS ATENDIDAS POR EDAD
                </div>
                <div class="row col-12">
                    <div class="col-1"></div>
                    <div class="col-1">
                        <div class="bg-info text-white">0 - 10</div>
                    </div>
                    <div class="col-1">
                        <div class="bg-info text-white">11 - 20</div>
                    </div>
                    <div class="col-1">
                        <div class="bg-info text-white">21 - 30</div>
                    </div>
                    <div class="col-1">
                        <div class="bg-info text-white">31 - 40</div>
                    </div>
                    <div class="col-1">
                        <div class="bg-info text-white">41 - 50</div>
                    </div>
                    <div class="col-1">
                        <div class="bg-info text-white">51 - 60</div>
                    </div>
                    <div class="col-1">
                        <div class="bg-info text-white">61 - 70</div>
                    </div>
                    <div class="col-1">
                        <div class="bg-info text-white">71 - 80</div>
                    </div>
                    <div class="col-1">
                        <div class="bg-info text-white">81 - 90</div>
                    </div>
                    <div class="col-1">
                        <div class="bg-info text-white">> 91</div>
                    </div>
                    <div class="col-1"></div>
                </div>

                <div :style="'background:' + colores[consulta.id_usuario]" v-for="(consulta, index) in consultas" :key="index" class="mb-1 row col-12">
                    <div class="col-1"></div>
                    <div class="col-1">
                        <div class="bg-light">{{ consulta.a0_10 }}</div>
                    </div>
                    <div class="col-1">
                        <div class="bg-light">{{ consulta.a11_20 }}</div>
                    </div>
                    <div class="col-1">
                        <div class="bg-light">{{ consulta.a21_30 }}</div>
                    </div>
                    <div class="col-1">
                        <div class="bg-light">{{ consulta.a31_40 }}</div>
                    </div>
                    <div class="col-1">
                        <div class="bg-light">{{ consulta.a41_50 }}</div>
                    </div>
                    <div class="col-1">
                        <div class="bg-light">{{ consulta.a51_60 }}</div>
                    </div>
                    <div class="col-1">
                        <div class="bg-light">{{ consulta.a61_70 }}</div>
                    </div>
                    <div class="col-1">
                        <div class="bg-light">{{ consulta.a71_80 }}</div>
                    </div>
                    <div class="col-1">
                        <div class="bg-light">{{ consulta.a81_90 }}</div>
                    </div>
                    <div class="col-1">
                        <div class="bg-light">{{ consulta.a91_100 }}</div>
                    </div>
                    <div class="col-1"></div>
                </div>

            </div>
        </div>

        <div class="card shadow-lg">
            <div class="card-body text-center">
                <div class="col-12 text-center bg-primary text-white">
                    DÍAS DE MAYOR CONSULTA
                </div>
                <div class="row">
                    <div class="col">
                        <div class="bg-info text-white">Domingo</div>
                    </div>
                    <div class="col">
                        <div class="bg-info text-white">Lunes</div>
                    </div>
                    <div class="col">
                        <div class="bg-info text-white">Martes</div>
                    </div>
                    <div class="col">
                        <div class="bg-info text-white">Miércoles</div>
                    </div>
                    <div class="col">
                        <div class="bg-info text-white">Jueves</div>
                    </div>
                    <div class="col">
                        <div class="bg-info text-white">Viernes</div>
                    </div>
                    <div class="col">
                        <div class="bg-info text-white">Sábado</div>
                    </div>
                    <div class="col">
                        <div class="bg-info text-white">Total consultas</div>
                    </div>
                </div>

                <div :style="'background:' + colores[consulta.id_usuario]" class="mb-1 row" v-for="(consulta, index) in consultas" :key="index" >
                    <div class="col">
                        <div class="bg-light">{{ consulta.domingo }}</div>
                    </div>
                    <div class="col">
                        <div class="bg-light">{{ consulta.lunes }}</div>
                    </div>
                    <div class="col">
                        <div class="bg-light">{{ consulta.martes }}</div>
                    </div>
                    <div class="col">
                        <div class="bg-light">{{ consulta.miercoles }}</div>
                    </div>
                    <div class="col">
                        <div class="bg-light">{{ consulta.jueves }}</div>
                    </div>
                    <div class="col">
                        <div class="bg-light">{{ consulta.viernes }}</div>
                    </div>
                    <div class="col">
                        <div class="bg-light">{{ consulta.sabado }}</div>
                    </div>
                    <div class="col">
                        <div class="bg-light">{{ parseInt(consulta.domingo) + 
                                                            parseInt(consulta.lunes) +
                                                            parseInt(consulta.martes) +
                                                            parseInt(consulta.miercoles) +
                                                            parseInt(consulta.jueves) + 
                                                            parseInt(consulta.viernes) + 
                                                            parseInt(consulta.sabado)}}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card shadow-lg">
            <div class="card-body text-center">
                <div class="col-12 text-center bg-primary text-white">
                    CONSULTAS CONCRETA VS NO CON CRETADAS
                </div>
                <div class="row col-12">
                    <div class="col-3">
                        <div class="bg-info text-white">Concretadas</div>
                    </div>
                    <div class="col-2">
                        <div class="bg-info text-white">Porcentaje</div>
                    </div>
                    <div class="col-3">
                        <div class="bg-info text-white">No concretadas</div>
                    </div>
                    <div class="col-2">
                        <div class="bg-info text-white">Porcentaje</div>
                    </div>
                    <div class="col-2">
                        <div class="bg-info text-white">Total</div>
                    </div>
                </div>

                <div :style="'background:' + colores[consulta.id_usuario]" class="mb-1 row col-12" v-for="(consulta, index) in consultas" :key="index">
                    <div class="col-3">
                        <div class="bg-light">{{ consulta.total_atendidas }}</div>
                    </div>
                    <div class="col-2">
                        <div class="bg-light">{{  Math.round((parseInt(consulta.total_atendidas) * 100) / parseInt(consulta.total_citas)) }}%</div>
                    </div>
                    <div class="col-3">
                        <div class="bg-light">{{ parseInt(consulta.total_citas) - parseInt(consulta.total_atendidas) }}</div>
                    </div>
                    <div class="col-2">
                        <div class="bg-light">{{ Math.round(((parseInt(consulta.total_citas) - parseInt(consulta.total_atendidas))*100) / parseInt(consulta.total_citas)) }}%</div>
                    </div>
                    <div class="col-2">
                        <div class="bg-light">{{ consulta.total_citas }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card shadow-lg">
            <div class="card-body text-center">
                <div class="col-12 text-center bg-primary text-white">
                    MEDICAMENTOS MÁS RECETADOS
                </div>
                <div class="row col-12">
                    <div class="col-3">
                        <div class="bg-info text-white">Nombre</div>
                    </div>
                    <div class="col-3">
                        <div class="bg-info text-white">Marca</div>
                    </div>
                    <div class="col-4">
                        <div class="bg-info text-white">Presentación</div>
                    </div>
                    <div class="col-2">
                        <div class="bg-info text-white">Total</div>
                    </div>
                </div>

                <div :style="'background:' + colores[medicamento.id_usuario]" class="mb-1 row col-12" v-for="(medicamento, index) in medicamentos" :key="index">
                    <div class="col-3 bg-light">
                        <div>{{ medicamento.nombre }}</div>
                    </div>
                    <div class="col-3 bg-light">
                        <div>{{ medicamento.marca }}</div>
                    </div>
                    <div class="col-4 bg-light">
                        <div>{{ medicamento.presentacion }}</div>
                    </div>
                    <div class="col-2 bg-light">
                        <div>{{ medicamento.total_atendidas }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from "../config.js";

export default {
    name: 'Estadisticas',

    data: () => ({
        medicos: [],
        id_medico: '',
        fecha_inicio: null,
        fecha_fin: null,
        citas_pendientes: 0,
        consultas:[],
        medicamentos:[],
        pacientes:[],
        colores:[],
        nombre_medicos: [],
        ver_estadisticas_por_medico: false,

        lang:{
            formatLocale: {
                months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                monthsShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
                weekdays: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Vierner', 'Sabado'],
                weekdaysShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
                weekdaysMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
            }
        }
    }),

    created: async function() {
        var user = await this.$store.dispatch("getUsuario"); 

        this.id_medico = user.id_usuario;
        
        var permisos = await this.$store.dispatch("getPermisos");

        if(permisos){
            this.ver_estadisticas_por_medico = permisos.includes('ESTAD');
        }

        await this.getMedicos();
    },

    methods:{
        async Generar(){
            if(this.id_medico.length <= 0){
                this.$toast.error('No se ha seleccionado algún medico', {
                    dismissible: true,
                    position: 'top'
                });

                return;
            }
            if(this.fecha_inicio > this.fecha_fin){
                this.$toast.error('La fecha inicio período tiene que ser menor o igual a la fecha fin período', {
                    dismissible: true,
                    position: 'top'
                });

                return;
            }

            var datos = await api.post("api/generar_reporte",{'id_usuarios': this.id_medico,
                                                                'fecha_inicio': this.fecha_inicio,
                                                                'fecha_fin':this.fecha_fin});

            this.consultas = datos.data.consultas;
            this.medicamentos = datos.data.medicamentos;
            this.pacientes = datos.data.pacientes;
            this.nombre_medicos = datos.data.medicos;

            var color = '#';
            for(var i = 0; i < this.id_medico.length; i++){
                color = '#'+(Math.random()*0xFFFFFF<<0).toString(16)

                this.colores[this.id_medico[i]] = color;
            }
        },

        async getMedicos(){
            var medic = await api.get("api/listado_medicos");
            this.medicos = medic.data;
        }
    }
}
</script>