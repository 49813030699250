import Vue from "vue";
import Vuex from "vuex";
import api from "../config.js"

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(Vuex);
Vue.use(VueToast);

export default new Vuex.Store({
  state: {
    user: null,
    auth: false,
  },
  mutations: {
    SET_USER(state, user){
      
      if(!user){
        localStorage.removeItem('auth');
        localStorage.removeItem('id_usuario');
        localStorage.removeItem('usuario');
      }else{
        state.user = user;
        state.auth = Boolean(user);
        localStorage.setItem('auth', state.auth);
        localStorage.setItem('id_usuario', user.id_usuario);
        localStorage.setItem('usuario', JSON.stringify(user));
      }
    }
  },
  actions: {
    async logout(){
      await api.post("/logout");
      
      localStorage.removeItem('auth');
      localStorage.removeItem('id_usuario');
      localStorage.removeItem('usuario');
    },

    async login({ dispatch }, credentials){
      var cookie = await api.get("/sanctum/csrf-cookie");
      if(cookie.status == 204){
        await api.post("/login", credentials);
      }

      return dispatch("getUsuario");
    },

    async register({ dispatch }, form){
      var response;
      var cookie = await api.get("/sanctum/csrf-cookie");
      if(cookie.status == 204){
        response = await api.post("/register", form);
      }else{
        response = "Sin sesion";
      }
      dispatch("getUsuario");
      return response;
    },

    getPermisos(){
      var usuario = JSON.parse(localStorage.getItem('usuario'));
      this.id_usuario_sesion = usuario.id_usuario;
      var permisos = null;

      if(usuario.permisos){
        permisos = usuario.permisos.split(',');
      }

      return permisos;
    },

    async getUsuario({ commit }){
      var user = null;
      await api.get("/api/user").then(res =>{
        if(res.data.id_usuario){
          commit("SET_USER", res.data);
          user = res.data;
        }else{
          commit("SET_USER", null);
        }
      }).catch(() => {
          commit("SET_USER", null);
      });

      if(!user){
        //location.reload();// = '/login';
      }
      return user;
    },
  },
  modules: {}
});
