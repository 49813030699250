<template>
    <div class="card-body">
        <table cellpadding="0" cellspacing="0" class="tabla_diagnostico">
            <thead>
                <tr>
                    <th></th>
                    <th>Nombre</th>
                    <th>Descripción</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(permiso, lista_index) in permisos" :key="permiso.id_permiso" :class="{par_secundaria:lista_index%2 == 0, inpar_secundaria:lista_index%2 != 0}">
                    <td>
                        <label for="nombre" class="col control-label text-primary"><toggle-button @change="setPermiso(permiso.id_permiso)" v-model="activos[permiso.id_permiso]" :sync="true" color="#43aee6" :labels="{ checked: 'SI', unchecked: 'NO' }"/></label>
                    </td>
                    <td>{{ permiso.nombre }}</td>
                    <td>{{ permiso.descripcion }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import api from "../../config.js";

export default {
    name: 'Permisos',

    props: ['id_usuario'],

    data: () => ({
        permisos: [],
        activos: []
    }),

    created: async function() {
        var listado = await api.get("api/get_listado_permisos/" + this.id_usuario);
        this.permisos = listado.data;

        this.permisos.forEach(permiso => {
            this.activos[permiso.id_permiso] = Boolean(permiso.id_permiso_to_usuario);
        });
    },

    methods: {
        async setPermiso(id_permiso){
            var check = await api.post("api/set_permiso/" + this.id_usuario,{
                'id_permiso':id_permiso,
                'check':this.activos[id_permiso],
            });

            this.activos[id_permiso] = Boolean(check.data);
        }
    }
    
}
</script>