<template>
    <div class="container">
        <div class="card">
            <div class="card-body">
                <div class="container-fluid container_perfil">
                    <div class="col text-center">
                        <img @click="show_cargar_imagen = true" :src="(img_perfil)?img_perfil:'dott.jpg'" class="imgRedonda shadow mb-3" />
                        <!-- <ImageUpload ref="ref_file"></ImageUpload> -->
                    </div>
                </div>  

                <div class="form-group row">
                    <label for="nombre" class="col-lg-3 control-label text-primary">Nombre:</label>
                    <div class="col-lg-9">
                        <input @change="updateInfoUsuario" v-model="form_usuario.nombre" v-uppercase type="text" class="form-control text-center">
                    </div>
                </div>

                <div class="form-group row">
                    <label for="apellido_paterno" class="col-lg-3 control-label text-primary">Apellido paterno:</label>
                    <div class="col-lg-9">
                        <input @change="updateInfoUsuario" v-uppercase v-model="form_usuario.apellido_paterno" type="text" class="form-control text-center">
                    </div>
                </div>

                <div class="form-group row">
                    <label for="apellido_materno" class="col-lg-3 control-label text-primary">Apellido materno:</label>
                    <div class="col-lg-9">
                        <input @change="updateInfoUsuario" v-uppercase v-model="form_usuario.apellido_materno" type="text" class="form-control text-center">
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-lg-3 control-label text-primary">Es medico:</label>
                    <div class="col-lg-9">
                        <label for="nombre" class="col text-primary"><toggle-button @change="updateInfoUsuario" color="#43aee6" :sync="true" v-model="es_medico" :labels="{ checked: 'SI', unchecked: 'NO' }"/></label>
                    </div>
                </div>

                <div v-if="!es_medico" class="form-group row">
                    <label for="rol" class="col-lg-3 control-label text-primary">Rol:</label>
                    <div class="col-lg-9">
                        <input @change="updateInfoUsuario" v-uppercase v-model="form_usuario.rol" type="text" class="form-control text-center">
                    </div>
                </div>

                <div v-else class="form-group row">
                    <label for="cedula" class="col-lg-3 control-label text-primary">Cédula profesional:</label>
                    <div class="col-lg-9">
                        <input @change="updateInfoUsuario" v-uppercase v-model="form_usuario.cedula" type="text" class="form-control text-center">
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-lg-3 control-label text-primary">Sexo:</label>
                    <div class="col-lg-9">
                        <div class="form-check-inline">
                            <label class="form-check-label text-primary">
                                <input @change="updateInfoUsuario" v-model="form_usuario.sexo" type="radio" class="form-check-input" value="M" />Masculino
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label class="form-check-label text-primary">
                                <input @change="updateInfoUsuario" v-model="form_usuario.sexo" type="radio" class="form-check-input" value="F" />Femenino
                            </label>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-lg-3 control-label text-primary">Fecha nacimiento:</label>
                    <div class="col-lg-9">
                    <date-picker @change="updateInfoUsuario" v-model="form_usuario.fecha_nacimiento" id="fecha_usuario" placeholder="Seleccione una fecha" valueType="format"></date-picker>
                    <!-- <b-form-datepicker v-model="form_usuario.fecha_nacimiento" class="form-control" id="fecha_consulta" placeholder="Seleccione una fecha"></b-form-datepicker> -->
                    </div>
                </div>

                <div class="form-group row">
                    <label for="telefono" class="col-lg-3 control-label text-primary">Teléfono móvil:</label>
                    <div class="col-lg-9">
                        <input @change="updateInfoUsuario" v-uppercase type="text" v-model="form_usuario.telefono" class="form-control text-center">
                    </div>
                </div>

                <div class="form-group row">
                    <label for="direccion" class="col-lg-3 control-label text-primary">Dirección:</label>
                    <div class="col-lg-9">
                        <input @change="updateInfoUsuario" v-uppercase type="text" v-model="form_usuario.direccion" class="form-control text-center">
                    </div>
                </div>

                <div class="form-group row">
                    <label for="email" class="col-lg-3 control-label text-primary">Correo electrónico:</label>
                    <div class="col-lg-9">
                        <input @change="updateInfoUsuario" v-uppercase type="text" v-model="form_usuario.email" class="form-control text-center">
                    </div>
                </div>

                <div class="form-group row">
                    <label for="universidad" class="col-lg-3 control-label text-primary">Institución/Universidad:</label>
                    <div class="col-lg-9">
                        <input name="universidad" id="universidad" @change="updateInfoUsuario" v-uppercase type="text" v-model="form_usuario.universidad" class="form-control text-center">
                    </div>
                </div>

                <div class="form-group row">
                    <label for="id_especialidad" class="col-lg-3 control-label text-primary">Especialidad:</label>
                    <div class="col-lg-9">
                        <v-select @input="updateInfoUsuario" :reduce="nombre => nombre.id_especialidad" :options="especialidades" v-model="form_usuario.id_especialidad" label="nombre"/>
                    </div>
                </div>

                <div class="form-group row">
                    <label for="id_especialidad" class="col-lg-3 control-label text-primary">Escuela:</label>
                    <div class="col-lg-9">
                        <v-select @input="updateInfoUsuario" :reduce="nombre => nombre.id_logotipo" :options="logotipos" v-model="form_usuario.id_logotipo" label="nombre">
                            <template v-slot:option="option">
                                <img width="100" height="100" :src="option.url_imagen" :alt="option.nombre">
                                {{ option.nombre }}
                            </template>
                        </v-select>
                    </div>
                </div>

                <!-- <div class="form-group row">
                    <label class="col-lg-3 control-label text-primary">Logo:</label>
                    <div class="col-lg-9">
                        <div class="form-check-inline">
                            <label class="form-check-label text-primary">
                                <input v-model="form_usuario.escuela" type="radio" class="form-check-input" value="udg" />UDG
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label class="form-check-label text-primary">
                                <input v-model="form_usuario.escuela" type="radio" class="form-check-input" value="umsnh" />HMSNH
                            </label>
                        </div>
                    </div>
                </div> -->

                <!-- <div class="container-fluid mt-3">
                    <div class="align-items-center">
                        <div class="text-center">
                            <button @click="cambioContrasena" class="btn btn-warning btn-lg"> Cambiar contraseña</button>
                        </div> -->

                        <!-- <div v-else class="text-center">
                            <button class="btn btn-primary btn-lg btn-block" type="button" disabled>
                            <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                            Cargando...
                            </button>
                        </div> -->
                    <!-- </div>
                </div> -->

                <!-- <div class="container-fluid mt-3">
                    <div class="align-items-center">
                        <div v-if="!cargando" class="text-center">
                            <button @click="updateInfoUsuario" class="btn btn-primary  btn-lg btn-block"> Actualizar información </button>
                        </div>

                        <div v-else class="text-center">
                            <button class="btn btn-primary btn-lg btn-block" type="button" disabled>
                            <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                            Cargando...
                            </button>
                        </div>
                    </div>
                </div> --> 
            </div>
        </div>


        <b-modal v-model="hahahaha" id="id_cambio_contrasena" title="Cambio de contraseña">
            <CambioContrasenaOtro></CambioContrasenaOtro>
        </b-modal> 

        <b-modal v-model="show_cargar_imagen" size="xl" id="id_cargar_imagen" title="Cargar Imagen">
                <ImageUpload ref="ref_file"></ImageUpload>
                <Button v-if="!showLoading" @click="guardarImagenPerfil" class="mt-3 btn btn-primary">Guardar Imagen</Button>
                <Button v-if="!showLoading" @click="cancelarImagenPerfil" class="ml-3 mt-3 btn btn-warning">Cancelar</Button>
        </b-modal>
    </div>
</template>

<script>
import api from "../config.js";
import ImageUpload from '../components/dragAndDrop/ImageUpload.vue';
import CambioContrasenaOtro from '../components/usuario/CambioContraseña.vue';

export default {
    name: 'PerfilUsuario',

    props: ['id_usuario'],

    components: {
        ImageUpload,
        CambioContrasenaOtro,
    },

    data: () => ({
        modal_cambio_contrasena: false,
        showLoading:false,
        hahahaha:false,
        show_cargar_imagen: false,
        cargando: false,
        es_medico: false,
        especialidades: [],
        logotipos: [],
        img_perfil: null,
        form_usuario: {
            nombre: "",
            email: "",
            apellido_paterno: "",
            apellido_materno: "",
            cedula: "",
            escuela: "",
            sexo: "",
            fecha_nacimiento: "",
            direccion: "",
            universidad: "",
            id_especialidad: "",
            es_medico: false,
            rol: "",
            id_logotipo: ""
        }
  }),

  created: async function() {
      await this.getInfoUsuario();
  },

  methods: {
        cambioContrasena(){
            this.hahahaha = true;
        },
        async guardarImagenPerfil(){
            this.showLoading = true;
            const formData = new FormData();
            var file = this.$refs.ref_file.getFile();

            if(!file){
                this.$toast.error("Favor de seleccionar una imagen", {
                    dismissible: true,
                    position: "top"
                });
                this.showLoading = false;
                return;
            }

            formData.append('image', file, file.name);

            if(this.id_usuario){
                formData.append('id_usuario', this.id_usuario);
            }

            var datos = await api.post("api/set_img_perfil", formData);

            if(datos.data.tipo == 'Exito'){
                this.$router.go(0);
            }else{
                this.$toast.error("Ocurrio un error vuelve a intentarlo", {
                    dismissible: true,
                    position: "top"
                });
            }

            this.showLoading = false;
        },
        async cancelarImagenPerfil(){
            this.$refs.ref_file.cleanFile();
            this.show_cargar_imagen = false;
        },
        async getInfoUsuario(){
            var datos = null;
            if(this.id_usuario){
                datos = await api.get("api/get_usuario?id_usuario=" + this.id_usuario);
            }else{
                datos = await api.get("api/get_usuario");
            }

            var user = datos.data.usuario;
            this.especialidades = datos.data.especialidades;

            this.logotipos = datos.data.logotipos;

            (user.es_medico == '1')?this.es_medico = true : this.es_medico = false;

            this.form_usuario = user;

            if(user.url_img){
                this.img_perfil = user.url_img;
            }
        },
        async updateInfoUsuario(){
            this.cargando = true;
            (this.es_medico)?this.form_usuario.es_medico = '1' : this.form_usuario.es_medico = '0';

            if(this.id_usuario){
                this.form_usuario.id_usuario = this.id_usuario;
            }

            this.form_usuario.nombre             = this.form_usuario.nombre.toUpperCase();
            this.form_usuario.email              = this.form_usuario.email.toUpperCase();
            this.form_usuario.apellido_paterno   = this.form_usuario.apellido_paterno.toUpperCase();
            this.form_usuario.apellido_materno   = (this.form_usuario.apellido_materno)?this.form_usuario.apellido_materno.toUpperCase():null;
            this.form_usuario.cedula             = (this.form_usuario.cedula)?this.form_usuario.cedula.toUpperCase():null;
            this.form_usuario.direccion          = (this.form_usuario.direccion)?this.form_usuario.direccion.toUpperCase():null;
            this.form_usuario.universidad        = (this.form_usuario.universidad)?this.form_usuario.universidad.toUpperCase():null;
            this.form_usuario.rol                = (this.form_usuario.rol)?this.form_usuario.rol.toUpperCase():null;

            var user = await api.post("api/update_usuario",this.form_usuario);

            (user.data.es_medico == '1')?this.es_medico = true : this.es_medico = false;
            
            this.form_usuario = user.data;

            this.$toast.success("Datos actualizados correctamente", {
                dismissible: true,
                position: "top"
            });

            this.cargando = false;
        },
    }
}
</script>

<style>
    .imgRedonda {
        width:150px;
        height:150px;
        border-radius:150px;
        border:1px solid #666;
    }

    .imgRedonda:hover{
        cursor: pointer;
    }
</style>