<template>
    <div class="container">
        <section class="row">
            <div class="card col-md-2">
                <div class="card-body">
                    <div class="form-group row justify-content-center" v-for="opcion in opciones" :key="opcion.clave">
                        <div v-if="campos_cargando[opcion.clave] == true" class="spinner-grow text-primary"></div>
                        <label v-else class="col-lg-12 btn btn-info btn-sm" :class="{ active: boton_activo == opcion.clave }" @click="cambioBoton(opcion.clave)"> {{ opcion.label }} </label>
                    </div>
                </div>
            </div>
            <div class="card col">
                <Hereditarios ref="ref_hereditarios_familiares" :data_hereditarios="data" v-if="boton_activo == 'hereditarios_familiares'"></Hereditarios>
                <PersonalesPatologicos ref="ref_personales_patologicos" :data_personales="data" v-if="boton_activo == 'personales_patologicos'"></PersonalesPatologicos>
                <AparatosSistemas ref="ref_aparatos_sistemas" :data_aparato_sistemas="data" v-if="boton_activo == 'aparatos_sistemas'"></AparatosSistemas>
                <Alergias :id_paciente="id_paciente" ref="ref_alergias" :data_alergias="data" v-if="boton_activo == 'alergias'"></Alergias>
                <Padres ref="ref_padres" :data_padres="data" v-if="boton_activo == 'padres'"></Padres>
                <Perinatales ref="ref_perinatales" :data_perinatales="data" v-if="boton_activo == 'perinatales'"></Perinatales>
                <Psicomotor ref="ref_psicomotor" :data_psicomotor="data" v-if="boton_activo == 'psicomotor'"></Psicomotor>
                <Menstruaciones ref="ref_menstruaciones" :data_menstruacion="data" v-if="boton_activo == 'menstruaciones'"></Menstruaciones>
                <Embarazos ref="ref_embarazos" :data_embarazo="data" v-if="boton_activo == 'embarazos'"></Embarazos>
                <OtrosGinecologicos ref="ref_otros_ginecologicos" :data_otros_ginecologicos="data" v-if="boton_activo == 'otros_ginecologicos'"></OtrosGinecologicos>

                <EnfermedadCIE @eliminarEnfermedad="eliminarEnfermedad" @getListadoEnfermedades="getListadoEnfermedades" :data_enfermedades="data.cie" v-if="boton_activo == 'enfermedades_cie10'"></EnfermedadCIE>
            
                <div class="container-fluid mt-3 pb-4">
                    <div class="align-items-center row">
                        <div v-if="boton_activo != 'solicitud_estudios'" class="text-center col-lg-4">
                            <button v-if="anterior_activo" class="btn btn-primary  btn-lg btn-block" @click="Anterior">Anterior</button>
                            <div v-else class="spinner-grow text-primary"></div>
                        </div>

                        <div class="text-center col-lg-4">
                            <button v-if="siguiente_activo" class="btn btn-success  btn-lg btn-block" @click="Continuar">Siguiente</button>
                            <div v-else class="spinner-grow text-primary"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

         <Loading :show="showLoading"></Loading>

         <b-modal v-model="show_listado" size="xl" id="listado_enfermedades_a" title="Enfermedades">
            <ListadoEnfermedadesCIE @addEnfermedad="addEnfermedad" :listado_enfermedades="listado_enfermedades"></ListadoEnfermedadesCIE>
        </b-modal>
    </div>
</template>


<script>
const $ = require("jquery");

import api from "../config.js";

import Loading from "../components/Loading.vue";
import Hereditarios from "../components/antecedentes/Hereditarios.vue";
import PersonalesPatologicos from "../components/antecedentes/PersonalesPatologicos.vue";
import AparatosSistemas from "../components/antecedentes/AparatosSistemas.vue";
import Padres from "../components/antecedentes/Padres.vue";
import Perinatales from "../components/antecedentes/Perinatales.vue";
import Psicomotor from "../components/antecedentes/Psicomotor.vue";
import Menstruaciones from "../components/antecedentes/Menstruaciones.vue";
import Embarazos from "../components/antecedentes/Embarazos.vue";
import OtrosGinecologicos from "../components/antecedentes/OtrosGinecologicos.vue";
import EnfermedadCIE from "../components/antecedentes/EnfermedadCIE.vue";
import Alergias from "../components/antecedentes/Alergias.vue";

import ListadoEnfermedadesCIE from "../components/antecedentes/listado/ListadoEnfermedadesCIE.vue"


export default{
    name: "Antecedentes",

    props: ['id_paciente'],

    components:{
        Loading,
        Hereditarios,
        PersonalesPatologicos,
        AparatosSistemas,
        Padres,
        Perinatales,
        Psicomotor,
        Menstruaciones,
        Embarazos,
        OtrosGinecologicos,
        EnfermedadCIE,
        ListadoEnfermedadesCIE,
        Alergias
    },

    data: () => ({
        sexo:null,
        edad:null,
        siguiente_activo: true,
        anterior_activo: true,
        arreglo_campos: ['hereditarios_familiares', 
                        'personales_patologicos', 
                        'enfermedades_cie10', 
                        'aparatos_sistemas', 
                        'alergias',
                        // 'vacunas', 
                        // 'padres', 
                        // 'perinatales', 
                        // 'psicomotor', 
                        // 'menstruaciones',
                        // 'embarazos', 
                        // 'otros_ginecologicos'
                        ],
        campos_cargando:[],
        listado_enfermedades: null,
        show_listado: false,
        showLoading: false,
        boton_activo: '',
        data: '',
        opciones:[
            {clave:'hereditarios_familiares', label:'Hereditarios familiares'},
            {clave:'personales_patologicos', label:'Personales patológicos'},
            {clave:'enfermedades_cie10', label:'Enfermedades CIE11'},
            {clave:'aparatos_sistemas', label:'Aparatos y sistemas'},
            {clave:'alergias', label:'Alergias'},
            // {clave:'vacunas', label:'Vacunas'},
            // {clave:'padres', label:'Padres'},
            // {clave:'perinatales', label:'Perinatales'},
            // {clave:'psicomotor', label:'Psicomotor'},
            // {clave:'menstruaciones', label:'Menstruaciones'},
            // {clave:'embarazos', label:'Embarazos'},
            // {clave:'otros_ginecologicos', label:'Otros ginecologicos'}
        ],
    }),

    created: async function(){
        this.showLoading = true;
        await this.getAntecedentes();
        this.boton_activo = 'hereditarios_familiares';
        this.showLoading = false;

        if(this.edad < 18 && this.sexo == 'M'){
            this.opciones.push({clave:'padres', label:'Padres'});
            this.opciones.push({clave:'perinatales', label:'Perinatales'});
            this.opciones.push({clave:'psicomotor', label:'Psicomotor'});

            this.arreglo_campos.push('padres');
            this.arreglo_campos.push('perinatales');
            this.arreglo_campos.push('psicomotor');
        }else if(this.edad < 18 && this.sexo == 'F'){
            this.opciones.push({clave:'padres', label:'Padres'});
            this.opciones.push({clave:'perinatales', label:'Perinatales'});
            this.opciones.push({clave:'psicomotor', label:'Psicomotor'});
            this.opciones.push({clave:'menstruaciones', label:'Menstruaciones'});
            this.opciones.push({clave:'embarazos', label:'Embarazos'});
            this.opciones.push({clave:'otros_ginecologicos', label:'Ginecológicos'});

            this.arreglo_campos.push('padres');
            this.arreglo_campos.push('perinatales');
            this.arreglo_campos.push('psicomotor');
            this.arreglo_campos.push('menstruaciones');
            this.arreglo_campos.push('embarazos');
            this.arreglo_campos.push('otros_ginecologicos');
        }else if(this.edad >= 18 && this.sexo == 'F'){
            this.opciones.push({clave:'menstruaciones', label:'Menstruaciones'});
            this.opciones.push({clave:'embarazos', label:'Embarazos'});
            this.opciones.push({clave:'otros_ginecologicos', label:'Ginecológicos'});

            this.arreglo_campos.push('menstruaciones');
            this.arreglo_campos.push('embarazos');
            this.arreglo_campos.push('otros_ginecologicos');
        }
    },

    methods:{
        calcularEdad(fecha) {
            var hoy = new Date();
            var cumpleanos = new Date(fecha);
            var edad = hoy.getFullYear() - cumpleanos.getFullYear();
            var m = hoy.getMonth() - cumpleanos.getMonth();

            if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
                edad--;
            }

            return edad;
        },
        cambioBoton(key){
            if(this.boton_activo == key){
                //FIXME ver porque funciona solamente con el splice
                this.campos_cargando.splice( -1, 1 );
            }
            this.Continuar(false);
            this.boton_activo = key;
        },

        async getAntecedentes(){
            var antecedentes = await api.get("api/antecedentes/" + this.id_paciente);
            this.data = antecedentes.data;
            this.sexo = this.data.sexo;
            this.edad = this.calcularEdad(this.data.fecha_nacimiento);
        },

        actualizarRegistro(registros, btn_aux){
            api.post("api/set_antecedentes/" + this.id_paciente, registros).then(respuesta => {
                if(respuesta.data.tipo == 'Exito'){
                    this.data = respuesta.data.antecedentes;

                    this.$toast.success("Datos actualizados correctamente", {
                        dismissible: true,
                        position: "top"
                    });
                }else{
                    this.$toast.error("Ocurrio un error vuelve a intentarlo", {
                        dismissible: true,
                        position: "top"
                    });
                }

                this.siguiente_activo = true;
                this.anterior_activo = true;

                this.campos_cargando[btn_aux] = false;
                this.campos_cargando.splice( -1, 1 );
            });
        },

        async addEnfermedad(nombre, clave) {
            var result = await api.post("api/add_enfermedad/" + this.id_paciente,{ 'descripcion': nombre, 'codigo': clave});
            this.data = result.data.antecedentes;
            this.show_listado = false;
        },

        irArriba(){
            $('body,html').animate({ scrollTop:'1px' },500);
        },

        async getListadoEnfermedades(enfermedad_buscar) {
            this.showLoading = true;
            var listado = await api.post("api/getEnfermedad",{ 'enfermedad_buscar': enfermedad_buscar});
            this.listado_enfermedades = listado.data;
            this.showLoading = false;
            this.show_listado = true;
        },

        async eliminarEnfermedad(id) {
            var result = await api.post("api/eliminar_enfermedad/" + this.id_paciente,{ 'id_enfermedad_cie_to_paciente': id});
            this.data = result.data.antecedentes;
        },

        Anterior(){
            this.Continuar(true, false);
        },

        Continuar(next = true, siguiente = true){

            if(next){
                if(siguiente){
                    this.siguiente_activo = false;
                }else{
                    this.anterior_activo = false;
                }
            }


            var registros = null;
            this.irArriba();
            this.campos_cargando[this.boton_activo] = true;

            switch(this.boton_activo){
                case 'hereditarios_familiares':
                    registros = this.$refs.ref_hereditarios_familiares.getInfo();
                    break;

                case 'personales_patologicos':
                    registros = this.$refs.ref_personales_patologicos.getInfo();
                    break;

                case 'aparatos_sistemas':
                    registros = this.$refs.ref_aparatos_sistemas.getInfo();
                    break;

                case 'alergias':
                    registros = this.$refs.ref_alergias.getInfo();
                    break; 

                /* case 'vacunas':
                    registros = this.$refs.ref_vacunas.getInfo();
                    break; */

                case 'padres':
                    registros = this.$refs.ref_padres.getInfo();
                    break;

                case 'perinatales':
                    registros = this.$refs.ref_perinatales.getInfo();
                    break;

                case 'psicomotor':
                    registros = this.$refs.ref_psicomotor.getInfo();
                    break;

                case 'menstruaciones':
                    registros = this.$refs.ref_menstruaciones.getInfo();
                    break;

                case 'embarazos':
                    registros = this.$refs.ref_embarazos.getInfo();
                    break;

                case 'otros_ginecologicos':
                    registros = this.$refs.ref_otros_ginecologicos.getInfo();
                    break;

                default:
                    this.campos_cargando[this.boton_activo] = false;
                    this.campos_cargando.splice( -1, 1 );

                    this.siguiente_activo = true;
                    this.anterior_activo = true;
                    break;
            }

            if(registros){
                this.actualizarRegistro(registros, this.boton_activo);
            }

            if(next){
                if(siguiente){
                    this.next();
                }else{
                    this.anterior();
                }
            }
        },
        next(){
            var next = (this.arreglo_campos.indexOf(this.boton_activo)) + 1;
            if(next < this.arreglo_campos.length){
                this.boton_activo = this.opciones[next].clave;
            }else{
                this.boton_activo = this.opciones[0].clave;
            }
        },

        anterior(){
            var next = (this.arreglo_campos.indexOf(this.boton_activo)) - 1;
            if(next >= 0){
                this.boton_activo = this.opciones[next].clave;
            }else{
                this.boton_activo = this.opciones[(this.arreglo_campos.length - 1)].clave;
            }
        },



    }
}
</script>