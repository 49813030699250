<template>
    <div class="card-body">
        <div v-if="!campo_informativo" class="form-group row col-lg-12">
            <div class="col-lg-12">
                <span @click="show_listado_estudios = true" class="btn btn-outline-success btn-sm" style="font-size:20px">ESTUDIOS  <i class="fas fa-search"></i></span>
            </div>
        </div>

        <table cellpadding="0" cellspacing="0" class="tabla_diagnostico">
            <thead>
                <tr>
                    <th style="width:32%">NOMBRE DEL ESTUDIO CLÍNICO</th>
                    <th>NOTAS</th>
                    <th v-if="mostrarSubirArchivo()">ARCHIVOS</th>
                    <th v-if="!campo_informativo && mostrarSubirArchivo()" style="width:5%">SUBIR ARCHIVOS</th>
                    <th v-if="!campo_informativo" style="width:5%"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(lista, lista_index) in listado_estudio" :key="lista_index" :class="{par_principal:lista_index%2 == 0, inpar_principal:lista_index%2 != 0}">
                    <td style="font-size:13px; padding:10px">{{ lista.nombre | uppercase }}</td>
                    <td>
                        <textarea class="form-control" v-uppercase v-if="!campo_informativo" v-model="lista.notas" style="width:100%; font-size:13px; padding:10px" @change="updateNota(lista.id_estudio_to_consulta, lista.notas)"></textarea>
                        <div style="font-size:13px; padding:10px; white-space: break-spaces" v-else>{{ lista.notas | uppercase }}</div>
                    </td>
                    <td v-if="mostrarSubirArchivo()" style="max-width: 10px">
                        <div class="form-group col-lg-12 pt-1 row" v-for="(lista_estudi, lista_estudios_index) in lista.archivos" :key="lista_estudios_index">
                            <a target="_blank" class="col-lg-10 btn btn-primary btn-sm btn-block" :href="lista.ruta + lista_estudi">{{ lista_estudi.substr(lista_estudi.lastIndexOf("/") + 1) }}</a>
                            <button v-if="!campo_informativo" @click="eliminarArchivo(lista_estudi.substr(lista_estudi.lastIndexOf('/') + 1), lista.id_estudio_to_consulta)" class="col-lg-2 btn btn-danger btn-sm">X</button>
                        </div>
                    </td>
                    <td v-if="!campo_informativo && mostrarSubirArchivo()">
                        <ArchivosUpload @refreshArchivos="refreshArchivos" :id_paciente="id_paciente" :id_consulta="id_consulta" :id_estudio_to_consulta="lista.id_estudio_to_consulta"></ArchivosUpload>
                    </td>
                    <td>
                        <button v-if="!campo_informativo" @click="eliminarEstudio(lista.id_estudio_to_consulta)" type="button" class="btn btn-danger btn-sm ml-2 mr-2">X</button>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="form-group col-lg-12">
            <label for="padre" class="col-lg-12 control-label text-primary">INGRESAR NOTAS GENERALES DE LOS ESTUDIOS (APARECEN EN LA RECETA)</label>
            <div class="col-lg-12">
                <textarea class="form-control" v-uppercase v-if="!campo_informativo" v-model="datos.notas_estudios" style="width:100%"></textarea>
                <div class="border border-primary" style="width:100%; font-size:13px; padding:10px" v-else>{{ datos.notas_estudios | uppercase }}</div>
            </div>
        </div>

        <b-modal v-model="show_listado_estudios" size="xl" id="listado_estudios" title="Catálogo de estudios clinicos">
            <ListadoEstudios @addEstudio="addEstudio"></ListadoEstudios>
        </b-modal>
        
        <Loading :show="eliminando"></Loading>
    </div>
</template>

<script>
import api from "../../config.js";

import ListadoEstudios from "../ListadoEstudios.vue";
import ArchivosUpload from "../dragAndDrop/ArchivosUpload.vue";
import Loading from "../Loading.vue";

export default {
    name: 'SolicitudEstudios',

    components:{
        ListadoEstudios,
        ArchivosUpload,
        Loading
    },

    props: ['estudios', 'id_consulta', 'campo_informativo', 'id_paciente', 'motivo'],

    data: () => ({
        eliminando: false,
        show_listado_estudios: false,
        listado_estudio:[],
        datos:{
            notas_estudios:'',
        },
    }),

    created: function(){
        if(this.estudios){
            this.datos.notas_estudios = this.estudios.notas_estudios;
            this.estatus              = this.estudios.estatus;
        }
        this.getListado();
    },

    methods:{
        async eliminarArchivo(nombre, id_estudio_to_consulta){
            this.eliminando = true;
            if(confirm('Está seguro de eliminar el archivo: ' + nombre)){
                var listado = await api.post("api/delete_archivo",{'nombre': nombre,
                                                                'id_estudio_to_consulta': id_estudio_to_consulta,
                                                                'id_consulta': this.id_consulta,
                                                                'id_paciente': this.id_paciente});
                this.listado_estudio = listado.data;
            }
            this.eliminando = false;
        },
        getInfo(){
            if(this.datos.notas_estudios){
                this.datos.notas_estudios = this.datos.notas_estudios.toUpperCase();
            }
            
            return this.datos;
        },
        async getListado(){
            var listado = await api.get("api/listado_estudio_to_consulta/" + this.id_consulta);
            this.listado_estudio = listado.data;
        },
        async addEstudio(id_estudio){
            var listado =  await api.post("api/add_estudio_to_consulta",{
                'id_estudio': id_estudio,
                'id_consulta': this.id_consulta
            });
            this.listado_estudio = listado.data;
        },
        async eliminarEstudio(id_estudio_to_consulta){
            if(confirm('Está seguro de eliminar el estudio')){
                var listado = await api.post("api/eliminar_estudio_to_consulta",{'id_estudio_to_consulta':id_estudio_to_consulta,
                                                                                'id_consulta':this.id_consulta});
                this.listado_estudio = listado.data;
            }
        },

        /* async  */updateNota(id_estudio_to_consulta, nota){
            if(nota){
                nota = nota.toUpperCase();
            }
            /* var listado = await */ api.post("api/update_nota",{'id_estudio_to_consulta':id_estudio_to_consulta,
                                                            'id_consulta':this.id_consulta,
                                                            'nota': nota});
            //this.listado_estudio = listado.data;
        },

        refreshArchivos(datos){
            this.listado_estudio = datos.data;
            //FIXME ver porque funciona solamente con el splice
            this.listado_estudio.splice(0, 0 );
        },

        mostrarSubirArchivo(){
            var valor = false;
            switch(this.motivo.clave){
                case 'NOTIN':
                case 'ESTD':
                    valor = true;
                    break;
                
                default:
                   valor = false;
                   break; 
            }

            return valor;
        }
    }
}
</script>