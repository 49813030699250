<template>
    <div class="container1">
        <ul class="nav nav-tabs" id="myTab" role="tablist">

            <li class="nav-item">
                <button role="tab" class="btn btn-outline-secondary" :class="{ active: (tab == 'peso') }" @click="cambioPestana('peso')">
                    Peso para la edad
                </button>
            </li>

            <li class="nav-item">
                <button role="tab" class="btn btn-outline-secondary" :class="{ active: (tab == 'altura') }" @click="cambioPestana('altura')">
                    Talla para la edad
                </button>
            </li>
        </ul>

        <section>
            <div class="card card-body">
                <div class="container-fluid">
                    <div class="col-lg-12 row">
                        <label for="nombre" class="col control-label text-center">Niños<toggle-button @change="cambioSexoNino" v-model="nino" :sync="true" color="#43aee6"/></label>
                        <label for="nombre" class="col control-label text-center">Niñas<toggle-button @change="cambioSexoNina" v-model="nina" :sync="true" color="#fca9f9"/></label>
                    </div>
                </div>  

                <div class="container-fluid">
                    <div class="row col">
                        <div class="mr-2">
                            <label class="col text-center">Semanas:</label>
                            <input class="form-control" v-model="datos.semanas">
                        </div>
                        <div class="mr-2">
                            <label class="col text-center">L:</label>
                            <input class="form-control" v-model="datos.ele">
                        </div>
                        <div class="mr-2">
                            <label class="col text-center">M:</label>
                            <input class="form-control" v-model="datos.eme">
                        </div>
                        <div class="mr-2">
                            <label class="col text-center">S:</label>
                            <input class="form-control" v-model="datos.ese">
                        </div>
                        <div class="mr-2">
                            <label class="col text-center">SD:</label>
                            <input class="form-control" v-model="datos.ese_de">
                        </div>
                        <div class="mr-2">
                            <label class="col text-center">1st:</label>
                            <input class="form-control" v-model="datos.uno_st">
                        </div>
                        <div class="mr-2">
                            <label class="col text-center">3rd:</label>
                            <input class="form-control" v-model="datos.tres_rd">
                        </div>
                        <div class="mr-2">
                            <label class="col text-center">5th:</label>
                            <input class="form-control" v-model="datos.cinco_th">
                        </div>
                        <div class="mr-2 mt-3">
                            <label class="col text-center">15th:</label>
                            <input class="form-control" v-model="datos.quince_th">
                        </div>
                        <div class="mr-2 mt-3">
                            <label class="col text-center">25th:</label>
                            <input class="form-control" v-model="datos.veinticinco_th">
                        </div>
                        <div class="mr-2 mt-3">
                            <label class="col text-center">50th:</label>
                            <input class="form-control" v-model="datos.cincuenta_th">
                        </div>
                        <div class="mr-2 mt-3">
                            <label class="col text-center">75th:</label>
                            <input class="form-control" v-model="datos.setenta_y_cinco_th">
                        </div>
                        <div class="mr-2 mt-3">
                            <label class="col text-center">85th:</label>
                            <input class="form-control" v-model="datos.ochenta_y_cinco_th">
                        </div>
                        <div class="mr-2 mt-3">
                            <label class="col text-center">95th:</label>
                            <input class="form-control" v-model="datos.noventa_y_cinco_th">
                        </div>
                        <div class="mr-2 mt-3">
                            <label class="col text-center">97th:</label>
                            <input class="form-control" v-model="datos.noventa_y_ciente_th">
                        </div>
                        <div class="mr-2 mt-3">
                            <label class="col text-center">99th:</label>
                            <input class="form-control" v-model="datos.noventa_y_nueve_th">
                        </div>
                    </div>
                </div>

                <div class="container-fluid mt-3">
                    <div class="col-lg-12 row">
                        <button v-if="!datos.id_peso_altura_edad" @click="agregarParametro" class="col btn btn-primary text-center">Agregar</button>
                        <button v-else @click="editarParametro" class="col btn btn-success text-center">Editar</button>
                    </div>
                </div>
            </div>
            <div class="card card-body">

                <b-row class="my-1">
                    <b-col sm="2" class="ml-3 pt-2">
                        <label for="input-none">Buscar (Semanas):</label>
                    </b-col>
                    <b-col sm="9">
                        <b-form-input v-model="keyword" type="search" id="filterInput" placeholder="Buscar" ></b-form-input>
                    </b-col>
                </b-row>

                <b-table :thead-class="{azulColor: nino,  rosaColor: nina}" :keyword="keyword" :items="items" :bordered="false" :fields="fields" striped hover outlined responsive>
                    <template #cell(opciones)="row">
                        <div class="btn btn-warning mr-1 btn-sm" @click="selectEditarParametro(row.item)">Editar</div>
                        <div class="btn btn-danger mr-1 btn-sm" @click="selectEliminarParametro(row.item.id_peso_altura_edad, row.item.semanas)">Eliminar</div>
                    </template>
                </b-table>

                 <div class="ml-3">
                    <b-pagination :per-page="perPage" v-model="currentPage" pills :total-rows="rows"></b-pagination>
                </div>
            </div>
        </section>

        <b-overlay no-wrap fixed :show="show_eliminar_percentil" rounded="sm">
            <template #overlay>
                <div>
                <p><strong id="form-confirm-label">¿Estás seguro de eliminar el percentil de la semana {{ semana_eliminar }}?</strong></p>
                <div>
                    <b-button variant="outline-danger" class="mr-3" @click="btnCancelar">
                        NO
                    </b-button>
                    <b-button variant="outline-success" @click="btnAceptar">SI</b-button>
                </div>
                </div>
            </template>
        </b-overlay>
    </div>
</template>

<script>

import api from "../../config.js";

export default {
    name: 'Percentiles',

    data: () => ({
        show_eliminar_percentil: false,
        datos:{
            id_peso_altura_edad: null,
            semanas: null,
            ele: null,
            eme: null,
            ese: null,
            ese_de: null,
            uno_st: null,
            tres_rd: null,
            cinco_th: null,
            quince_th: null,
            veinticinco_th: null,
            cincuenta_th: null,
            setenta_y_cinco_th: null,
            ochenta_y_cinco_th: null,
            noventa_y_cinco_th: null,
            noventa_y_ciente_th: null,
            noventa_y_nueve_th: null,
            sexo: null,
            tipo: null
        },
        id_eliminar: null,
        semana_eliminar: null,
        keyword: '',
        tab: 'peso',
        nino: true,
        nina: false,
        listado_parametros: [],
        currentPage: 1,
        perPage: 30,
        fields: [
            {
                key: "semanas",
                label: "Semanas",
                sortable: true
            },
            {
                key: "ele",
                label: "L",
                sortable: true
            },
            {
                key: "eme",
                label: "M",
                sortable: true
            },
            {
                key: "ese",
                label: "S",
                sortable: true
            },
            {
                key: "ese_de",
                label: "SD",
                sortable: true
            },
            {
                key: "uno_st",
                label: "1st",
                sortable: true
            },
            {
                key: "tres_rd",
                label: "3rd",
                sortable: true
            },
            {
                key: "cinco_th",
                label: "5th",
                sortable: true
            },
            {
                key: "quince_th",
                label: "15th",
                sortable: true
            },
            {
                key: "veinticinco_th",
                label: "25th",
                sortable: true
            },
            {
                key: "cincuenta_th",
                label: "50th",
                sortable: true
            },
            {
                key: "setenta_y_cinco_th",
                label: "75th",
                sortable: true
            },
            {
                key: "ochenta_y_cinco_th",
                label: "85th",
                sortable: true
            },
            {
                key: "noventa_y_cinco_th",
                label: "95th",
                sortable: true
            },
            {
                key: "noventa_y_ciente_th",
                label: "97th",
                sortable: true
            },
            {
                key: "noventa_y_nueve_th",
                label: "99th",
                sortable: true
            },
            {
                key: "opciones",
                label: "",
                sortable: false
            },

        ],
    }),

    created: async function(){
        this.getListado();
    },

    methods: {
        btnCancelar(){
            this.show_eliminar_percentil = false;
            this.semana_eliminar = null;
            this.id_eliminar = null;
        },
        async btnAceptar(){
            var listado = await api.post("api/eliminar_parametro/" + this.id_eliminar);
            this.listado_parametros = listado.data.listado;

            this.$toast.success("Se ha eliminado el percentil", {
                dismissible: true,
                position: "top"
            });

            this.btnCancelar();
        },
        limpiarForm(){
            for(const propiedad in this.datos){
                this.datos[propiedad] = null;
            }  
        },
        async editarParametro(){
            var exito = true;
            for(const propiedad in this.datos){
                if(!this.datos[propiedad]){
                    exito = false;
                    break;
                    
                }
            }

            if(!exito){
                this.$toast.warning("Favor de llenar todos los campos", {
                    dismissible: true,
                    position: "top"
                });

                return;
            }

            this.datos.sexo = this.getSexo();

            this.datos.tipo = this.tab;

            var listado = await api.post("api/editar_parametro/" + this.datos.id_peso_altura_edad,this.datos);
            this.listado_parametros = listado.data.listado;

            this.$toast.success("Se ha editado el correctamente", {
                dismissible: true,
                position: "top"
            });

            this.limpiarForm();
        },
        selectEliminarParametro(id_peso_altura_edad, semanas){
            this.show_eliminar_percentil = true;
            this.semana_eliminar = semanas;
            this.id_eliminar = id_peso_altura_edad;
        },
        selectEditarParametro(row){
            this.datos = row;
        },
        cambioSexoNina(){
            this.nina = this.nino;
            this.nino = !this.nina;

            this.getListado();
        },

        cambioSexoNino(){
            this.nino = this.nina;
            this.nina = !this.nino;

            this.getListado();
        },

        cambioPestana(tab){
            this.tab = tab;

            this.getListado();
        },

        async agregarParametro(){
            var exito = true;

            this.datos.sexo = this.getSexo();

            this.datos.tipo = this.tab;

            for(const propiedad in this.datos){
                if(!this.datos[propiedad] && propiedad != 'id_peso_altura_edad'){
                    exito = false;
                    break;
                    
                }
            }

            if(!exito){
                this.$toast.warning("Favor de llenar todos los campos", {
                    dismissible: true,
                    position: "top"
                });

                return;
            }

            var listado = await api.post("api/agregar_parametro",this.datos);
            this.listado_parametros = listado.data.listado;

            this.$toast.success("Se ha registrado el correctamente", {
                dismissible: true,
                position: "top"
            });

            this.limpiarForm();
        },

        async getListado(){
            this.limpiarForm();
            var listado = await api.get("api/listado_parametros?tipo=" + this.tab + "&sexo=" + this.getSexo());
            this.listado_parametros = listado.data.listado;
        },

        getSexo(){
            var sexo = 'f';
            
            if(this.nino){
                sexo = 'm';
            }

            return sexo;
        }
    },

    computed: {
        filtrado(){
            return this.keyword  ? this.listado_parametros.filter(
                item => item.semanas.toString().includes(this.keyword)
            ) : this.listado_parametros;
        },
        rows() {
            return this.filtrado.length;
        },

        items(){
            return this.filtrado.slice((this.currentPage - 1) * this.perPage,this.perPage * this.currentPage);
        }
    }
}
</script>

<style>
    .modal-x2 {
        max-width: 90% !important;
    }
    .container1{
        max-width: 100% !important;
    }
    .azulColor{
        background: #43aee6;
    }
    .rosaColor{
        background: #fca9f9;
    }
</style>