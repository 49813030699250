<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="card-body row">
                    <div class="col-lg-12 pt-3">
                        <div class="form-group row col">
                            <label class="col-lg-12 control-label text-primary" style="font-size:20px">HISTORIAL PERSONAL</label>
                        </div>

                        <section class="row">
                            <div class="col">
                                <div class="card-body">
                                     <div class="form-group row" v-for="pregunta in preguntas" :key="clave = pregunta.clave">
                                        <label for="nombre" class="col-lg-8 control-label text-primary">{{ pregunta.pregunta }}</label>
                                        <label for="nombre" class="col control-label text-primary"><toggle-button :sync="true" v-model="certificado_medico.json_certificado[pregunta.clave]" color="#43aee6" :labels="{ checked: 'SI', unchecked: 'NO' }"/></label>
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>

                    <div class="col-lg-12 pt-3">
                        <div class="form-group row col">
                            <label class="col-lg-12 control-label text-primary" style="font-size:20px">EXAMEN FISICO</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'CertificadoMedico',

    data:() => ({
        certificado_medico:{
            ojos: '',
            boca: '',
            nariz: '',
            extremidades: '',
            corazon: '',
            abdomen: '',
            otros: '',
            señas_particulares: '',
            respuesta: '',
            conclucion: '',
            json_certificado:{
                CRIEP: false,
                CRIAS: false,
                LENTE: false,
                FUMAS: false,
                TOMAS: false,
                HOSPA: false,
                CIRLE: false,
                DESMA: false,
                ALERG: false,
                BATRA: false,
                LIMDE: false,
                LIMME: false
            },
            json_signos_vitales:{
                peso: 0,
                talla: 0,
                ta: '',
                fc: '',
                fr: '',
            },
        },

        preguntas:[
            {clave:'CRIEP', pregunta:'¿Ha presentado crisis epilépticas?'},
            {clave:'CRIAS', pregunta:'¿Ha presentado crisis asmaticas?'},
            {clave:'LENTE', pregunta:'¿Usa lentes?'},
            {clave:'FUMAS', pregunta:'¿Fuma habitualmente?'},
            {clave:'TOMAS', pregunta:'¿Ingiere bebidas alcohólicas habitualmente?'},
            {clave:'HOSPA', pregunta:'¿Ha estado hospitalizado durante el último año?'},
            {clave:'CIRLE', pregunta:'¿Ha tenido una cirugía, enfermedades o lesión médica seria?'},
            {clave:'DESMA', pregunta:'¿Alguna vez se ha desmayado o ha perdido el conocimiento?'},
            {clave:'ALERG', pregunta:'¿Es alérgico a algún medicamento o alimento?'},
            {clave:'BATRA', pregunta:'¿Está bajo tratamiento médico?'},
            {clave:'LIMDE', pregunta:'¿Tiene alguna limitación para su participación en actividades?'},
            {clave:'LIMME', pregunta:'¿Ha tenido limitación médica para practicar algún deporte?'},
            {clave:'EXAMN', pregunta:'Examenes de laboratorio:'},
        ]
    }),
}
</script>
