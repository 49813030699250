<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="card-body row">
                    <div class="card col-lg-6 pt-3">
                        <div class="form-group row col">
                            <label style="font-size:13px" class="col-lg-6 control-label text-primary">Total de embarazos:</label>
                            <div class="col-lg-6">
                                <input :maxlength="max" @keydown="filterKey" v-model="d_embarazo.total_embarazos" style="font-size:13px" required type="text" class="form-control" id="total_embarazos"/>
                            </div>
                        </div>

                        <div class="form-group row col">
                            <label style="font-size:13px" class="col-lg-6 control-label text-primary">No. Partos:</label>
                            <div class="col-lg-6">
                                <input :maxlength="max" @keydown="filterKey" v-model="d_embarazo.no_partos" style="font-size:13px" required type="text" class="form-control" id="no_partos"/>
                            </div>
                        </div>

                        <div class="form-group row col">
                            <label style="font-size:13px" class="col-lg-6 control-label text-primary">No. Cesáreas:</label>
                            <div class="col-lg-6">
                                <input :maxlength="max" @keydown="filterKey" v-model="d_embarazo.no_cesareas" style="font-size:13px" required type="text" class="form-control" id="no_cesareas"/>
                            </div>
                        </div>

                    </div>

                    <div class="card col-lg-6 pt-3">
                        <div class="form-group row col">
                            <label style="font-size:13px" class="col-lg-6 control-label text-primary">No. Abortos:</label>
                            <div class="col-lg-6">
                                <input :maxlength="max" @keydown="filterKey" v-model="d_embarazo.no_abortos" style="font-size:13px" required type="text" class="form-control" id="no_abortos"/>
                            </div>
                        </div>

                        <div class="form-group row col">
                            <label style="font-size:13px" class="col-lg-6 control-label text-primary">Nacimientos vivos:</label>
                            <div class="col-lg-6">
                                <input :maxlength="max" @keydown="filterKey" v-model="d_embarazo.nacidos_vivos" style="font-size:13px" required type="text" class="form-control" id="nacidos_vivos"/>
                            </div>
                        </div>

                        <div class="form-group row col">
                            <label style="font-size:13px" class="col-lg-6 control-label text-primary">Vivos actuales:</label>
                            <div class="col-lg-6">
                                <input :maxlength="max" @keydown="filterKey" v-model="d_embarazo.vivos_actuales" style="font-size:13px" required type="text" class="form-control" id="vivos_actuales"/>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 form-group row">
                        <label for="nombre" class="col-lg-12 control-label text-primary">Otros:</label>
                        <div class="col-lg-12">
                            <textarea class="form-control" v-uppercase v-model="d_embarazo.embarazos_otros" style="width:100%; font-size:13px"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Embarazos',

    props: ['data_embarazo'],

    data: () => ({
        max: 3,
        d_embarazo:{
            total_embarazos: '',
            no_partos: '',
            no_cesareas: '',
            no_abortos: '',
            nacidos_vivos: '',
            vivos_actuales: '',
            embarazos_otros: ''
        },
    }),

    created: function(){
        if(this.data_embarazo){
            //this.d_embarazo = this.data_embarazo;

            this.d_embarazo.total_embarazos = this.data_embarazo.total_embarazos;
            this.d_embarazo.no_partos = this.data_embarazo.no_partos;
            this.d_embarazo.no_cesareas = this.data_embarazo.no_cesareas;
            this.d_embarazo.no_abortos = this.data_embarazo.no_abortos;
            this.d_embarazo.nacidos_vivos = this.data_embarazo.nacidos_vivos;
            this.d_embarazo.vivos_actuales = this.data_embarazo.vivos_actuales;
            this.d_embarazo.embarazos_otros = this.data_embarazo.embarazos_otros;
        }
    },

    methods: {
        getInfo(){
            return this.d_embarazo;
        },
        filterKey(e){
            if((e.key >= 0 && e.key <= 9) || e.key == 'Backspace' || e.key == 'Tab') {
                //
            }else{
                e.preventDefault();
            }
        }
    }
}
</script>