<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="row card-body">
                    <div class=" col-lg-12">
                        <div class="form-group row col-lg-12">
                            <div class="col-lg-12">
                                <button type="button" @click="alergias_listado_medicamento = true" class="btn btn-success btn-sm btn-block">Listado de medicamentos</button>
                            </div>
                        </div>


                        <table cellpadding="0" cellspacing="0" class="tabla_diagnostico">
                            <thead>
                                <tr>
                                    <th>Nombre medicamento</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody v-for="(registro, index) in registros" :key="registro.id_alergias_to_paciente">
                                <tr :class="{par_principal:index%2 == 0, inpar_principal:index%2 != 0}">
                                    <td>{{ registro.sustancia }}</td>
                                    <td><span class="btn-outline-danger btn-sm eliminar_diagnostico" @click="eliminarAlergia(registro.id_alergias_to_paciente)">X</span></td>
                                </tr>
                            </tbody>
                        </table>

                        <div class="container-fluid">
                            <label for="nombre" class="col control-label text-primary text-center">Otras alergias:</label>
                            <div class="col-lg-12">
                                <textarea class="form-control" v-uppercase v-model="alergias.alergias_otras" style="width:100%; font-size:13px"></textarea>
                            </div>
                        </div>               
                    </div>

                    <b-modal v-model="alergias_listado_medicamento" size="xl" id="listado_medicamento_alergias" title="medicamentos">
                        <ListadoMedicamentos :desde_alergia="true" @addMedicamento="addMedicamento"></ListadoMedicamentos>
                    </b-modal>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from "../../config.js";

import ListadoMedicamentos from "../ListadoMedicamentos.vue";

export default {
    name: 'Alergias',

    props: ['data_alergias',
            'id_paciente'],

    components:{
        ListadoMedicamentos
    },

    data: () => ({
        alergias_listado_medicamento: false,
        alergias:{
            alergias_otras: ''
        },
        registros: []
    }),

    created: function(){
        if(this.data_alergias){
            this.alergias.alergias_otras = this.data_alergias.alergias_otras;
        }

        this.getListadoAlergias();
    },

    methods: {
        getInfo(){
            return this.alergias;
        },

        async addMedicamento(id_medicamento){
            var listado = await api.post("api/add_alergia_paciente",{"id_medicamento":id_medicamento,
                                                                    "id_paciente":this.id_paciente});
            this.registros = listado.data;
        },

        async getListadoAlergias(){
            var listado = await api.get("api/listado_alergia_paciente/" + this.id_paciente);
            this.registros = listado.data;
        },

        async eliminarAlergia(id){
            var listado = await api.post("api/eliminar_alergia_paciente",{"id_alergias_to_paciente":id,
                                                                        "id_paciente":this.id_paciente});
            this.registros = listado.data;
        }
    }
}
</script>