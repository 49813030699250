<template>
  <div class="calendario">
    
    <div id="cal">
      <div class="header">
        <span @click="anteriorMes" class="left button" id="prev"> &lang; </span>
        <span class="left hook"></span>
        <span class="month-year" id="label"> {{mes_nombre[mes_actual].nombre}} {{anio_actual}} </span>
        <span class="right hook"></span>
        <span @click="siguienteMes" class="right button" id="next"> &rang; </span>
      </div>
      <table id="days">
        <td>Domingo</td>
        <td>Lunes</td>
        <td>Martes</td>
        <td>Miercoles</td>
        <td>Jueves</td>
        <td>Viernes</td>
        <td>Sabado</td>
      </table>
      <div id="cal-frame">
        <table class="curr">
          <tbody>
            <tr v-for="valo in contador_semanas" :key="valo">
              <td v-for="val in 7" :key="val.id" :class="[calendario[((valo - 1) * 7) + (val - 1)].class]" @click="(calendario[((valo - 1) * 7) + (val - 1)].activo_funcion == '1')?verCitas(calendario[((valo - 1) * 7) + (val - 1)].dia):''">
                <div class="numero_consultas" v-if="(calendario[((valo - 1) * 7) + (val - 1)].consultas) > 0">
                  {{ calendario[((valo - 1) * 7) + (val - 1)].consultas }}
                </div>
                <div>
                  {{ calendario[((valo - 1) * 7) + (val - 1)].dia }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'Calendario',

    props: ['parametro_fecha'],

    data: () => ({
      showLoadingCalendario: true,
      es_calendario:false,
      anio_actual: null,
      dia_hoy: null,
      mes_hoy: null,
      anio_hoy: null,
      mes_actual: null,
      calendario:[],
      contador_semanas:'',
      mes_nombre: [
        {nombre: 'Enero'},
        {nombre: 'Febrero'},
        {nombre: 'Marzo'},
        {nombre: 'Abril'},
        {nombre: 'Mayo'},
        {nombre: 'Junio'},
        {nombre: 'Julio'},
        {nombre: 'Agosto'},
        {nombre: 'Septiembre'},
        {nombre: 'Octubre'},
        {nombre: 'Noviembre'},
        {nombre: 'Diciembre'},
      ],
    }),
 
    created: function(){
      var hoy = new Date();
      this.dia_hoy  = hoy.getDate();
      this.mes_actual = this.mes_hoy  = hoy.getMonth();
      this.anio_hoy = hoy.getFullYear();
    },
    methods:{
      async getInfoMes(mes, citas, horario_medico){
        this.calendario = [];
        this.anio_actual = mes.getFullYear();
        this.mes_actual = mes.getMonth();

        var empieza_dia_mes = new Date(mes.getFullYear(), mes.getMonth(), 1);
        var termina_dia_mes = new Date(mes.getFullYear(), mes.getMonth() + 1, 0);

        var cantidad_dias_mes = termina_dia_mes.getDate();
        var dia_semana_primero = empieza_dia_mes.getDay();

        var contador = 0;
        var iterar_dias = 0;
        var contador_dias = 1;
        do{
          for(var i = 0; i <= 6; i++){
            if(iterar_dias >= dia_semana_primero && contador_dias <= cantidad_dias_mes){
              this.es_calendario = true;
            }else{
              this.es_calendario = false;
            }

            var fila;
            var consultas = 0;
            if(this.es_calendario){
              var classe = '';
              
              if(this.dia_hoy == contador_dias && this.mes_hoy == this.mes_actual && this.anio_hoy == this.anio_actual){
                classe = 'dia_hoy';
              }

              var limpiar_contador = ((contador_dias).toString().length == 1)?('0' + (contador_dias)):contador_dias;

              classe += (citas[limpiar_contador])?' con_cita':'';
              
              var activo_funcion = '1';

              if(horario_medico[i] || citas[limpiar_contador] || horario_medico[i] == 0){
                classe += ' dia_trabajo';
              }else{
                classe += ' dia_descanso';

                activo_funcion = '0';
              }

              consultas = (citas[limpiar_contador])? citas[limpiar_contador]: 0;

              fila = {fila: contador, dia: contador_dias, class: classe, id: iterar_dias, consultas: consultas, activo_funcion: activo_funcion}
              contador_dias ++;
              this.calendario.push(fila);
            }else{
              fila = {fila: contador, dia: '', class: "nil dia_descanso", id: iterar_dias, consultas: 0, activo_funcion, activo: activo_funcion}
              this.calendario.push(fila);
            }
            iterar_dias ++;
          }
          contador ++;
        }while(contador_dias <= cantidad_dias_mes);
        
        this.contador_semanas = contador;
      },
      siguienteMes(){
        if(this.mes_actual == 11){
          this.mes_actual = 0;
          this.anio_actual ++;
        }else{
          this.mes_actual++;
        }
        
        this.showLoadingCalendario = true;
        this.$emit('fechaCalendario', new Date(this.anio_actual, this.mes_actual));
      },

      anteriorMes(){
        if(this.mes_actual == 0){
          this.mes_actual = 11;
          this.anio_actual --;
        }else{
          this.mes_actual--;
        }

        this.$emit('fechaCalendario', new Date(this.anio_actual, this.mes_actual));
      },

      verCitas(dia){
        this.$emit('fechaListado', new Date(this.anio_actual, this.mes_actual, dia));
      }
    }
};
</script>

<style>
@import "../assets/css/calendario.css";
</style>