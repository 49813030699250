<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="card-body">
                    <div class="form-group row">
                        <h4 class="col-lg-12 control-label text-primary">{{ this.titulo }}</h4>
                    </div>

                    <div class="form-group row">
                        <div class="col-lg-12">
                            <textarea class="form-control"  v-uppercase v-if="!campo_informativo" v-model="data.info" style="width:100%; font-size:13px; padding:10px"></textarea>
                            <div class="border border-primary" style="width:100%; font-size:13px; padding:10px" v-else>{{ data.info | uppercase }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        name: 'Padecimiento',

        props: ['padecimiento', 'campo_informativo', 'motivo', 'tipo'],

        data: () => ({
            editar_precio_consulta: false,
            titulo: '',
            data:{
                info: '',
                costo: ''
            }
        }),

        created: async function(){
            var permisos = await this.$store.dispatch("getPermisos");

            if(permisos){
                this.editar_precio_consulta = permisos.includes('PRECO');
            }

            if(this.padecimiento){
                this.data.info  = this.padecimiento[this.tipo];
            }

            switch(this.tipo){
                
                case 'padecimiento':
                case 'sintomas':
                    this.titulo = 'SÍNTOMAS QUE PRESENTA EL PACIENTE';
                    break;

                case 'subjetivo':
                    this.titulo = 'SUBJETIVO';
                    break;

                case 'objetivo':
                    this.titulo = 'OBJETIVO';
                    break;

                case 'analisis':
                    this.titulo = 'ANÁLISIS';
                    break;

                case 'plan':
                    this.titulo = 'PLAN';
                    break;
            }
        },

        methods:{
            getInfo(){
                if(this.data.info){
                    this.data.info = this.data.info.toUpperCase();
                }

                var _tipo = this.tipo;

                if(_tipo == 'padecimiento'){
                    _tipo = 'sintomas';
                }

                this.data = {[_tipo]: this.data.info}
                       
                return this.data;
            }
        }
    }
</script>