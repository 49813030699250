<template>
    <div class="container">
        <div class="card">
            <form @submit.prevent="registrar" class="formulario__register">
            
            <div class="form-group">
                <input v-uppercase class="form-control" v-bind:class="{ 'is-invalid': nombre_error }" v-model="form_register.nombre" type="text" placeholder="Nombre" />
                <div v-if="nombre_error"><small id="passwordHelp" class="text-danger"> {{ nombre_error }}</small></div>
            </div>

            <div class="form-group">
                <input v-uppercase class="form-control" v-bind:class="{ 'is-invalid': apellido_paterno_error }" v-model="form_register.apellido_paterno" type="text" placeholder="Apellido paterno" />
                <div v-if="apellido_paterno_error"><small id="passwordHelp" class="text-danger"> {{ apellido_paterno_error }}</small></div>
            </div>
            
            <div class="form-group">
                <input v-uppercase class="form-control" v-bind:class="{ 'is-invalid': apellido_materno_error }" v-model="form_register.apellido_materno" type="text" placeholder="Apellido materno" />
                <div v-if="apellido_materno_error"><small id="passwordHelp" class="text-danger"> {{ apellido_materno_error }}</small></div>
            </div>

            <div class="form-group">
                <div class="col-md-12 mt-4">
                    <div class="form-group row">
                        <label class="col-lg-6 control-label">Es medico:</label>
                        <label for="es_medico" class="col text-primary"><toggle-button :sync="true" v-model="es_medico" color="#43aee6" :labels="{ checked: 'SI', unchecked: 'NO' }"/></label>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <input v-uppercase v-if="!es_medico" class="form-control" v-model="form_register.rol" type="text" placeholder="Rol" />
            </div>

            <div class="form-group">
                <input v-uppercase class="form-control" v-bind:class="{ 'is-invalid': email_error }" v-model="form_register.email" type="email" placeholder="Correo electronico" />
                <div v-if="email_error"><small id="passwordHelp" class="text-danger"> {{ email_error }}</small></div>
            </div>

            <div class="form-group">
                <input class="form-control" v-bind:class="{ 'is-invalid': password_error }" v-model="form_register.password" type="password" placeholder="contraseña" />
                <div v-if="password_error"><small id="passwordHelp" class="text-danger"> {{ password_error }}</small></div>
            </div>

            <div class="form-group">
                <input class="form-control" v-bind:class="{ 'is-invalid': password_confirm_error }" v-model="form_register.confirm_password" type="password" placeholder="confirmar contraseña" />
                <div v-if="password_confirm_error"><small id="passwordHelp" class="text-danger"> {{ password_confirm_error }}</small></div>
            </div>

            <button v-if="!registrar_usuario" class="btn btn-primary" id="btn__iniciar-sesion">Registrar</button>

            <button v-else class="btn btn-primary" type="button" disabled>
              <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
              Registrando...
            </button>
          </form>
        </div>
    </div>
</template>

<script>
import api from "../config.js";

    export default {
        name: 'NuevoUsuario',

        data: () => ({
            form_register: {
                nombre: "",
                email: "",
                password: "",
                confirm_password: "",
                apellido_paterno: "",
                apellido_materno: "",
                es_medico: true,
                rol: '',
            },
            es_medico: true,
            iniciar_sesion: false,
            registrar_usuario: false,
            email_error: false,
            password_error: false,
            password_confirm_error: false,
            apellido_paterno_error: false,
            apellido_materno_error: false,
            nombre_error: false,
            login_error: false
        }),

        methods:{
            async registrar(){
                (this.es_medico)? this.form_register.es_medico = '1' : this.form_register.es_medico = '0';

                this.registrar_usuario = true;
                var cookie = await api.get("/sanctum/csrf-cookie");
                if(cookie.status == 204){

                    (this.form_register.nombre)? this.form_register.nombre = this.form_register.nombre.toUpperCase() : '';
                    (this.form_register.email)? this.form_register.email = this.form_register.email.toUpperCase() : '';
                    (this.form_register.apellido_paterno)? this.form_register.apellido_paterno = this.form_register.apellido_paterno.toUpperCase() : '';
                    (this.form_register.apellido_materno)?this.form_register.apellido_materno = this.form_register.apellido_materno.toUpperCase() : '';
                    (this.form_register.rol)?this.form_register.rol = this.form_register.rol.toUpperCase() : '';


                    await api.post("/register", this.form_register).then(data => {
                    if(data.status == 200){
                        this.$toast.success('Se registró el usuario exitosamente', {
                            dismissible: true,
                            position: 'top'
                        });

                        this.form_register.nombre = "";
                        this.form_register.email = "";
                        this.form_register.password = "";
                        this.form_register.confirm_password = "";
                        this.form_register.apellido_paterno = "";
                        this.form_register.apellido_materno = "";

                        this.registrar_usuario = false;

                        location.reload();
                    }
                    }).catch((erro) =>{
                    this.$toast.error('Favor de capturar los datos correctamente', {
                        dismissible: true,
                        position: 'top'
                    });
                    this.nombre_error           = (erro.response.data.error.nombre)?erro.response.data.error.nombre[0]:false;
                    this.apellido_paterno_error = (erro.response.data.error.apellido_paterno)?erro.response.data.error.apellido_paterno[0]:false;
                    this.apellido_materno_error = (erro.response.data.error.apellido_materno)?erro.response.data.error.apellido_materno[0]:false;
                    this.email_error            = (erro.response.data.error.email)?erro.response.data.error.email[0]:false;
                    this.password_error         = (erro.response.data.error.password)?erro.response.data.error.password[0]:false;
                    this.password_confirm_error = (erro.response.data.error.confirm_password)?erro.response.data.error.confirm_password[0]:false;

                    this.registrar_usuario = false;
                    });
                }
                this.registrar_usuario = false;
            },
        }
    }

    
</script>
