<template>

  <div class="wrapper">
    <div class="top_navbar">
      <div class="hamburger" @click="hamburger">
        <div class="one"></div>
        <div class="two"></div>
        <div class="three"></div>
      </div>
      <div class="top_menu">
        <div class="logo">MEDICAL SCHEDULE</div>
        <div>
            <div class="nombre_doc">{{ user.nombre }} {{ user.apellido_paterno }} {{ user.apellido }}</div>
            <div class="container_perfil"  @mousemove="hover_perfil_opcions = true" @mouseleave="hover_perfil_opcions = false">
              <a href="#">
                <i>
                  <img :src="user.url_img"/>
                </i>
              </a>
              <div :class="{ user_options_hidden: !hover_perfil_opcions, user_options: hover_perfil_opcions }">
                  <ul>
                    <li>
                        <router-link tag="div" to="/perfil">
                          Perfil
                        </router-link>
                    </li>
                    <li><div @click="logout">Cerrar sesión</div></li>
                  </ul>
              </div>
            </div>
        </div>
      </div>
    </div>


    <div class="sidebar">
      <ul>
        <li class="menu">
          <router-link tag="a" to="/dashboard/true">
            <span class="icon"><i class="fas fa-calendar-week"></i></span>
            <span class="title">Calendario</span>
          </router-link>
        </li>
        <li class="menu btn_abajo_paciente" @mouseover="hover_abajo_paciente = true" @mouseleave="hover_abajo_paciente = false">
          <a href="#" :class="{ active: hover_abajo_paciente }">
            <span class="icon"><i class="fas fa-users"></i></span>
            <span class="title">Pacientes</span>
          </a>
          <ul class="submenu" :class="{ submenu_mostrar: hover_abajo_paciente }">
            <li v-b-modal.registrar_paciente>
              <span>Registrar Paciente</span>
              <b-modal v-model="show" size="xl" id="registrar_paciente" title="Nuevo Paciente">
                <NuevoPaciente @cerrarModalNuevoPaciente="cerrarModal"></NuevoPaciente>
                <template v-slot:modal-footer>
                  <div class="container-fluid"> 
                    <div class="align-items-center">
                      <div class="text-center" style="margin-left:10px; margin-right:10px">
                        <b-button @click="show=false" class="btn btn-danger  btn-lg btn-block"> Cerrar </b-button>
                      </div>	
                    </div>
                  </div>
                </template>
              </b-modal>
            </li>
            <router-link tag="li" to="/listado_pacientes"><span>Listado Pacientes</span></router-link>
          </ul>
        </li>
        <li class="menu btn_abajo_citas" @mouseover="hover_abajo_citas = true" @mouseleave="hover_abajo_citas = false">
          <a href="#" :class="{ active: hover_abajo_citas }">
            <span class="icon"><i class="fas fa-book-medical"></i></span>
            <span class="title">Citas</span>
          </a>
          <ul class="submenu" :class="{ submenu_mostrar: hover_abajo_citas }">
            <li v-b-modal.registrar_cita>
              <span>Registrar Cita</span>
              <b-modal v-model="show_cita" id="registrar_cita" title="Nueva Cita">
                <NuevaCita></NuevaCita>
                <template v-slot:modal-footer>
                  <div class="container-fluid"> 
                    <div class="align-items-center">
                      <div class="text-center" style="margin-left:10px; margin-right:10px">
                        <b-button @click="show_cita=false" class="btn btn-danger  btn-lg btn-block"> Cerrar </b-button>
                      </div>	
                    </div>
                  </div>
                </template>
              </b-modal>
            </li>
          </ul>
        </li>
        <li class="menu">
           <router-link tag="a" to="/usuarios">
            <span class="icon"><i class="fas fa-user"></i></span>
            <span class="title">Usuarios</span>
          </router-link>
        </li>
        <li class="menu btn_abajo_catalogos" @mouseover="hover_abajo_catalogos = true" @mouseleave="hover_abajo_catalogos = false">
          <a href="#" :class="{ active: hover_abajo_catalogos }">
            <span class="icon"><i class="fas fa-chart-bar"></i></span>
            <span class="title">Catálogos</span>
          </a>

          <ul class="submenu" :class="{ submenu_mostrar: hover_abajo_catalogos }">
            <li v-b-modal.catalogo_percentiles>
              <span>Percentiles</span>
              <b-modal v-model="show_percentiles" size="x2" id="catalogo_percentiles" title="Percentiles">
                <Percentiles :desde_catalogo="true"></Percentiles>
              </b-modal>
            </li>

            <li v-b-modal.catalogo_medicamentos>
              <span>Medicamentos</span>
              <b-modal v-model="show_medicamento" size="xl" id="catalogo_medicamentos" title="Medicamentos">
                <ListadoMedicamentos :desde_catalogo="true"></ListadoMedicamentos>
              </b-modal>
            </li>


            <!-- <li v-b-modal.catalogo_direcciones>
              <span>Direcciones</span>
              <b-modal v-model="show_direcciones" size="xl" id="catalogo_direcciones" title="Direcciones">
                <Direcciones :desde_catalogo="true"></Direcciones>
              </b-modal>
            </li> -->

            <li v-b-modal.catalogo_estudios>
              <span>Estudios</span>
              <b-modal v-model="show_estudios" size="xl" id="catalogo_estudios" title="Estudios">
                <ListadoEstudios :desde_catalogo="true"></ListadoEstudios>
              </b-modal>
            </li>

           <!-- <li v-if="catalogo_motivos" v-b-modal.catalogo_motivos>
              <span>Motivos</span>
              <b-modal v-model="show_motivos" size="xl" id="catalogo_motivos" title="Motivos">
                <Motivos :desde_catalogo="true"></Motivos>
              </b-modal>
            </li> -->

            <li v-if="catalogo_especialidades" v-b-modal.catalogo_especialidades>
              <span>Especialidades</span>
              <b-modal v-model="show_especialidades" size="xl" id="catalogo_especialidades" title="Especialidades">
                <Especialidades :desde_catalogo="true"></Especialidades>
              </b-modal>
            </li>

            <li v-if="catalogo_logotipos" v-b-modal.catalogo_logotipos>
              <span>Logotipos</span>
              <b-modal v-model="show_logotipos" size="xl" id="catalogo_logotipos" title="Logotipos">
                <Logotipos :desde_catalogo="true"></Logotipos>
              </b-modal>
            </li>
          </ul>
        </li>

        <!-- <li class="menu">
          <router-link tag="a" to="/dashboard/true">
            <span class="icon"><i class="fas fa-calendar-week"></i></span>
            <span class="title">Configuración</span>
          </router-link>
        </li> -->

        <li class="menu">
          <router-link tag="a" to="/estadisticas">
            <span class="icon"><i class="fas fa-calendar-week"></i></span>
            <span class="title">Estadisticas</span>
          </router-link>
        </li>
      </ul>
    </div>

    <div class="main_container">
      <router-view />
    </div>
  </div>
</template>

<script>

import NuevaCita from "../components/NuevaCita.vue";
import NuevoPaciente from "../components/NuevoPaciente.vue";
import ListadoMedicamentos from "../components/ListadoMedicamentos.vue";
import ListadoEstudios from "../components/ListadoEstudios.vue";
//import Direcciones from "../components/catalogos/Direcciones.vue";
//import Motivos from "../components/catalogos/Motivos.vue";
import Especialidades from "../components/catalogos/Especialidades.vue";
import Logotipos from "../components/catalogos/Logotipos.vue";
import Percentiles from "../components/catalogos/Percentiles.vue";

const $ = require("jquery");

export default {
  name: "Home",
  
  components:{
    NuevaCita,
    NuevoPaciente,
    ListadoMedicamentos,
    //Direcciones,
    ListadoEstudios,
    //Motivos,
    Especialidades,
    Logotipos,
    Percentiles
  },

  data() {
    return {
      catalogo_motivos: false,
      catalogo_logotipos: false,
      catalogo_especialidades: false,
      ver_catalogo_medicamentos: false,
      hover_abajo_paciente: false,
      hover_abajo_citas: false,
      hover_perfil_opcions: false,
      hover_abajo_catalogos: false,
      user: [],
      show: false,
      show_cita: false,
      show_medicamento: false,
      //show_direcciones: false,
      show_estudios: false,
      show_percentiles: false,
      show_motivos: false,
      show_especialidades: false,
      show_logotipos: false
    };
  },

  async beforeCreate(){
    this.user = await this.$store.dispatch("getUsuario");

    var permisos = await this.$store.dispatch("getPermisos");

    if(permisos){
      this.catalogo_motivos = permisos.includes('CATMO');
      this.catalogo_especialidades = permisos.includes('CATDA');
      this.catalogo_logotipos = permisos.includes('CATLO');
    }
  },

  methods: {
    hamburger() {
      $(".wrapper").toggleClass("collapse_1"); 
    },

    async logout(){
      await this.$store.dispatch("logout");
      location.reload();
    },

    cerrarModal(){
      this.show = false;
    }
  }
};
</script>

<style>
@import "../assets/css/menu_lateral.css";
</style>
