<template>
    <div class="container">
        <div class="row">
            <div class="col row mt-3 ml-1">
                <div class="card col-lg-6 pt-3">
                    <div class="form-group row col" v-for="col in col_1" :key="col.clave">
                        <label style="font-size:13px" class="col-lg-6 control-label text-primary">{{ col.label }}:</label>
                        <div class="col-lg-3">
                            <input :maxlength="max" @keydown="filterKey" v-model="json_psicomotor[col.clave]" style="font-size:13px" required type="text" class="form-control" id="nombre"/>
                        </div>
                        <label style="font-size:13px" class="col-lg-3 control-label text-primary">meses</label>
                    </div>
                </div>

                <div class="card col-lg-6 pt-3">
                    <div class="form-group row col" v-for="col in col_2" :key="col.clave">
                        <label style="font-size:13px" class="col-lg-6 control-label text-primary">{{ col.label }}:</label>
                        <div class="col-lg-3">
                            <input :maxlength="max" @keydown="filterKey" v-model="json_psicomotor[col.clave]" style="font-size:13px" required type="text" class="form-control" id="nombre"/>
                        </div>
                        <label style="font-size:13px" class="col-lg-3 control-label text-primary">meses</label>
                    </div>
                </div>
            </div>
        </div>

        <hr />

        <div class="col-md-12 row">
            <div class="col-lg-6">
                <div class="form-group row">
                    <label for="desempeno_escolar" class="col-lg-12 control-label text-primary">Desempeño escolar:</label>
                    <div class="col-lg-12">
                        <textarea class="form-control" v-uppercase v-model="d_psicomotor.desempeno_escolar" style="width:100%; font-size:13px"></textarea>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="form-group row">
                    <label for="psicomotor_otros" class="col-lg-12 control-label text-primary">Otros:</label>
                    <div class="col-lg-12">
                        <textarea class="form-control" v-uppercase v-model="d_psicomotor.psicomotor_otros" style="width:100%; font-size:13px"></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Psicomotor',

    props: ['data_psicomotor'],

    data: () => ({
        max: 3,
        json_psicomotor:{
            siguio_objetos: '',
            sostuvo_cabeza: '',
            sedestacion: '',
            rodamiento: '',
            bisilabos: '',
            bipedestacion: '',
            deambulacion: '',
            endocrino: '',
            osteomuscular: '',
            nervioso: '',
            sensorial: '',
            psicosomatico: '',
            subir_escaleras: '',
            control_esfinteres: ''
        },

        d_psicomotor:{
            json_psicomotor: '',
            desempeno_escolar: '',
            psicomotor_otros: ''
        },
        
        col_1:[
            {clave:'siguio_objetos', label:'Siguió objetos'},
            {clave:'sostuvo_cabeza', label:'Sostuvo la cabeza'},
            {clave:'sedestacion', label:'Sedestación'},
            {clave:'rodamiento', label:'Rodamiento'},
            {clave:'bisilabos', label:'Bisílabos'},
            {clave:'bipedestacion', label:'Bipedestación'},
            {clave:'deambulacion', label:'Deambulación'},
        ],

        col_2:[
            {clave:'endocrino', label:'Sonrió'},
            {clave:'osteomuscular', label:'Caminó'},
            {clave:'nervioso', label:'Correr'},
            {clave:'sensorial', label:'Gateó'},
            {clave:'psicosomatico', label:'Lenguaje fluido'},
            {clave:'subir_escaleras', label:'Subir escaleras'},
            {clave:'control_esfinteres', label:'Control de esfínteres'},
        ],
    }),

    created: function(){
        if(this.data_psicomotor){
            if(this.data_psicomotor.json_psicomotor){
                this.json_psicomotor = JSON.parse(this.data_psicomotor.json_psicomotor);
            }
            this.d_psicomotor.desempeno_escolar = this.data_psicomotor.desempeno_escolar;
            this.d_psicomotor.psicomotor_otros = this.data_psicomotor.psicomotor_otros;
        }
    },

    methods: {
        getInfo(){
            this.d_psicomotor.json_psicomotor = JSON.stringify(this.json_psicomotor);
            return this.d_psicomotor;
        },
        filterKey(e){
            if((e.key >= 0 && e.key <= 9) || e.key == 'Backspace' || e.key == 'Tab') {
                //
            }else{
                e.preventDefault();
            }
        }
    }
}
</script>