<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="card-body">
                    <div class="form-group row">
                        <h4 class="col-lg-12 control-label text-primary">{{ motivo.motivo }}</h4>
                    </div>

                    <div v-if="label_resumen_clinico" class="form-group row">
                        <div class="col-lg-12">
                            {{ label_resumen_clinico }}
                        </div>
                        <div class="col-lg-12">
                            <textarea class="form-control" v-uppercase v-if="!campo_informativo" v-model="d_interconsulta.resumen_clinico" style="width:100%; font-size:13px; padding:10px"></textarea>
                            <div class="border border-primary" style="width:100%; font-size:13px; padding:10px" v-else>{{ d_interconsulta.resumen_clinico }}</div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-lg-12">
                            {{ label_motivo }}
                        </div>
                        <div class="col-lg-12">
                            <textarea class="form-control" v-uppercase v-if="!campo_informativo" v-model="d_interconsulta.interconsulta" style="width:100%; font-size:13px; padding:10px"></textarea>
                            <div class="border border-primary" style="width:100%; font-size:13px; padding:10px" v-else>{{ d_interconsulta.interconsulta }}</div>
                        </div>
                    </div>

                    <div class="col-lg-12 row mb-2">
                        <div class="col-lg-6">
                            {{ servicio }}
                        </div>
                        <div class="col-lg-6">
                            <input style="font-size:13px" :readonly="campo_informativo" v-uppercase v-model="d_interconsulta.servicio_interconsulta" type="text" class="form-control"/>
                        </div>
                    </div>

                    <div v-if="servicio_consultado" class="col-lg-12 row mb-2">
                        <div class="col-lg-6">
                            {{ servicio_consultado }}
                        </div>
                        <div class="col-lg-6">
                            <input style="font-size:13px" :readonly="campo_informativo" v-uppercase v-model="d_interconsulta.servicio_consultado" type="text" class="form-control"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        name: 'Interconsulta',

        props: ['campo_informativo', 'interconsulta', 'motivo'],

        data: () => ({
            d_interconsulta:{
                interconsulta: '',
                servicio_interconsulta: '',
                servicio_consultado: '',
                resumen_clinico: ''
            },

            label_motivo: '',
            servicio: '',
            servicio_consultado: '',
            label_resumen_clinico: '',
        }),

        created: async function(){
            
            if(this.interconsulta){
                this.d_interconsulta.interconsulta = this.interconsulta.interconsulta;
                this.d_interconsulta.servicio_interconsulta = this.interconsulta.servicio_interconsulta;
                this.d_interconsulta.servicio_consultado = this.interconsulta.servicio_consultado;
                this.d_interconsulta.resumen_clinico = this.interconsulta.resumen_clinico;
            }

            switch(this.motivo.clave){
                case 'NOTRA':
                    this.label_motivo = 'MOTIVO DE ENVIO';
                    this.servicio = 'ESTABLECIMIENTO RECEPTOR';
                    this.servicio_consultado = 'ESTABLECIMIENTO QUE ENVIA';
                    this.label_resumen_clinico = 'RESUMEN CLINICO';
                    break;
                
                default:
                    this.label_motivo = 'PROBLEMAS ACTIVOS:';
                    this.servicio = 'SERVICIO QUE SOLICITA LA INTERCONSULTA:';
                    this.servicio_consultado = 'SERVICIO CONSULTADO:';
                     this.label_resumen_clinico = '';
                    break;
            }
        },

        methods:{
            getInfo(){
                return this.d_interconsulta;
            }
        }
    }
</script>