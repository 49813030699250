<template>
    <section class="row card-body">
        <div v-if="!campo_informativo" class="form-group row col-lg-12">
            <label class="col-lg-12 control-label text-primary">ENFERMEDADES DEL CIE-11</label>
            <div class="col-lg-8">
                <input style="font-size:13px" @keyup.enter="getListadoEnfermedades" v-model="enfermedad_buscar" type="text" class="form-control"/>
            </div>
            <div class="col-lg-4">
                <button v-if="loadin_buscar_enfermedad" class="btn btn-success btn-sm btn-block" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span class="sr-only">Loading...</span>
                </button>

                <button v-else type="button" @click="getListadoEnfermedades" class="btn btn-success btn-sm btn-block">BUSCAR</button>
            </div>
        </div>

        <div class="form-group row col-lg-12" v-if="enfermedad.codigo">
             <div class="col-lg-4 pb-2">
                <button type="button" @click="show_listado_medicamentos = true" class="btn btn-success btn-sm btn-block">LISTADO DE MEDICAMENTOS</button>
            </div>

            <label class="col-lg-12 control-label text-primary">MEDICAMENTO</label>
           
            <div class="col-lg-12">
                <v-select v-model="medicamento" :options="medicamentos_relacionados" label="sustancia">
                    <template #search="{attributes, events}">
                        <input class="vs__search" :required="!medicamento" v-bind="attributes" v-on="events" />
                    </template>
                </v-select>
            </div>
            
        </div>

        <div class="form-group row col-lg-12" v-if="medicamento">
            <label class="col-lg-12 control-label text-primary">DOSIS</label>
            <div class="col-lg-12">
                <input v-uppercase v-model="medicamento.dosificacion" style="font-size:13px" type="text" class="form-control"/>
            </div>
        </div>

        <div class="form-group row col-lg-12" v-if="medicamento">
            <label class="col-lg-12 control-label text-primary">CONCENTRACIÓN</label>
            <div class="col-lg-12">
                <select v-model="enfermedad.concentracion"  class="form-control">
                    <option :value="items" v-for="(items, index) in (medicamento.concentracion).split(',')" :key="index">{{items}}</option>
                </select>
            </div>
        </div>

        <div class="container-fluid mt-3 pb-4" v-if="medicamento && medicamento.id_medicamento && medicamento.dosificacion">
            <div class="align-items-center row">
                <div class="text-center col-lg-4">
                    <button @click="addRegistro" class="btn btn-primary  btn-lm btn-block">AGREGAR</button>
                </div>
            </div>
        </div>

        <table cellpadding="0" cellspacing="0" class="tabla_diagnostico">
            <thead>
                <tr>
                    <th>CIE11</th>
                    <th>MEDICAMENTO</th>
                    <th>DOSIFICACIÓN</th>
                </tr>
            </thead>
            <tbody v-for="(registro, index) in registros" :key="registro.codigo">
                <tr>
                    <td :class="{par_principal:index%2 == 0, inpar_principal:index%2 != 0}" colspan="3">
                        <div>
                            <div class="float-left">
                                <span class="btn_listado_medicinas" @click="registro.mostrar_principal = !registro.mostrar_principal"><i class="fas fa-caret-right"></i></span> {{ registro.clave }} - {{ registro.nombre }}
                            </div>
                            <div class="float-right">
                                <button v-if="!campo_informativo" class="btn btn-info btn-sm mr-2 mt-2 mb-2" @click="AddMedicamentoDesdeListado(registro.clave, registro.nombre)">Agregar medicamento</button>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr v-show="registro.mostrar_principal" v-for="(medic, med_index) in registro.medicamentos" :key="med_index">
                    <td valign="top" style="text-align: right; padding-right: 10px"><span class="btn_listado_medicinas" @click="medic.mostrar_secundario = !medic.mostrar_secundario"><i class="fas fa-angle-right"></i></span></td>
                    <td :id="'td_popover_' + medic.id" valign="bottom" :class="{['par_' + [medic.es_alergico]]:med_index%2 == 0, ['inpar_' + [medic.es_alergico]]:med_index%2 != 0}">
                        <b-popover v-if="medic.es_alergico == 'alergico'"
                            :target="'td_popover_' + medic.id"
                            triggers="hover focus"
                            content="El paciente es alergico al medicamento"
                        ></b-popover>
                        <div @dblclick="hacerCampoEditable(medic.id)">{{ medic.nombre }}</div>
                        <div style="background:white" v-show="medic.mostrar_secundario">
                            <div style="text-align: right; padding-right: 10px">Farmacéutica:</div>
                            <div style="text-align: right; padding-right: 10px">Sustancia(s):</div>
                            <div style="text-align: right; padding-right: 10px">Presentación:</div>
                            <div style="text-align: right; padding-right: 10px">Concentración:</div>
                        </div>
                    </td>
                    <td style="max-width:500px;" valign="top" @dblclick="hacerCampoEditable(medic.id)">
                        <div class="col-md-12 row" :class="{['par_' + [medic.es_alergico]]:med_index%2 == 0, ['inpar_' + [medic.es_alergico]]:med_index%2 != 0}">
                            <b-popover v-if="medic.es_alergico == 'alergico'"
                                :target="'popover_' + medic.id"
                                triggers="hover focus"
                                content="El paciente es alergico al medicamento"
                            ></b-popover>
                            <div :id="'popover_' + medic.id" v-if="!campo_editable[medic.id]" class="col-md-10">
                                <pre><span>{{ (medic.dosis)?medic.dosis:'-' | uppercase }}</span></pre>
                            </div>
                            
                            <div v-else>
                                <!-- <input class="form-control" v-uppercase type="text" v-model="medic.dosis" @change="updateDosificacion(medic.id, medic.dosis)"> -->
                                <textarea class="form-control" v-uppercase v-model="medic.dosis" @change="updateDosificacion(medic.id, medic.dosis)" cols="30" rows="10"></textarea>
                            </div>
                            <div class="col-md-2">
                                <span v-if="!campo_informativo" class="btn-outline-danger btn-sm eliminar_diagnostico" @click="eliminarDiagnostico(medic.id)">X</span>
                            </div>
                        </div>
                        <div v-show="medic.mostrar_secundario">
                            <div>{{ medic.farmaceutica | uppercase }}</div>
                            <div>{{ medic.sustancia_activa | uppercase }}</div>
                            <div>{{ medic.presentacion | uppercase }}</div>
                            <div>{{ medic.concentracion | uppercase }}</div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="form-group row col-lg-12">
            <label for="padre" class="col-lg-3 control-label text-primary">INDICACIONES:</label>
            <div class="col-lg-8">
                <textarea class="form-control"  v-uppercase v-if="!campo_informativo" v-model="datos.tratamiento" style="width:100%"></textarea>
                <div class="border border-primary" style="width:100%; font-size:13px; padding:10px" v-else><pre><span>{{ datos.tratamiento }}</span></pre></div>
            </div>
        </div>

        <div class="form-group row col-lg-12">
            <label for="padre" class="col-lg-3 control-label text-primary">PRONÓSTICO:</label>
            <div class="col-lg-8">
                <textarea class="form-control"  v-uppercase v-if="!campo_informativo" v-model="datos.pronostico" style="width:100%"></textarea>
                <div class="border border-primary" style="width:100%; font-size:13px; padding:10px" v-else>{{ datos.pronostico | uppercase }}</div>
            </div>
        </div>

        <b-modal v-model="show_listado_enfermedad" size="xl" id="listado_enfermedad" title="Enfermedades">
            <ListadoEnfermedadesCIE @addEnfermedad="addEnfermedad" :listado_enfermedades="listado_enfermedades"></ListadoEnfermedadesCIE>
        </b-modal>

        <b-modal v-model="show_listado_medicamentos" size="xl" id="listado_medicamento_d_y_t" title="Medicamentos">
            <ListadoMedicamentos :clave="enfermedad.codigo" @addMedicamento="addMedicamento"></ListadoMedicamentos>
        </b-modal>
        
    </section>
</template>

<script>
import api from "../../config.js";

import ListadoEnfermedadesCIE from "../antecedentes/listado/ListadoEnfermedadesCIE.vue";
import ListadoMedicamentos from "../ListadoMedicamentos.vue";

export default {
    name: 'DiagnosticoTratamiento',

    props: ['diagnostico', 'id_consulta', 'campo_informativo'],

    components:{
        ListadoEnfermedadesCIE,
        ListadoMedicamentos
    },

    data: () => ({
        campo_editable: [],
        medicamento: null,
        medicamentos_relacionados: [],
        loadin_buscar_enfermedad: false,
        listado_enfermedades: null,
        show_listado_enfermedad:false,
        show_listado_medicamentos:false,
        enfermedad_buscar: null,
        enfermedad:{
            nombre_enfermedad:'',
            codigo: '',
            id_medicamento: null,
            nombre_medicamento: '',
            dosis: '',
            concentracion: '',
        },
        fields: [
        {
            key: "codigo",
            label: "Codigo",
            sortable: true
        },
        {
            key: "medicamento",
            label: "Medicamento",
            sortable: true
        },
        {
            key: "dosis",
            label: "Dosificacione",
            sortable: true
        },
        {
            key: "opciones",
            label: "",
            sortable: false
        }
        ],

        datos:{
            tratamiento:'',
            pronostico:'',
        },

        registros:[]
    }),

    created: function(){
        if(this.diagnostico){
            this.datos.tratamiento = this.diagnostico.tratamiento;
            this.datos.pronostico  = this.diagnostico.pronostico;
        }

        this.getListado();
    },

    methods:{
        getInfo(){
            return this.datos;
        },
        hacerCampoEditable(id){
            if(!this.campo_informativo){
                this.campo_editable[id] = true;
                //FIXME ver porque funciona solamente con el splice
                this.campo_editable.splice(0, 0 );
            }
        },

        async getListadoEnfermedades() {
            this.loadin_buscar_enfermedad = true;
            var listado = await api.post("api/getEnfermedad",{ 'enfermedad_buscar': this.enfermedad_buscar});
            this.listado_enfermedades = listado.data;
            this.loadin_buscar_enfermedad = false;
            this.show_listado_enfermedad = true;
        },
        async addEnfermedad(nombre, clave) {
            this.enfermedad.nombre_enfermedad = nombre;
            this.enfermedad.codigo = clave;
            this.enfermedad_buscar = nombre;
            this.show_listado_enfermedad = false;

            var listado = await api.post("api/get_relacionadas",{'clave':clave});

            this.medicamentos_relacionados = listado.data;
            this.enfermedad.dosis = this.medicamentos_relacionados.dosificacion;
        },
        AddMedicamentoDesdeListado(clave, nombre){
            this.enfermedad.nombre_enfermedad = nombre;
            this.enfermedad.codigo = clave;
            this.enfermedad_buscar = nombre;
            this.show_listado_medicamentos = true
        },
        async addMedicamento(id_medicamento, nombre, dosis, concentracion){
            this.enfermedad.id_medicamento = id_medicamento;
            this.enfermedad.nombre_medicamento = nombre;
            this.enfermedad.dosis = dosis;
            this.enfermedad.id_consulta = this.id_consulta;
            this.enfermedad.concentracion = concentracion;

            var listado = await api.post("api/add_diagnostico_tratamiento",this.enfermedad);
            this.registros = listado.data;

            this.enfermedad.id_medicamento = null;
            this.enfermedad.nombre_medicamento = '';
            this.enfermedad.dosis = '';
        },  
        async updateDosificacion(id_registro, dosis){
            var listado = await api.post("api/update_dosificacion/"+ this.id_consulta,{
                'id_diagnostico_tratamiento':id_registro,
                'dosis' : dosis
            });

            this.campo_editable[id_registro] = false;

            this.registros = listado.data;
        },
        async addRegistro(){
            this.enfermedad.id_consulta = this.id_consulta;
            this.enfermedad.id_medicamento = this.medicamento.id_medicamento;
            this.enfermedad.dosis = this.medicamento.dosificacion;

            var listado = await api.post("api/add_diagnostico_tratamiento",this.enfermedad);
            this.registros = listado.data;

            this.enfermedad.nombre_enfermedad = '';
            this.enfermedad.codigo = null;
            this.enfermedad.dosis = '';

            this.enfermedad_buscar = '';

            this.medicamento = null;
        },
        async getListado(){
            var listado = await api.get("api/listado_diagnostico_tratamiento/" + this.id_consulta);
            this.registros = listado.data;
        },
        async eliminarDiagnostico(id){
            var listado = await api.post("api/eliminar_diagnostico_tratamiento", {'id':id, 'id_consulta': this.id_consulta});
            this.registros = listado.data;
        }
    }
}
</script>