<template>
    <div class="uploader mt-3" @dragenter="OnDragEnter" @dragleave="OnDragLeave" @dragover.prevent @drop="onDrop" :class="{ draggin: isDragging }">
        <div v-if="!imagen">
            <i class="fa fa-cloud-upload"></i>
            <p>Arrastra y suelta la imagen aqui</p>
            <div>O</div>
            <div class="file-input">
                <label for="file">Selecciona la imagen</label>
                <input type="file" id="file" @change="onInputChange">
            </div>
        </div>

        <div v-else class="imagen-preview">
            <div class="img-wrapper">
                <img height="150" width="150" :src="imagen" alt="imagen">
                <div class="details">
                    <span v-if="file" style="width: 150px; display: inline-block; background: #fff; color: #000; font-size: 12px" class="name" v-text="file.name"></span>
                    <br>
                    <span class="text-danger">Nota: La imagen como se muestra aquí se mostrará en la receta medica, si no se ve bien se tendrá que subir una nueva imagen con mayor o menor resolución según sea el caso.</span>
                </div>

                <div class="file-input">
                    <label for="file">Cambiar la imagen</label>
                    <input type="file" id="file" @change="onInputChange">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ImageUpload',

    data: () => ({
        isDragging: false,
        dragCount: 0,
        imagen: null,
        file: null,
        nombre_editar: null,
    }),

    methods: {
        setImagen(imagen, nombre){
            this.imagen = imagen;
            this.nombre_editar = nombre;
            this.file = null;
        },
        getFile(){
            return this.file;
        },
        cleanFile(){
            this.nombre_editar = null;
            this.imagen =  null;
            this.file =  null;
        },
        OnDragEnter(e){
            e.preventDefault();
            
            this.dragCount++;

            this.isDragging = true;
        },
        OnDragLeave(e){
            e.preventDefault();
            this.dragCount--;            

            if(this.dragCount <= 0 ){
                this.isDragging = false;
            }
        },
        onDrop(e){
            e.preventDefault();
            e.stopPropagation();
            this.dragCount = 0;
            this.isDragging = false;

            const file = e.dataTransfer.files;

            if(file[0]){
                this.addImage(file[0]);
            }
        },
        onInputChange(e){
            const file = e.target.files;

            if(file[0]){
                this.addImage(file[0]);
            }
        },
        addImage(file){
            if(!file.type.match('image.*')){
                 this.$toast.error("Favor de subir una imagen", {
                    dismissible: true,
                    position: "top"
                });
                 return;
            }

            this.file = file;

            const reader = new FileReader();

            reader.onload = (e) => this.imagen = e.target.result; 
            reader.readAsDataURL(file);
        }
    }
}
</script>

<style scoped>
.uploader{
    width: 100%;
    background: #2196F3;
    color: #fff;
    padding: 40px 15px;
    text-align: center;
    border: 3px dashed #fff;
    border-radius: 10px;
    font-size: 20px;
    position: relative;
}
.uploader i{
    font-size: 85px;
}
.draggin{
    color: #2196F3;
    background: #fff;
    border: 3px dashed #2196F3;
}
.draggin .file-input label, input{
    background: #2196F3;
    color: #fff;
}
.file-input{
    width: 200px;
    margin: auto;
    height: 68px;
    position: relative;
}

.file-input label, input{
    background: #fff;
    color: #2196F3;
    width: 100%;
    position: absolute;
    left: 0;
    top: -7px;
    padding: 10px;
    border-radius: 4px;
    margin-top: 7px;
    cursor: pointer;
}

.file-input input{
    opacity: 0;
    z-index: -2;
}
</style>