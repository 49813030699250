<template>
    <div class="container">
        <form @submit.prevent="registrarCita">
            <div class="form-group row">
                <label  for="nombre" class="col-lg-2 control-label text-primary">Médico:</label>
                <div class="col-lg-10">
                    <v-select :disabled="disabled_medico" v-model="cita.id_usuario_cita" :reduce="nombre => nombre.id_usuario" :options="medicos" label="nombre">
                        <template #search="{attributes, events}">
                            <input
                            class="vs__search"
                            :required="!cita.id_usuario_cita"
                            v-bind="attributes"
                            v-on="events"
                            />
                        </template>
                    </v-select>
                </div>
            </div>
            <div class="form-group row">
                <label for="paciente" class="col-lg-2 control-label text-primary">Paciente:</label>
                <div class="col-lg-9">
                    <v-select :required="true" :disabled="disabled_paciente" :filterable="false" @search="query => search = query" v-model="cita.id_paciente" :options="paginated" label="nombre">
                         <template #search="{attributes, events}">
                            <input class="vs__search" :required="!cita.id_paciente" v-bind="attributes" v-on="events" />
                        </template> 
                    </v-select>
                </div>
                <div v-if="!disabled_paciente" class="col-lg-1">
                    <div @click="registrar_paciente_en_cita = true" class="btn btn-success btn-sm">+</div>
                </div>
            </div>
            <div class="form-group row">
                <label for="tipo_consulta" class="col-lg-4 control-label text-primary">Tipo de consulta:</label>
                <div class="col-lg-8">
                    <div class="form-check-inline">
                        <label class="form-check-label text-primary">
                            <input :disabled="Boolean(estatus_consulta)" type="radio" v-model="cita.tipo_consulta" class="form-check-input" value="primera_vez">Primera vez
                        </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label text-primary">
                            <input :disabled="Boolean(estatus_consulta)" type="radio" v-model="cita.tipo_consulta" class="form-check-input" value="subsecuente">Subsecuente
                        </label>
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <label for="motivo" class="col-lg-2 control-label text-primary">Motivo:</label>
                <div class="col-lg-10">
                    <v-select :required="true"  :disabled="Boolean(estatus_consulta)" v-model="cita.id_motivo" :reduce="motivo => motivo.id_motivo" :options="motivos" label="motivo">
                        <template #search="{attributes, events}">
                            <input class="vs__search" :required="!cita.id_motivo" v-bind="attributes" v-on="events" />
                        </template> 
                    </v-select>
                </div>
            </div>
            <div class="form-group row">
                <label for="duracion" class="col-lg-5 control-label text-primary">Duración de consulta:</label>
                <div class="col-lg-7">
                    <input type="time" min="00:01" max="01:00" :disabled="disabled_duracion" v-model="cita.duracion" id="default-picker" class="form-control text-center">
                </div>
            </div>
            <div class="form-group row">
                <label for="fecha" class="col-lg-2 control-label text-primary">Fecha:</label>
                <div class="col-lg-10">
                    <b-form-datepicker :state="state_fecha" :disabled="disabled_fecha"  v-model="fecha" class="mb-2" show-decade-nav placeholder="Seleccione una fecha"></b-form-datepicker>
                </div>
            </div>
            <div class="form-group row">
                <label for="horario" class="col-lg-2 control-label text-primary">Horario</label>
                <div class="col-lg-10">
                    <input required :disabled="disabled_horario" type="time" v-model="horario" id="default-picker" class="form-control text-center">
                </div>
            </div>

            <div v-if="editar_consulta">
                <div v-if="id_cita && !cargando && pacientes.length > 0" class="container-fluid mt-3">
                    <div class="align-items-center">
                        <div class="text-center">
                            <div @click="updateCita" class="btn btn-primary  btn-lg btn-block">
                                Editar cita
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="id_cita && !cargando && pacientes.length > 0" class="container-fluid mt-3">
                    <div class="align-items-center">
                        <div class="text-center">
                            <div @click="cancelarCita" class="btn btn-warning  btn-lg btn-block">
                                Cancelar cita
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="!id_cita && !cargando && pacientes.length > 0" class="container-fluid mt-3">
                    <div class="align-items-center">
                        <div class="text-center">
                            <button class="btn btn-primary  btn-lg btn-block">
                                Registrar cita
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <b-modal v-model="registrar_paciente_en_cita" size="xl" id="registrar_paciente_en_cita" title="Nuevo Paciente">
            <NuevoPaciente @getListadoPacientes="getListadoPacientes" :desde_cita="true" @cerrarModalNuevoPaciente="cerrarModal"></NuevoPaciente>
            <template v-slot:modal-footer>
                <div class="container-fluid"> 
                <div class="align-items-center">
                    <div class="text-center" style="margin-left:10px; margin-right:10px">
                    <b-button @click="registrar_paciente_en_cita=false" class="btn btn-danger  btn-lg btn-block"> Cerrar </b-button>
                    </div>	
                </div>
                </div>
            </template>
        </b-modal>

        <b-overlay no-wrap fixed :show="show_cancelar_cita" rounded="sm">
            <template #overlay>
                <div>
                <p><strong id="form-confirm-label">¿Estas seguro de cancelar la cita?</strong></p>
                <div v-if="!cancelando">
                    <b-button variant="outline-danger" class="mr-3" @click="btnCancelar">
                    No
                    </b-button>
                    <b-button variant="outline-success" @click="btnAceptarCancelar">Si</b-button>
                </div>
                </div>
            </template>
        </b-overlay>

    </div>    
</template>

<script>
import api from "../config.js";
import {en, es} from 'vuejs-datepicker/dist/locale';

import NuevoPaciente from "./NuevoPaciente.vue";

export default {
    name: "NuevaCita",

    components:{
        NuevoPaciente
    },

    created: async function() {
        var cookie = await api.get("/sanctum/csrf-cookie");
        if (cookie.status == 204) {
            this.cargando = true;
            await this.getDatos();
            this.cargando = false;
        }

        if(this.duracion_cita){
            this.cita.duracion  = this.duracion_cita;
            this.disabled_duracion = true;
        }
        if(this.hora){
            this.horario  = this.hora;
            this.disabled_horario = true;
        }
        if(this.fecha_cita){
            this.fecha = this.fecha_cita;
            this.disabled_fecha = true;
        }
        if(this.id_medico_seleccionado){
            this.cita.id_usuario_cita = this.id_medico_seleccionado;
            this.disabled_medico = true;
        }

        if(this.estatus_consulta && (this.estatus_consulta == 'terminada' || this.estatus_consulta == 'cancelada')){
            this.editar_consulta = false;
        }
    },

    props: [
        'id_cita',
        'duracion_cita',
        'fecha_cita',
        'hora',
        'id_medico_seleccionado',
        'estatus_consulta'
    ],

    data: () => ({
        editar_consulta: true,
        cargando: true,
        show_cancelar_cita: false,
        registrar_paciente_en_cita: false,
        en: en,
        es: es,
        cita: {
            id_paciente: null,
            duracion: "00:20",
            tipo_consulta: 'primera_vez',
            id_motivo: null,
            fecha_hora: null,
            id_usuario_cita: null,
        },
        cancelando: false,
        id_cita_cancelar: null,
        state_fecha: null,
        disabled_duracion: false,
        disabled_horario: false,
        disabled_fecha: false,
        disabled_medico: false,
        disabled_paciente: false,
        fecha: null,
        horario: null,
        medicos: [],
        pacientes: [],
        motivos: [],
        search: '',
        id_paciente_registrado: null
    }),

    computed: {
        filtered(){
            return this.pacientes.filter(value => value.nombre.toLowerCase().includes(this.search.toLowerCase()));
        },

        paginated(){
            return this.filtered.slice(0,10);
        }
    },

    methods: {
        async getDatos(){
            await this.getMedicos();
            this.getListadoPacientes();
            this.getListadoMotivos();
        

            if(this.id_cita){
                this.getInfoCita();
                this.disabled_paciente = true;
            }
        },

        async getMedicos(){
            var medic = await api.get("api/listado_medicos");
            this.medicos = medic.data;
        },

        async getListadoPacientes(id_paciente = null, nombre, ap_paterno, ap_materno) {
            var listado = await api.get("api/listado_pacientes");
            this.pacientes = listado.data;

            if(id_paciente){
                this.cita.id_paciente = nombre + ' ' + ap_paterno + ' ' + ap_materno;
                this.id_paciente_registrado = id_paciente;
            }
        },

        async getListadoMotivos() {
            var listado = await api.get("api/listado_motivos");
            this.motivos = listado.data;
        },

        async updateCita(){
            this.cita.fecha_hora = this.fecha + "T" + this.horario + ":00";
            var info = await api.post("api/update_cita/" + this.id_cita, this.cita);

            if(info.data.tipo == 'Exito'){
                this.$toast.success("Cita editada correctamente", {
                    dismissible: true,
                    position: "top"
                });
                var fecha = new Date(this.cita.fecha_hora);
                this.fechaListado(fecha, true);
            }else{
                this.$toast.error("Ocurrio un error vuelve a intentarlo", {
                    dismissible: true,
                    position: "top"
                });
            }
        },

        async btnAceptarCancelar(){
            this.cancelando = true;
            this.cita.fecha_hora = this.fecha + "T" + this.horario + ":00";
            var fecha = new Date(this.cita.fecha_hora);
            var info = await api.post("api/cancelar_cita/" + this.id_cita_cancelar);
            
            if(info.data.tipo == 'Exito'){
                this.$toast.success("Cita cancelada correctamente", {
                    dismissible: true,
                    position: "top"
                });
                this.fechaListado(fecha, true);
            }else{
                this.$toast.error("Ocurrio un error vuelve a intentarlo", {
                    dismissible: true,
                    position: "top"
                });
            }

            this.cancelando = false;
        },

        btnCancelar(){
            this.show_cancelar_cita = false;
            this.id_cita_cancelar = null;
        },

        cancelarCita(){
            this.show_cancelar_cita = true;
            this.id_cita_cancelar = this.id_cita;
        },

        fechaListado(fecha, getCalendarioCompleto) {
             this.$emit('fechaListado', fecha, getCalendarioCompleto);
        },

        async getInfoCita(){
            var info = await api.get("api/get_info_cita/" + this.id_cita);

            if(info.data.tipo == 'Exito'){
                var cita = info.data.cita;
                this.id_medico_seleccionado = cita.id_usuario_cita;
                this.cita.id_paciente = cita.id_paciente;
                this.cita.tipo_consulta = cita.tipo_consulta;
                this.cita.id_motivo = cita.id_motivo;
            }else{
                this.$toast.error("Ocurrio un error vuelve a intentarlo", {
                    dismissible: true,
                    position: "top"
                });
            }
        },

        async registrarCita(){
            var $this4 = this;
            this.cargando = true;

            if(typeof this.cita.id_paciente == "object"){
                this.cita.id_paciente = this.cita.id_paciente.id_paciente;
            }

            if(typeof this.cita.id_paciente == 'string'){
                this.cita.id_paciente = this.id_paciente_registrado;
            }

            /* this.cita.id_paciente = this.cita.id_paciente.id_paciente; */

            if(this.fecha){
                this.state_fecha = true;
                this.cita.fecha_hora = this.fecha + "T" + this.horario + ":00";
                api.post("api/nueva_cita", this.cita).then(respuesta => {
                    if (respuesta.data.tipo == "Exito") {
                        this.$toast.success("Cita registrada correctamente", {
                        dismissible: true,
                        position: "top"
                        });
                    }
                    if(this.fecha_cita){
                        var fecha = new Date(this.cita.fecha_hora);
                        this.fechaListado(fecha, true);
                    }else{
                        $this4.$router.push('/dashboard/' + this.cita.fecha_hora);
                        $this4.$router.go(0);
                    }
                    
                }).catch(() => {
                    this.$toast.error("Ocurrio un error vuelve a intentarlo", {
                        dismissible: true,
                        position: "top"
                    });
                    this.cargando = false;
                });
            }else{
                this.state_fecha = false;
                this.cargando = false;
            }
        },

        cerrarModal(){
            this.registrar_paciente_en_cita = false;
        }
    }
}
</script>

<style>
    @import "../assets/css/general.css";
</style>