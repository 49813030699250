<template>
    <div class="container">
       <div class="card">
            <div class="card-body">
                <div class="form-group row">
                    <label for="nombre" class="col-lg-1 control-label text-primary">Dia:</label>
                    <div class="col-lg-5">
                        <select v-model="dia" class="form-control">
                            <option value="lunes">Lunes</option>
                            <option value="martes">Martes</option>
                            <option value="miercoles">Miércoles</option>
                            <option value="jueves">Jueves</option>
                            <option value="viernes">Viernes</option>
                            <option value="sabado">Sabado</option>
                            <option value="domingo">Domingo</option>
                        </select>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="nombre" class="col-lg-1 control-label text-primary">De:</label>
                    <div class="col-lg-3">
                        <input v-model="de" type="time" class="form-control text-center">
                    </div>
                </div>
                <div class="form-group row">
                    <label for="nombre" class="col-lg-1 control-label text-primary">A:</label>
                    <div class="col-lg-3">
                        <input v-model="a" type="time" class="form-control text-center">
                    </div>
                </div>
                <div class="container-fluid mt-3">
                    <div v-if="!loading" class="align-items-center">
                        <div class="text-center">
                            <button @click="addHorario" class="btn btn-primary  btn-lg btn-block">
                                Agregar
                            </button>
                        </div>
                    </div>

                     <div v-else class="align-items-center">
                        <div class="text-center">
                            <button class="btn btn-primary" type="button" disabled>
                                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                Registrando...
                            </button>
                        </div>
                    </div>
                </div>
                <br>
                <br>
                <div>
                    <b-table :items="horario" :fields="fields" :busy="false" :per-page="perPage" :small="true" :bordered="true" :foot-clone="true" :current-page="currentPage" caption-top striped hover outlined responsive :filter="filter">
                        <template #cell(opciones)="row">
                            <button class="btn btn-outline-danger btn-sm" @click="eliminar(row.item.id_horario_to_usuario)">Eliminar</button>
                        </template>
                    </b-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from "../config.js";

export default {
    name: 'HorarioMedico',

    props: ['id_usuario'],

    data: () => ({
        filter: null,
        perPage: 20,
        currentPage: 1,
        de:'',
        a:'',
        dia:'',
        loading: false,

        fields: [
            {
                key: "dia",
                sortable: true
            },
            {
                key: "de",
                sortable: true
            },
            {
                key: "a",
                sortable: true
            },
            {
                key: "opciones",
                label: "",
                sortable: false
            }
        ],
        horario: []
    }),

    created: async function() {
        await this.getHorario();
    },

    methods: {
        async eliminar(id){
            var listado = await api.post("api/eliminar_horario_usuarios/"+ id,{'id_usuario': this.id_usuario});
            this.horario = listado.data;
            this.$toast.success("Se ha eliminado el registro", {
                dismissible: true,
                position: "top"
            });
        },
        async addHorario(){
            this.loading = true;
            var listado = await api.post("api/add_horario_usuarios",{
                'id_usuario':this.id_usuario,
                'de': this.de,
                'a':this.a,
                'dia':this.dia
            });
            this.horario = listado.data;
            this.loading = false;
            this.$toast.success("Se agrego el registro correctamente", {
                dismissible: true,
                position: "top"
            });

            this.de = '';
            this.a = '';
            this.dia ='';
        },
        async getHorario() {
            var listado = await api.get("api/get_horario_usuarios/" + this.id_usuario);
            this.horario = listado.data;
        },
    },

}
</script>