<template>
    <div class="container">
        <section class="row">
            <div class="col">
                <div class="card-body">
                    <div class="form-group row">
                        <label for="nombre" class="col-lg-3 control-label text-primary"></label>
                        <label for="nombre" class="col control-label text-primary">Mamá</label>
                        <label for="nombre" class="col control-label text-primary">Papá</label>
                        <label for="nombre" class="col control-label text-primary">Hermanos</label>
                        <label for="nombre" class="col control-label text-primary">Tíos</label>
                        <label for="nombre" class="col control-label text-primary">Primos</label>
                        <label for="nombre" class="col control-label text-primary">Abuelos</label>
                    </div>

                    <div class="form-group row" v-for="enfermedad in enfermedades" :key="clave = enfermedad.clave">
                        <label for="nombre" class="col-lg-3 control-label text-primary">{{ enfermedad.label }}</label>
                        <label for="nombre" class="col control-label text-primary"><toggle-button :sync="true" v-model="hereditarios.json[enfermedad.clave].mama" color="#43aee6" :labels="{ checked: 'SI', unchecked: 'NO' }"/></label>
                        <label for="nombre" class="col control-label text-primary"><toggle-button :sync="true" v-model="hereditarios.json[enfermedad.clave].papa" color="#43aee6" :labels="{ checked: 'SI', unchecked: 'NO' }"/></label>
                        <label for="nombre" class="col control-label text-primary"><toggle-button :sync="true" v-model="hereditarios.json[enfermedad.clave].hermanos" color="#43aee6" :labels="{ checked: 'SI', unchecked: 'NO' }"/></label>
                        <label for="nombre" class="col control-label text-primary"><toggle-button :sync="true" v-model="hereditarios.json[enfermedad.clave].tios" color="#43aee6" :labels="{ checked: 'SI', unchecked: 'NO' }"/></label>
                        <label for="nombre" class="col control-label text-primary"><toggle-button :sync="true" v-model="hereditarios.json[enfermedad.clave].primos" color="#43aee6" :labels="{ checked: 'SI', unchecked: 'NO' }"/></label>
                        <label for="nombre" class="col control-label text-primary"><toggle-button :sync="true" v-model="hereditarios.json[enfermedad.clave].abuelos" color="#43aee6" :labels="{ checked: 'SI', unchecked: 'NO' }"/></label>
                    </div>
                    
                    <div class="container-fluid mt-3">
                        <label for="nombre" class="col control-label text-primary text-center">Otros</label>
                        <div class="col-lg-12">
                            <textarea class="form-control" v-uppercase v-model="hereditarios.json.otros" style="width:100%"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>


<script>

export default{
    name: "Hereditarios",

    props: ['data_hereditarios'],

    data: () => ({
        clave: "",
        cargando: false,
        hereditarios:{
            json:{
                diabletes:              {mama:false,papa:false,hermanos: false,tios:false,primos:false,abuelos:false},
                hipertension:           {mama:false,papa:false,hermanos: false,tios:false,primos:false,abuelos:false},
                cardiopatia:            {mama:false,papa:false,hermanos: false,tios:false,primos:false,abuelos:false},
                hepatopatia:            {mama:false,papa:false,hermanos: false,tios:false,primos:false,abuelos:false},
                nefropatia:             {mama:false,papa:false,hermanos: false,tios:false,primos:false,abuelos:false},
                enfermedad_mental:      {mama:false,papa:false,hermanos: false,tios:false,primos:false,abuelos:false},
                enfermedad_alergica:    {mama:false,papa:false,hermanos: false,tios:false,primos:false,abuelos:false},
                enfermedad_endocrina:   {mama:false,papa:false,hermanos: false,tios:false,primos:false,abuelos:false},
                asma:                   {mama:false,papa:false,hermanos: false,tios:false,primos:false,abuelos:false},
                cancer:                 {mama:false,papa:false,hermanos: false,tios:false,primos:false,abuelos:false},
                interogados_negados:    {mama:false,papa:false,hermanos: false,tios:false,primos:false,abuelos:false},
                obecidad:               {mama:false,papa:false,hermanos: false,tios:false,primos:false,abuelos:false},
                otros: '',
            },
            json_hereditarios: ''
        }
        ,
        enfermedades:[
            {clave:'diabletes', label:'Diabetes'},
            {clave:'hipertension', label:'Hipertensión'},
            {clave:'cardiopatia', label:'Cardiopatía'},
            {clave:'hepatopatia', label:'Hepatopatía'},
            {clave:'nefropatia', label:'Nefropatía'},
            {clave:'enfermedad_mental', label:'Enfermedades mental'},
            {clave:'enfermedad_alergica', label:'Enfermedades alérgicas'},
            {clave:'enfermedad_endocrina', label:'Enfermedades endócrina'},
            {clave:'asma', label:'Asma'},
            {clave:'cancer', label:'Cáncer'},
            {clave:'interogados_negados', label:'Interogados y negados'},
            {clave:'obecidad', label:'Obesidad'}
        ],
    }),

    created: function(){
        if(this.data_hereditarios.json_hereditarios){
            this.hereditarios.json = JSON.parse(this.data_hereditarios.json_hereditarios);
        }
    },

    methods: {
        /* actualizarRegistro(){
            this.hereditarios.json_hereditarios = JSON.stringify(this.hereditarios.json);
            this.$emit('actualizarRegistro', this.hereditarios);
        }, */
        getInfo(){
            this.hereditarios.json_hereditarios = JSON.stringify(this.hereditarios.json);
            return this.hereditarios;
        }
    }
}
</script>