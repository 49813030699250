<template>
  <div class="container">
    <Loading :show="showLoading"></Loading>
    <div class="card">
      <div class="text-center">
        <p class="text-primary h3 mt-2">LISTADO DE PACIENTES</p>
      </div>

      <b-row class="my-1">
        <b-col sm="1" class="ml-3 pt-2">
          <label for="input-none">Buscar:</label>
        </b-col>
        <b-col sm="9">
          <b-form-input @change="buscarPaciente" @keyup.enter="buscarPaciente" v-model="buscar_paciente" type="search" id="filterInput" placeholder="Buscar" ></b-form-input>
          <p class="text-primary">Pacientes registrados: {{ rows }}</p>
        </b-col>
      </b-row>

      <b-table :items="clientes" :fields="fields" :busy="false" :per-page="perPage" :small="true" :bordered="true" :foot-clone="true" :current-page="currentPage" caption-top striped hover outlined responsive :filter="filter">
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(opciones)="row">
          <b-link class="btn btn-outline-success mr-1 btn-sm" v-bind:to="'/editar_paciente/' + row.item.id_paciente + '/editar'" >Ver</b-link>
          <button @click="btnEliminar(row.item.id_paciente, row.item.nombre)" class="btn btn-outline-danger btn-sm">Eliminar</button>
        </template>
      </b-table>

      <div class="ml-3">
        <b-pagination :per-page="perPage" v-model="currentPage" pills :total-rows="rows"></b-pagination>
      </div>
    </div>

    <b-overlay no-wrap fixed :show="show" rounded="sm">
      <template #overlay>
        <div>
          <p><strong id="form-confirm-label">¿Estas seguro de eliminar el paciente {{ nombreEliminar }} ?</strong></p>
          <div>
            <b-button variant="outline-danger" class="mr-3" @click="btnCancelar">
              Cancelar
            </b-button>
            <b-button variant="outline-success" @click="btnAceptar">OK</b-button>
          </div>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import api from "../config.js";

import Loading from "../components/Loading.vue";

export default {
  name: "ListadoPacientes",

  components:{
    Loading
  },

  beforecreated: function(){
    this.showLoading = true; 
  },

  created: async function() {
    await this.getListadoPacientes();
    this.showLoading = false;
  },

  methods: {
    buscarPaciente(){
        this.filter = this.buscar_paciente;
    },
    async getListadoPacientes() {
      var cookie = await api.get("/sanctum/csrf-cookie");
      if (cookie.status == 204) {
        var listado = await api.get("api/listado_pacientes");
        this.clientes = listado.data;
      }
      
    },

    btnEliminar(id_paciente_eliminar, nombre){
        this.indexEliminar = this.clientes.map(x => x.id_paciente).indexOf(id_paciente_eliminar);
        this.nombreEliminar = nombre;
        this.show = true;
        this.idPacienteEliminar = id_paciente_eliminar;
    },

    btnCancelar(){
        this.nombreEliminar = false;
        this.show = false;
        this.idPacienteEliminar = false;
    },

    async btnAceptar(){
        this.showLoading = true;
        this.show = false;
        var cookie = await api.get("/sanctum/csrf-cookie");
        if (cookie.status == 204) {
            var respuesta = await api.post("api/eliminar_paciente/" + this.idPacienteEliminar);
            this.showLoading = false;
            if(respuesta.data.tipo == 'Exito'){
                    this.$toast.success("El paciente se ha eliminado", {
                        dismissible: true,
                        position: "top"
                    });
                    this.clientes.splice(this.indexEliminar, 1);
                    this.nombreEliminar = '';
            }else{
                this.$toast.error("Ocurrio un error vuelve a intentarlo", {
                    dismissible: true,
                    position: "top"
                });
            }
        }
    }
  },

  data: () => ({
    showLoading: true,
    nombreEliminar: '',
    indexEliminar: false,
    idPacienteEliminar: false,
    filter: null,
    buscar_paciente:null,
    perPage: 20,
    currentPage: 1,
    show: false,
    busy: false,

    fields: [
      {
        key: "id_paciente",
        label: "Id",
        sortable: true
      },
      {
        key: "nombre",
        sortable: true
      },
      {
        key: "sexo",
        sortable: true
      },
      {
        key: "tipo_sangre",
        label: "Tipo de sangre",
        sortable: true
      },
      {
        key: "opciones",
        label: "",
        sortable: false
      }
    ],
    clientes: []
  }),

  computed: {
    rows() {
      return this.clientes.length;
    }
  }
};
</script>